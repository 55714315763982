.page-loader {
	position: fixed;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	display: flex;
	transition: 0.5s $ease-out-quad;
	z-index: 99999999999;

	.page--loaded & {
		opacity: 0;
		pointer-events: none;
		visibility: hidden;
	}
}
