.segment {
	$segment: &;

	&__main {
		@include wrapped('normal');
		@include flex() {
			flex-flow: column;
			justify-content: center;
			align-items: center;
		}
		position: relative;
		text-align: center;

		@include media('screen-md') {
			justify-content: space-between;
		}

		@include media('screen-lg') {
			flex-flow: row;
		}

		&--flex-3 {
			justify-content: center;

			@include media('screen-xl') {
				@include responsify($spacing-xxl, margin-bottom);
			}

			#{$segment}__single-col {
				flex: 0 100%;

				@include media('screen-lg') {
					flex: 0 50%;
				}

				@include media('screen-xl') {
					flex: 0 33.3333%;
				}
			}

			&:nth-of-type(2) {
				#{$segment}__text {
					margin-bottom: 0;
				}
			}

			#{$segment}__undertitle,
			#{$segment}__text,
			#{$segment}__image {
				@include responsify($spacing-lg, 'margin-bottom');
			}
		}

		&--flexible {
			display: block;

			@include media('screen-lg') {
				display: flex;
			}
		}

		&--text-left,
		&--reversed {
			justify-content: flex-start;

			h1,
			h2,
			h3,
			h4 {
				@include responsify($spacing-lg, 'margin-bottom');
			}

			.text-normal {
				@include responsify($spacing-lg, 'margin-bottom');
			}
		}

		&--reversed {
			@include media('screen-lg') {
				flex-flow: row-reverse;
			}

			#{$segment}__left {
				margin-right: 0;
			}

			#{$segment}__right {
				@include media('screen-lg') {
					@include responsify($flex-gutter, 'margin-right');
				}
			}
		}

		&--icon-grid,
		&--text-grid {
			flex-flow: row wrap;

			#{$segment}__single-col {
				@include responsify($spacing-xxl, 'margin-bottom');
				flex: 0 100%;

				@include media('screen-lg') {
					@include responsify($spacing-no, margin-bottom);
					display: flex;
					align-items: center;
					justify-content: center;
					min-height: 160px;
				}

				@include media('screen-xl') {
					@include responsify($spacing-no, 'margin-bottom');
				}

				&:nth-of-type(2) {
					@include responsify($spacing-no, 'margin-left');
					@include responsify($spacing-no, 'margin-right');
				}

				&:last-of-type {
					@include media('screen-xxl') {
						margin-right: auto;
					}
				}
			}

			.animated-bg & {
				svg {
					stroke: #ffffff; //simple
				}
			}

			#{$segment}__undertitle {
				@include responsify($font-sizes-special);
				padding: 0 1rem;

				.animated-bg & {
					color: white;
				}
			}
		}

		&--icon-grid {
			justify-content: space-between;

			#{$segment}__single-col {
				@include media('screen-sm') {
					flex: 0 30%;
				}

				@include media('screen-md') {
					flex: 0 30%;
				}

				@include media('screen-lg') {
					flex: 0 25%;
				}

				@include media('screen-xl') {
					flex: 0 25%;
				}
			}
		}

		&--text-grid {
			justify-content: space-around;

			#{$segment}__single-col {
				@include media('screen-sm') {
					flex: 1 50%;
				}

				@include media('screen-lg') {
					flex: 1 33.3333%;
				}

				@include media('screen-xxl') {
					flex: 1 25%;
				}
			}
		}

		&--w-bgs {
			@include media('screen-lg') {
				flex-flow: row wrap;
			}
		}
	}
}
