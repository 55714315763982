.slideshow {
	$slide-animation: 0.8s;
	position: relative;
	height: 100%;
	overflow: hidden;

	.section__left-image &,
	.section__right-image & {
		overflow: visible;
	}

	&__slides {
		position: relative;
		display: block;
		width: 100%;
		height: 100%;
		padding: 0;
		margin: 0;
		list-style: none;
		z-index: 10;

		.section__left-image &,
		.section__right-image & {
			width: 300px;
			height: 300px;

			@include media('screen-md') {
				width: 450px;
				height: 400px;
			}

			@include media('screen-max') {
				width: 650px;
				height: 650px;
			}
		}
	}

	&__slide {
		$slide: &;
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		transition: opacity 0s $slide-animation;
		will-change: clip-path;
		cursor: grab;
		opacity: 0;
		z-index: 1;

		.section__right &,
		.section__left & {
			img {
				object-fit: contain;
			}
		}

		&:active {
			cursor: grabbing;
		}

		@supports (clip-path: polygon(0 0, 0 0, 0 0, 0 0)) {
			clip-path: polygon(0 0, 100% 0, 100% 100%, 0 100%);
			transform: none;
		}

		&--last {
			z-index: 5;
		}

		&--active {
			opacity: 1;
			transition: opacity $slide-animation $ease-out-quad;
			z-index: 10;

			@supports (clip-path: polygon(0 0, 0 0, 0 0, 0 0)) {
				&#{$slide}--next {
					animation: slideshow-next $slide-animation $ease-out-quad forwards;
					clip-path: polygon(100% 0, 100% 0, 100% 100%, 100% 100%);
					transition: all 0s;
				}

				&#{$slide}--prev {
					animation: slideshow-prev $slide-animation $ease-out-quad forwards;
					clip-path: polygon(0 0, 0 0, 0 100%, 0 100%);
					transition: all 0s;
				}
			}
		}

		@at-root {
			@keyframes slideshow-prev {
				0% {
					clip-path: polygon(0 0, 0 0, 0 100%, 0 100%);
					transform: translate3d(-5%, 0, 0);
				}
				100% {
					clip-path: polygon(0 0, 100% 0, 100% 100%, 0 100%);
					transform: none;
				}
			}

			@keyframes slideshow-next {
				0% {
					clip-path: polygon(100% 0, 100% 0, 100% 100%, 100% 100%);
					transform: translate3d(5%, 0, 0);
				}
				100% {
					clip-path: polygon(0 0, 100% 0, 100% 100%, 0 100%);
					transform: none;
				}
			}
		}

		img {
			pointer-events: none;

			.main-top__right & {
				height: 100%;
				object-fit: cover;
			}
		}
	}

	&__bullets {
		@include responsify($slideshow-bullets);
		position: absolute;
		right: 0;
		bottom: 0;
		left: 0;
		display: flex;
		justify-content: center;
		align-content: center;
		align-items: center;
		padding: 1.5em;
		pointer-events: none;
		z-index: 20;

		&:before {
			@include scrim-gradient($startColor: $color-black, $direction: 'to top');
			content: '';
			position: absolute;
			right: 0;
			bottom: 0;
			left: 0;
			opacity: 0.75;
			pointer-events: none;
			height: 400%;
			transform-origin: center bottom;
			z-index: -1;
		}
	}

	&__bullet {
		width: 1em;
		height: 1em;
		padding: 0;
		margin: 0.75em;
		appearance: none;
		background: $color-white;
		border: 0;
		border-radius: 50%;
		color: transparent;
		cursor: pointer;
		font-size: 1em;
		opacity: 0.8;
		pointer-events: all;
		transition: 0.3s $ease-custom;

		&:hover {
			box-shadow: 0 0 0 0.375em rgba($color-white, 0.3);
			opacity: 1;
		}

		&--active {
			opacity: 1;
			transform: scale(1.4);

			&:hover {
				box-shadow: none;
			}
		}
	}

	&__buttons {
		@include responsify($slideshow-bullets);
		position: absolute;
		top: 0;
		left: 0;
		display: flex;
		justify-content: space-between;
		align-content: stretch;
		align-items: stretch;
		pointer-events: none;
		width: 100%;
		height: 100%;
		z-index: 15;
	}

	&__button {
		position: relative;
		width: 5em;
		padding: 0;
		margin: 0;
		appearance: none;
		background: transparent;
		border: 0;
		border-radius: 0;
		box-shadow: none;
		color: transparent;
		cursor: pointer;
		font-size: 1em;
		pointer-events: all;

		&:hover {
			&:before {
				opacity: 0.5;
			}
		}

		&:before {
			content: '';
			position: absolute;
			top: 0;
			bottom: 0;
			width: 300%;
			transform-origin: center bottom;
			transition: 0.3s $ease-out-quad;
			opacity: 0;
			z-index: -1;
		}

		&:after {
			content: '';
			margin-top: -1em;
			position: absolute;
			top: 50%;
			width: 2em;
			height: 2em;
			transition: transform 0.3s $ease-out-quad;
			border: 0 solid $color-white;
			border-width: 0 0.125em 0.125em 0;
		}

		&--prev {
			cursor: w-resize;

			&:hover {
				&:after {
					transform: translate3d(-0.5em, 0, 0) rotate(135deg);
				}
			}

			&:before {
				@include scrim-gradient($startColor: $color-black, $direction: 'to right');
				left: 0;
			}

			&:after {
				left: 2em;
				transform: rotate(135deg);
			}
		}

		&--next {
			cursor: e-resize;

			&:hover {
				&:after {
					transform: translate3d(0.5em, 0, 0) rotate(-45deg);
				}
			}

			&:before {
				@include scrim-gradient($startColor: $color-black, $direction: 'to left');
				right: 0;
			}

			&:after {
				right: 2em;
				transform: rotate(-45deg);
			}
		}
	}
}