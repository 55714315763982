.hamburger {
	$bar: '.hamburger__bar';
	position: relative;
	top: 50%;
	width: 30px;
	height: 100%;
	transform: scaleX(-1) translateY(-50%);

	@include media('screen-md') {
		display: none;
	}

	&__inner {
		position: relative;
	}

	&__bar {
		@include transition(transform 0.2s ease-in-out);
		$bar: &;
		position: absolute;
		display: block;
		width: 15px;
		top: 14px;
		height: 2px;
		background: $color-white;

		&:after,
		&:before {
			@include transition(transform 0.2s ease-in-out);
			content: '';
			position: absolute;
			display: block;
			height: 2px;
			background: $color-white;
		}

		&:before {
			@include transition(opacity 0.2s ease-in-out);
			width: 30px;
			bottom: 10px;
		}

		&:after {
			width: 24px;
			top: 10px;
		}
	}

	&--is-active {
		outline: none;

		#{$bar} {
			top: 4px;
			width: 30px;
			transform: translate3d(0, 10px, 0) rotate(45deg);

			&:before {
				transform: rotate(-45deg) translate3d(0, 0, 0);
				opacity: 0;
				visibility: hidden;
			}

			&:after {
				top: 20px;
				width: 30px;
				transform: translate3d(0, -20px, 0) rotate(-90deg);
			}
		}
	}
}

.mobile-menu {
	@include flex() {
		align-items: center;
		justify-content: center;
		flex-flow: column;
		margin: 0 auto;
	}
	@include responsify($header-height, padding-top);
	@include transition(transform 0.2s ease-in-out);
	position: absolute;
	top: 0;
	right: 0;
	left: 0;
	width: 90%;
	transform: translateX(120%);
	transform-origin: right;
	height: 100vh;
	margin: 0 auto;
	background: transparent;
	z-index: 3;
	
	@include media('screen-md') {
		display: none;
	}

	&--active {
		transform: translateX(0);
		//animation: slideIn 0.2s 0.2s forwards;
	}
}

@keyframes slideIn {
	100% {transform: translateX(0);}
}

.menu-element {
	text-align: center;

	&__single {
		@include flex() {
			align-items: center;
			justify-content: center;
			flex-flow: column;
		}
		@include responsify($font-sizes-xl);
		@include responsify($spacing-lg, padding-top);
		@include responsify($spacing-lg, padding-bottom);
		color: $color-white;

		&:first-of-type {
			@include responsify($spacing-no, padding-top);
		}

		&:last-of-type {
			@include responsify($spacing-no, padding-bottom);
		}

		&--bordered {
			position: relative;

			&:after,
			&:before {
				content: '';
				position: absolute;
				width: 120px;
				height: 2px;
				background: $color-white;
			}

			&:before {
				bottom: 0;
			}

			&:after {
				top: 0;
			}
		}
	}
}