.segment {
	$segment: &;

	&__single-col {
		@include responsify($spacing-xxl, 'margin-bottom');

		@include media('screen-xl') {
			@include responsify($spacing-no, 'margin-bottom');
		}

		&:nth-of-type(2) {
			@include media('screen-lg') {
				@include responsify($spacing-lg, 'margin-left');
				@include responsify($spacing-lg, 'margin-right');
			}
		}

		button {
			@include responsify($spacing-lg, 'margin-bottom');

			@include media('screen-md') {
				margin-bottom: 0;
			}
		}

		&--w-margin {
			#{$segment}__undertitle,
			#{$segment}__image {
				@include responsify($spacing-lg, 'margin-bottom');

				&:last-of-type {
					margin-bottom: 0;
				}
			}
		}
		
		.linked {
			color: $color-white;
			font-weight: 500;
		}
	}

	&__container {
		@include flex() {
			flex-flow: column;
			justify-content: center;
			align-items: center;
		}
		position: relative;
	}

	&__title {
		@include wrapped('normal');
		@include responsify($spacing-xxl, 'padding-top');
		@include responsify($spacing-xxl, 'padding-bottom');
		@include responsify($font-sizes-xl);
		position: relative;
		text-align: center;
	}

	&__image {
		@include responsify($spacing-lg, 'margin-bottom');
		height: 100px;
		background: $color-white;
		border-radius: 100%;

		@include media('screen-md') {
			height: 333px;
		}

		&--rounded {
			@include flex($justify-content: center) {
				align-items: center;
			}
			width: 150px;
			height: 150px;
			margin: 0 auto;
			line-height: 0;

			@include media('screen-lg') {
				width: 250px;
				height: 250px;
			}

			@include media('screen-xl') {
				width: 300px;
				height: 300px;
			}

			.section--no-bg & {
				background: transparent;
			}
		}

		&--rounded-small {
			width: 120px;
			height: auto;
			background: transparent;
			border-radius: 0;

			#{$segment}__text {
				margin-bottom: 0;
			}
		}
	}

	&__undertitle {
		@include responsify($font-sizes-lg);
	}

	&__text {
		@include responsify($font-sizes-nm);
		font-weight: 400;
	}
}