.main-logo {
	@include responsify($font-sizes-md);
	line-height: 0;
	
	a {
		display: block;
		color: $color-white;
	}
	
	&__logo {
		width: 8rem;
		height: auto;

		@include media('screen-lg') {
			width: 12rem;		
		}
	}
}