//basic colors (non element related)
//define as many as you want...
//PLEASE DO NOT DELETE EXISTING COLORS HERE! THEY ARE NEEDED WITHIN THE system
$color-red: #D0021B;
$color-green-dot: #96C254;
$color-grey: #7e7e7e;
$color-grey-light: #c2c2c2; //used for input backgrounds, etc.
$color-grey-ultralight: #F8F8F8;
$color-black: #000000;
$color-white: #ffffff;
$color-main: $color-black; //main highlight color of the system
$color-primary: $color-black;
$color-custom-red: #B04363;
$color-custom-purple: #6A28E6;
$color-orange: #F15A24;

/*
 Gradient Colors
 */

$start-bg-0: $color-black;
$end-bg-0: $color-white;

//Green to Blue
$start-bg-1: #5FECAB;
$end-bg-1: #3B94F4;

//Purple to Purple
$start-bg-2: #6429D4;
$end-bg-2: #832DA3;

// Red to Red
$start-bg-3: #D64263;
$end-bg-3: #B04363;

// Red to Orange
$start-bg-4: #EF4571;
$end-bg-4: #DB8235;

// Button Colors
$color-btn-text: $color-white;
$color-btn-primary-text: #782BC1;
$color-btn-secondary-text: #782BC1;
$color-btn-primary: #EF4571;
$color-btn-secondary: $color-white;
$color-btn-tertiary: #EF4571;
$color-btn-alert: $color-red;
