/*
 Gradient Colors
 */
::-webkit-input-placeholder {
  /* WebKit browsers */
  color: transparent;
  opacity: 0;
}

:-moz-placeholder {
  /* Mozilla Firefox 4 to 18 */
  color: transparent;
  opacity: 0;
}

::-moz-placeholder {
  /* Mozilla Firefox 19+ */
  color: transparent;
  opacity: 0;
}

:-ms-input-placeholder {
  /* Internet Explorer 10+ */
  color: transparent;
  opacity: 0;
}

@media only screen and (min-width: 320px) {
  .element-sm-1-1 {
    grid-column: span 1;
    grid-row: span 1;
  }
  .element-sm-1-2 {
    grid-column: span 1;
    grid-row: span 2;
  }
  .element-sm-1-3 {
    grid-column: span 1;
    grid-row: span 3;
  }
  .element-sm-1-4 {
    grid-column: span 1;
    grid-row: span 4;
  }
  .element-sm-2-1 {
    grid-column: span 2;
    grid-row: span 1;
  }
  .element-sm-2-2 {
    grid-column: span 2;
    grid-row: span 2;
  }
  .element-sm-2-3 {
    grid-column: span 2;
    grid-row: span 3;
  }
  .element-sm-2-4 {
    grid-column: span 2;
    grid-row: span 4;
  }
  .element-sm-3-1 {
    grid-column: span 3;
    grid-row: span 1;
  }
  .element-sm-3-2 {
    grid-column: span 3;
    grid-row: span 2;
  }
  .element-sm-3-3 {
    grid-column: span 3;
    grid-row: span 3;
  }
  .element-sm-3-4 {
    grid-column: span 3;
    grid-row: span 4;
  }
  .element-sm-4-1 {
    grid-column: span 4;
    grid-row: span 1;
  }
  .element-sm-4-2 {
    grid-column: span 4;
    grid-row: span 2;
  }
  .element-sm-4-3 {
    grid-column: span 4;
    grid-row: span 3;
  }
  .element-sm-4-4 {
    grid-column: span 4;
    grid-row: span 4;
  }
}
@media only screen and (min-width: 768px) {
  .element-md-1-1 {
    grid-column: span 1;
    grid-row: span 1;
  }
  .element-md-1-2 {
    grid-column: span 1;
    grid-row: span 2;
  }
  .element-md-1-3 {
    grid-column: span 1;
    grid-row: span 3;
  }
  .element-md-1-4 {
    grid-column: span 1;
    grid-row: span 4;
  }
  .element-md-2-1 {
    grid-column: span 2;
    grid-row: span 1;
  }
  .element-md-2-2 {
    grid-column: span 2;
    grid-row: span 2;
  }
  .element-md-2-3 {
    grid-column: span 2;
    grid-row: span 3;
  }
  .element-md-2-4 {
    grid-column: span 2;
    grid-row: span 4;
  }
  .element-md-3-1 {
    grid-column: span 3;
    grid-row: span 1;
  }
  .element-md-3-2 {
    grid-column: span 3;
    grid-row: span 2;
  }
  .element-md-3-3 {
    grid-column: span 3;
    grid-row: span 3;
  }
  .element-md-3-4 {
    grid-column: span 3;
    grid-row: span 4;
  }
  .element-md-4-1 {
    grid-column: span 4;
    grid-row: span 1;
  }
  .element-md-4-2 {
    grid-column: span 4;
    grid-row: span 2;
  }
  .element-md-4-3 {
    grid-column: span 4;
    grid-row: span 3;
  }
  .element-md-4-4 {
    grid-column: span 4;
    grid-row: span 4;
  }
}
@media only screen and (min-width: 1024px) {
  .element-lg-1-1 {
    grid-column: span 1;
    grid-row: span 1;
  }
  .element-lg-1-2 {
    grid-column: span 1;
    grid-row: span 2;
  }
  .element-lg-1-3 {
    grid-column: span 1;
    grid-row: span 3;
  }
  .element-lg-1-4 {
    grid-column: span 1;
    grid-row: span 4;
  }
  .element-lg-2-1 {
    grid-column: span 2;
    grid-row: span 1;
  }
  .element-lg-2-2 {
    grid-column: span 2;
    grid-row: span 2;
  }
  .element-lg-2-3 {
    grid-column: span 2;
    grid-row: span 3;
  }
  .element-lg-2-4 {
    grid-column: span 2;
    grid-row: span 4;
  }
  .element-lg-3-1 {
    grid-column: span 3;
    grid-row: span 1;
  }
  .element-lg-3-2 {
    grid-column: span 3;
    grid-row: span 2;
  }
  .element-lg-3-3 {
    grid-column: span 3;
    grid-row: span 3;
  }
  .element-lg-3-4 {
    grid-column: span 3;
    grid-row: span 4;
  }
  .element-lg-4-1 {
    grid-column: span 4;
    grid-row: span 1;
  }
  .element-lg-4-2 {
    grid-column: span 4;
    grid-row: span 2;
  }
  .element-lg-4-3 {
    grid-column: span 4;
    grid-row: span 3;
  }
  .element-lg-4-4 {
    grid-column: span 4;
    grid-row: span 4;
  }
}
@media only screen and (min-width: 1280px) {
  .element-xl-1-1 {
    grid-column: span 1;
    grid-row: span 1;
  }
  .element-xl-1-2 {
    grid-column: span 1;
    grid-row: span 2;
  }
  .element-xl-1-3 {
    grid-column: span 1;
    grid-row: span 3;
  }
  .element-xl-1-4 {
    grid-column: span 1;
    grid-row: span 4;
  }
  .element-xl-2-1 {
    grid-column: span 2;
    grid-row: span 1;
  }
  .element-xl-2-2 {
    grid-column: span 2;
    grid-row: span 2;
  }
  .element-xl-2-3 {
    grid-column: span 2;
    grid-row: span 3;
  }
  .element-xl-2-4 {
    grid-column: span 2;
    grid-row: span 4;
  }
  .element-xl-3-1 {
    grid-column: span 3;
    grid-row: span 1;
  }
  .element-xl-3-2 {
    grid-column: span 3;
    grid-row: span 2;
  }
  .element-xl-3-3 {
    grid-column: span 3;
    grid-row: span 3;
  }
  .element-xl-3-4 {
    grid-column: span 3;
    grid-row: span 4;
  }
  .element-xl-4-1 {
    grid-column: span 4;
    grid-row: span 1;
  }
  .element-xl-4-2 {
    grid-column: span 4;
    grid-row: span 2;
  }
  .element-xl-4-3 {
    grid-column: span 4;
    grid-row: span 3;
  }
  .element-xl-4-4 {
    grid-column: span 4;
    grid-row: span 4;
  }
}
@media only screen and (min-width: 1440px) {
  .element-xxl-1-1 {
    grid-column: span 1;
    grid-row: span 1;
  }
  .element-xxl-1-2 {
    grid-column: span 1;
    grid-row: span 2;
  }
  .element-xxl-1-3 {
    grid-column: span 1;
    grid-row: span 3;
  }
  .element-xxl-1-4 {
    grid-column: span 1;
    grid-row: span 4;
  }
  .element-xxl-2-1 {
    grid-column: span 2;
    grid-row: span 1;
  }
  .element-xxl-2-2 {
    grid-column: span 2;
    grid-row: span 2;
  }
  .element-xxl-2-3 {
    grid-column: span 2;
    grid-row: span 3;
  }
  .element-xxl-2-4 {
    grid-column: span 2;
    grid-row: span 4;
  }
  .element-xxl-3-1 {
    grid-column: span 3;
    grid-row: span 1;
  }
  .element-xxl-3-2 {
    grid-column: span 3;
    grid-row: span 2;
  }
  .element-xxl-3-3 {
    grid-column: span 3;
    grid-row: span 3;
  }
  .element-xxl-3-4 {
    grid-column: span 3;
    grid-row: span 4;
  }
  .element-xxl-4-1 {
    grid-column: span 4;
    grid-row: span 1;
  }
  .element-xxl-4-2 {
    grid-column: span 4;
    grid-row: span 2;
  }
  .element-xxl-4-3 {
    grid-column: span 4;
    grid-row: span 3;
  }
  .element-xxl-4-4 {
    grid-column: span 4;
    grid-row: span 4;
  }
}
@media screen and (min-width: 320px) {
  .element-sm-offset-0 {
    margin-left: 0;
  }
  .element-sm-1 {
    flex-basis: 8.3333333333%;
  }
  .element-sm-offset-1 {
    margin-left: 8.3333333333%;
  }
  .element-sm-2 {
    flex-basis: 16.6666666667%;
  }
  .element-sm-offset-2 {
    margin-left: 16.6666666667%;
  }
  .element-sm-3 {
    flex-basis: 25%;
  }
  .element-sm-offset-3 {
    margin-left: 25%;
  }
  .element-sm-4 {
    flex-basis: 33.3333333333%;
  }
  .element-sm-offset-4 {
    margin-left: 33.3333333333%;
  }
  .element-sm-5 {
    flex-basis: 41.6666666667%;
  }
  .element-sm-offset-5 {
    margin-left: 41.6666666667%;
  }
  .element-sm-6 {
    flex-basis: 50%;
  }
  .element-sm-offset-6 {
    margin-left: 50%;
  }
  .element-sm-7 {
    flex-basis: 58.3333333333%;
  }
  .element-sm-offset-7 {
    margin-left: 58.3333333333%;
  }
  .element-sm-8 {
    flex-basis: 66.6666666667%;
  }
  .element-sm-offset-8 {
    margin-left: 66.6666666667%;
  }
  .element-sm-9 {
    flex-basis: 75%;
  }
  .element-sm-offset-9 {
    margin-left: 75%;
  }
  .element-sm-10 {
    flex-basis: 83.3333333333%;
  }
  .element-sm-offset-10 {
    margin-left: 83.3333333333%;
  }
  .element-sm-11 {
    flex-basis: 91.6666666667%;
  }
  .element-sm-offset-11 {
    margin-left: 91.6666666667%;
  }
  .element-sm-12 {
    flex-basis: 100%;
  }
  .element-sm-offset-12 {
    margin-left: 100%;
  }
}
@media screen and (min-width: 768px) {
  .element-md-offset-0 {
    margin-left: 0;
  }
  .element-md-1 {
    flex-basis: 8.3333333333%;
  }
  .element-md-offset-1 {
    margin-left: 8.3333333333%;
  }
  .element-md-2 {
    flex-basis: 16.6666666667%;
  }
  .element-md-offset-2 {
    margin-left: 16.6666666667%;
  }
  .element-md-3 {
    flex-basis: 25%;
  }
  .element-md-offset-3 {
    margin-left: 25%;
  }
  .element-md-4 {
    flex-basis: 33.3333333333%;
  }
  .element-md-offset-4 {
    margin-left: 33.3333333333%;
  }
  .element-md-5 {
    flex-basis: 41.6666666667%;
  }
  .element-md-offset-5 {
    margin-left: 41.6666666667%;
  }
  .element-md-6 {
    flex-basis: 50%;
  }
  .element-md-offset-6 {
    margin-left: 50%;
  }
  .element-md-7 {
    flex-basis: 58.3333333333%;
  }
  .element-md-offset-7 {
    margin-left: 58.3333333333%;
  }
  .element-md-8 {
    flex-basis: 66.6666666667%;
  }
  .element-md-offset-8 {
    margin-left: 66.6666666667%;
  }
  .element-md-9 {
    flex-basis: 75%;
  }
  .element-md-offset-9 {
    margin-left: 75%;
  }
  .element-md-10 {
    flex-basis: 83.3333333333%;
  }
  .element-md-offset-10 {
    margin-left: 83.3333333333%;
  }
  .element-md-11 {
    flex-basis: 91.6666666667%;
  }
  .element-md-offset-11 {
    margin-left: 91.6666666667%;
  }
  .element-md-12 {
    flex-basis: 100%;
  }
  .element-md-offset-12 {
    margin-left: 100%;
  }
}
@media screen and (min-width: 1024px) {
  .element-lg-offset-0 {
    margin-left: 0;
  }
  .element-lg-1 {
    flex-basis: 8.3333333333%;
  }
  .element-lg-offset-1 {
    margin-left: 8.3333333333%;
  }
  .element-lg-2 {
    flex-basis: 16.6666666667%;
  }
  .element-lg-offset-2 {
    margin-left: 16.6666666667%;
  }
  .element-lg-3 {
    flex-basis: 25%;
  }
  .element-lg-offset-3 {
    margin-left: 25%;
  }
  .element-lg-4 {
    flex-basis: 33.3333333333%;
  }
  .element-lg-offset-4 {
    margin-left: 33.3333333333%;
  }
  .element-lg-5 {
    flex-basis: 41.6666666667%;
  }
  .element-lg-offset-5 {
    margin-left: 41.6666666667%;
  }
  .element-lg-6 {
    flex-basis: 50%;
  }
  .element-lg-offset-6 {
    margin-left: 50%;
  }
  .element-lg-7 {
    flex-basis: 58.3333333333%;
  }
  .element-lg-offset-7 {
    margin-left: 58.3333333333%;
  }
  .element-lg-8 {
    flex-basis: 66.6666666667%;
  }
  .element-lg-offset-8 {
    margin-left: 66.6666666667%;
  }
  .element-lg-9 {
    flex-basis: 75%;
  }
  .element-lg-offset-9 {
    margin-left: 75%;
  }
  .element-lg-10 {
    flex-basis: 83.3333333333%;
  }
  .element-lg-offset-10 {
    margin-left: 83.3333333333%;
  }
  .element-lg-11 {
    flex-basis: 91.6666666667%;
  }
  .element-lg-offset-11 {
    margin-left: 91.6666666667%;
  }
  .element-lg-12 {
    flex-basis: 100%;
  }
  .element-lg-offset-12 {
    margin-left: 100%;
  }
}
@media screen and (min-width: 1280px) {
  .element-xl-offset-0 {
    margin-left: 0;
  }
  .element-xl-1 {
    flex-basis: 8.3333333333%;
  }
  .element-xl-offset-1 {
    margin-left: 8.3333333333%;
  }
  .element-xl-2 {
    flex-basis: 16.6666666667%;
  }
  .element-xl-offset-2 {
    margin-left: 16.6666666667%;
  }
  .element-xl-3 {
    flex-basis: 25%;
  }
  .element-xl-offset-3 {
    margin-left: 25%;
  }
  .element-xl-4 {
    flex-basis: 33.3333333333%;
  }
  .element-xl-offset-4 {
    margin-left: 33.3333333333%;
  }
  .element-xl-5 {
    flex-basis: 41.6666666667%;
  }
  .element-xl-offset-5 {
    margin-left: 41.6666666667%;
  }
  .element-xl-6 {
    flex-basis: 50%;
  }
  .element-xl-offset-6 {
    margin-left: 50%;
  }
  .element-xl-7 {
    flex-basis: 58.3333333333%;
  }
  .element-xl-offset-7 {
    margin-left: 58.3333333333%;
  }
  .element-xl-8 {
    flex-basis: 66.6666666667%;
  }
  .element-xl-offset-8 {
    margin-left: 66.6666666667%;
  }
  .element-xl-9 {
    flex-basis: 75%;
  }
  .element-xl-offset-9 {
    margin-left: 75%;
  }
  .element-xl-10 {
    flex-basis: 83.3333333333%;
  }
  .element-xl-offset-10 {
    margin-left: 83.3333333333%;
  }
  .element-xl-11 {
    flex-basis: 91.6666666667%;
  }
  .element-xl-offset-11 {
    margin-left: 91.6666666667%;
  }
  .element-xl-12 {
    flex-basis: 100%;
  }
  .element-xl-offset-12 {
    margin-left: 100%;
  }
}
@media screen and (min-width: 1440px) {
  .element-xxl-offset-0 {
    margin-left: 0;
  }
  .element-xxl-1 {
    flex-basis: 8.3333333333%;
  }
  .element-xxl-offset-1 {
    margin-left: 8.3333333333%;
  }
  .element-xxl-2 {
    flex-basis: 16.6666666667%;
  }
  .element-xxl-offset-2 {
    margin-left: 16.6666666667%;
  }
  .element-xxl-3 {
    flex-basis: 25%;
  }
  .element-xxl-offset-3 {
    margin-left: 25%;
  }
  .element-xxl-4 {
    flex-basis: 33.3333333333%;
  }
  .element-xxl-offset-4 {
    margin-left: 33.3333333333%;
  }
  .element-xxl-5 {
    flex-basis: 41.6666666667%;
  }
  .element-xxl-offset-5 {
    margin-left: 41.6666666667%;
  }
  .element-xxl-6 {
    flex-basis: 50%;
  }
  .element-xxl-offset-6 {
    margin-left: 50%;
  }
  .element-xxl-7 {
    flex-basis: 58.3333333333%;
  }
  .element-xxl-offset-7 {
    margin-left: 58.3333333333%;
  }
  .element-xxl-8 {
    flex-basis: 66.6666666667%;
  }
  .element-xxl-offset-8 {
    margin-left: 66.6666666667%;
  }
  .element-xxl-9 {
    flex-basis: 75%;
  }
  .element-xxl-offset-9 {
    margin-left: 75%;
  }
  .element-xxl-10 {
    flex-basis: 83.3333333333%;
  }
  .element-xxl-offset-10 {
    margin-left: 83.3333333333%;
  }
  .element-xxl-11 {
    flex-basis: 91.6666666667%;
  }
  .element-xxl-offset-11 {
    margin-left: 91.6666666667%;
  }
  .element-xxl-12 {
    flex-basis: 100%;
  }
  .element-xxl-offset-12 {
    margin-left: 100%;
  }
}
a,
abbr,
acronym,
address,
applet,
article,
aside,
audio,
b,
big,
blockquote,
body,
canvas,
caption,
center,
cite,
code,
dd,
del,
details,
dfn,
div,
dl,
dt,
em,
embed,
fieldset,
figcaption,
figure,
footer,
form,
h1,
h2,
h3,
h4,
h5,
h6,
header,
hgroup,
html,
i,
iframe,
img,
ins,
kbd,
label,
legend,
li,
mark,
menu,
nav,
object,
ol,
output,
p,
pre,
q,
ruby,
s,
samp,
section,
small,
span,
strike,
strong,
sub,
summary,
sup,
table,
tbody,
td,
tfoot,
th,
thead,
time,
tr,
tt,
u,
ul,
var,
video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
}

article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
  display: block;
}

html {
  overflow-x: hidden;
  max-width: 100vw;
}

body {
  line-height: 1;
}

ol,
ul {
  list-style: none;
}

blockquote,
q {
  quotes: none;
}

blockquote:after,
blockquote:before,
q:after,
q:before {
  content: "";
  content: none;
}

a,
button {
  text-decoration: none;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

input,
textarea {
  box-sizing: border-box;
  -webkit-appearance: none;
  appearance: none;
  border: none;
  line-height: 1;
}

*:focus {
  outline: none;
}

form {
  overflow: hidden;
}

::-moz-selection {
  background: rgb(0, 0, 0);
  color: white;
}

::selection {
  background: rgb(0, 0, 0);
  color: white;
}

@-ms-viewport {
  width: device-width;
}
*, *:before, *:after {
  box-sizing: inherit;
}

html {
  box-sizing: border-box;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

body {
  font-size: 1rem;
  line-height: 1.2;
  font-family: "DM Sans", sans-serif;
  font-weight: 500;
  font-family: "DM Sans", sans-serif;
  -webkit-overflow-scrolling: touch;
}
@media screen and (min-width: 320px) {
  body {
    font-size: 1rem;
  }
}
@media screen and (min-width: 640px) {
  body {
    font-size: 1rem;
  }
}
@media screen and (min-width: 768px) {
  body {
    font-size: 1.25rem;
  }
}
@media screen and (min-width: 1024px) {
  body {
    font-size: 1.25rem;
  }
}
@media screen and (min-width: 320px) {
  body {
    line-height: 1.2;
  }
}
@media screen and (min-width: 640px) {
  body {
    line-height: 1.2;
  }
}
@media screen and (min-width: 768px) {
  body {
    line-height: 1.2;
  }
}
@media screen and (min-width: 1024px) {
  body {
    line-height: 1.2;
  }
}
h1 {
  font-size: 2rem;
  line-height: 1.2;
  font-family: "DM Sans", sans-serif;
}
@media screen and (min-width: 320px) {
  h1 {
    font-size: 2rem;
  }
}
@media screen and (min-width: 640px) {
  h1 {
    font-size: 2.5rem;
  }
}
@media screen and (min-width: 768px) {
  h1 {
    font-size: 3.375rem;
  }
}
@media screen and (min-width: 1024px) {
  h1 {
    font-size: 4.2rem;
  }
}
@media screen and (min-width: 1280px) {
  h1 {
    font-size: 4.2rem;
  }
}
@media screen and (min-width: 1440px) {
  h1 {
    font-size: 4.2rem;
  }
}
@media screen and (min-width: 320px) {
  h1 {
    line-height: 1.2;
  }
}
@media screen and (min-width: 640px) {
  h1 {
    line-height: 1.2;
  }
}
@media screen and (min-width: 768px) {
  h1 {
    line-height: 1.2;
  }
}
@media screen and (min-width: 1024px) {
  h1 {
    line-height: 1.2;
  }
}
h1.ce_headline {
  margin: 0 auto;
  width: 90%;
  max-width: 1100px;
  margin-bottom: 1.5625rem;
  text-align: center;
}
@media screen and (min-width: 320px) {
  h1.ce_headline {
    margin-bottom: 1.5625rem;
  }
}
@media screen and (min-width: 640px) {
  h1.ce_headline {
    margin-bottom: 1.5625rem;
  }
}
@media screen and (min-width: 768px) {
  h1.ce_headline {
    margin-bottom: 1.875rem;
  }
}
@media screen and (min-width: 1024px) {
  h1.ce_headline {
    margin-bottom: 2.5rem;
  }
}
h1.ce_headline + .lead-text {
  padding: 0;
  margin-top: 2.5rem;
}
@media screen and (min-width: 320px) {
  h1.ce_headline + .lead-text {
    padding: 0;
  }
}
@media screen and (min-width: 640px) {
  h1.ce_headline + .lead-text {
    padding: 0;
  }
}
@media screen and (min-width: 768px) {
  h1.ce_headline + .lead-text {
    padding: 0;
  }
}
@media screen and (min-width: 320px) {
  h1.ce_headline + .lead-text {
    margin-top: -2.5rem;
  }
}
@media screen and (min-width: 640px) {
  h1.ce_headline + .lead-text {
    margin-top: -2.5rem;
  }
}
@media screen and (min-width: 768px) {
  h1.ce_headline + .lead-text {
    margin-top: -3.125rem;
  }
}
@media screen and (min-width: 1024px) {
  h1.ce_headline + .lead-text {
    margin-top: -3.75rem;
  }
}
h2 {
  font-size: 2rem;
  line-height: 1.2;
}
@media screen and (min-width: 320px) {
  h2 {
    font-size: 2rem;
  }
}
@media screen and (min-width: 640px) {
  h2 {
    font-size: 2.4375rem;
  }
}
@media screen and (min-width: 768px) {
  h2 {
    font-size: 3.4375rem;
  }
}
@media screen and (min-width: 1024px) {
  h2 {
    font-size: 3.75rem;
  }
}
@media screen and (min-width: 1280px) {
  h2 {
    font-size: 4rem;
  }
}
@media screen and (min-width: 1440px) {
  h2 {
    font-size: 4rem;
  }
}
@media screen and (min-width: 320px) {
  h2 {
    line-height: 1.2;
  }
}
@media screen and (min-width: 640px) {
  h2 {
    line-height: 1.2;
  }
}
@media screen and (min-width: 768px) {
  h2 {
    line-height: 1.2;
  }
}
@media screen and (min-width: 1024px) {
  h2 {
    line-height: 1.2;
  }
}
h3 {
  font-size: 1.575rem;
  line-height: 1.2;
}
@media screen and (min-width: 320px) {
  h3 {
    font-size: 1.575rem;
  }
}
@media screen and (min-width: 640px) {
  h3 {
    font-size: 2.1875rem;
  }
}
@media screen and (min-width: 768px) {
  h3 {
    font-size: 2.1875rem;
  }
}
@media screen and (min-width: 1024px) {
  h3 {
    font-size: 2.8125rem;
  }
}
@media screen and (min-width: 1280px) {
  h3 {
    font-size: 2.8125rem;
  }
}
@media screen and (min-width: 320px) {
  h3 {
    line-height: 1.2;
  }
}
@media screen and (min-width: 640px) {
  h3 {
    line-height: 1.2;
  }
}
@media screen and (min-width: 768px) {
  h3 {
    line-height: 1.2;
  }
}
@media screen and (min-width: 1024px) {
  h3 {
    line-height: 1.2;
  }
}
h4 {
  font-size: 1.125rem;
  line-height: 1.2;
}
@media screen and (min-width: 320px) {
  h4 {
    font-size: 1.125rem;
  }
}
@media screen and (min-width: 640px) {
  h4 {
    font-size: 1.25rem;
  }
}
@media screen and (min-width: 768px) {
  h4 {
    font-size: 1.375rem;
  }
}
@media screen and (min-width: 1024px) {
  h4 {
    font-size: 1.375rem;
  }
}
@media screen and (min-width: 1280px) {
  h4 {
    font-size: 1.5rem;
  }
}
@media screen and (min-width: 320px) {
  h4 {
    line-height: 1.2;
  }
}
@media screen and (min-width: 640px) {
  h4 {
    line-height: 1.2;
  }
}
@media screen and (min-width: 768px) {
  h4 {
    line-height: 1.2;
  }
}
@media screen and (min-width: 1024px) {
  h4 {
    line-height: 1.2;
  }
}
a {
  color: #000000;
  font-weight: 400;
}

img {
  width: 100%;
  height: auto;
}

input,
textarea {
  font-family: "DM Sans", sans-serif;
}

p,
span {
  line-height: 1.2;
}
@media screen and (min-width: 320px) {
  p,
  span {
    line-height: 1.2;
  }
}
@media screen and (min-width: 640px) {
  p,
  span {
    line-height: 1.2;
  }
}
@media screen and (min-width: 768px) {
  p,
  span {
    line-height: 1.2;
  }
}
@media screen and (min-width: 1024px) {
  p,
  span {
    line-height: 1.2;
  }
}
.invisible {
  display: none;
}

.text-normal {
  font-size: 1.125rem;
  font-weight: 400;
}
@media screen and (min-width: 320px) {
  .text-normal {
    font-size: 1.125rem;
  }
}
@media screen and (min-width: 640px) {
  .text-normal {
    font-size: 1.25rem;
  }
}
@media screen and (min-width: 768px) {
  .text-normal {
    font-size: 1.375rem;
  }
}
@media screen and (min-width: 1024px) {
  .text-normal {
    font-size: 1.375rem;
  }
}
.footer .text-normal {
  margin-top: 0.3125rem;
  margin-bottom: 1.5625rem;
  max-width: 600px;
  width: 100%;
  text-align: center;
}
@media screen and (min-width: 320px) {
  .footer .text-normal {
    margin-top: 0.3125rem;
  }
}
@media screen and (min-width: 640px) {
  .footer .text-normal {
    margin-top: 0.3125rem;
  }
}
@media screen and (min-width: 768px) {
  .footer .text-normal {
    margin-top: 0.625rem;
  }
}
@media screen and (min-width: 320px) {
  .footer .text-normal {
    margin-bottom: 1.5625rem;
  }
}
@media screen and (min-width: 640px) {
  .footer .text-normal {
    margin-bottom: 1.5625rem;
  }
}
@media screen and (min-width: 768px) {
  .footer .text-normal {
    margin-bottom: 1.875rem;
  }
}
@media screen and (min-width: 1024px) {
  .footer .text-normal {
    margin-bottom: 2.5rem;
  }
}
select {
  -moz-appearance: none;
  -webkit-appearance: none;
}

select::-ms-expand {
  display: none;
}

.animated-bg {
  position: relative;
  overflow: hidden;
}
.animated-bg:before {
  content: "";
  position: absolute;
  left: 0;
  display: block;
  width: 900vw;
  height: 100%;
  background: linear-gradient(83deg, rgb(95, 236, 171) 0%, rgb(71, 178, 219) 6%, rgb(110, 41, 220) 18%, rgb(129, 45, 170) 27%, rgb(178, 68, 100) 39%, rgb(209, 69, 102) 46%, rgb(178, 68, 100) 52%, rgb(129, 45, 170) 60%, rgb(110, 41, 220) 69%, rgb(71, 178, 219) 78%, rgb(95, 236, 171) 89%, rgb(71, 178, 219) 95%, rgb(88, 120, 219) 100%);
  animation: falloff 55s linear infinite;
  animation-fill-mode: forwards;
  will-change: transform;
}
.animated-bg > * {
  color: #ffffff;
}

.danke .product__top {
  text-align: center;
}
.danke .footer {
  display: none;
}

.data header,
.impressum header {
  position: relative;
  overflow-x: hidden;
}
.data header:before,
.impressum header:before {
  content: "";
  position: absolute;
  left: 0;
  display: block;
  width: 900vw;
  height: 100%;
  background: linear-gradient(83deg, rgb(95, 236, 171) 0%, rgb(71, 178, 219) 6%, rgb(110, 41, 220) 18%, rgb(129, 45, 170) 27%, rgb(178, 68, 100) 39%, rgb(209, 69, 102) 46%, rgb(178, 68, 100) 52%, rgb(129, 45, 170) 60%, rgb(110, 41, 220) 69%, rgb(71, 178, 219) 78%, rgb(95, 236, 171) 89%, rgb(71, 178, 219) 95%, rgb(88, 120, 219) 100%);
  animation: falloff 55s linear infinite;
  animation-fill-mode: forwards;
}

.no-scroll {
  overflow: hidden;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
  border: 1px solid #FFFFFF;
  -webkit-text-fill-color: #FFFFFF;
  -webkit-box-shadow: 0 0 0 1000px rgb(135, 135, 135) inset;
}

.insicht.insicht--fade-in {
  opacity: 0;
}
.no-js .insicht.insicht--fade-in, .page--ready .insicht.insicht--fade-in.insicht--sichtbar {
  opacity: 1;
  transition: opacity 2s 0.4s cubic-bezier(0.25, 0.46, 0.45, 0.94);
}
.no-js .insicht.insicht--fade-in.insicht--fast, .page--ready .insicht.insicht--fade-in.insicht--sichtbar.insicht--fast {
  transition-duration: 1s;
}

.insicht.insicht--fade-up {
  opacity: 0;
  transform: translate3d(0, 2rem, 0);
}
.no-js .insicht.insicht--fade-up, .page--ready .insicht.insicht--fade-up.insicht--sichtbar {
  opacity: 1;
  transform: none;
  transition: opacity 1.6s 0.4s cubic-bezier(0.25, 0.46, 0.45, 0.94), transform 1.2s 0.4s cubic-bezier(0.19, 1, 0.22, 1);
}
.no-js .insicht.insicht--fade-up.insicht--fast, .page--ready .insicht.insicht--fade-up.insicht--sichtbar.insicht--fast {
  transition-duration: 1s, 1.2s;
}

.insicht.insicht--fade-down {
  opacity: 0;
  transform: translate3d(0, -2rem, 0);
}
.no-js .insicht.insicht--fade-down, .page--ready .insicht.insicht--fade-down.insicht--sichtbar {
  opacity: 1;
  transform: none;
  transition: opacity 2s 0.4s cubic-bezier(0.25, 0.46, 0.45, 0.94), transform 2.4s 0.4s cubic-bezier(0.19, 1, 0.22, 1);
}
.no-js .insicht.insicht--fade-down.insicht--fast, .page--ready .insicht.insicht--fade-down.insicht--sichtbar.insicht--fast {
  transition-duration: 1s, 1.2s;
}

.insicht.insicht--fade-left {
  opacity: 0;
  transform: translate3d(-2rem, 0, 0);
}
.no-js .insicht.insicht--fade-left, .page--ready .insicht.insicht--fade-left.insicht--sichtbar {
  opacity: 1;
  transform: none;
  transition: opacity 2s 0.4s cubic-bezier(0.25, 0.46, 0.45, 0.94), transform 2.4s 0.4s cubic-bezier(0.19, 1, 0.22, 1);
}
.no-js .insicht.insicht--fade-left.insicht--fast, .page--ready .insicht.insicht--fade-left.insicht--sichtbar.insicht--fast {
  transition-duration: 1s, 1.2s;
}

.insicht.insicht--fade-right {
  opacity: 0;
  transform: translate3d(2rem, 0, 0);
}
.no-js .insicht.insicht--fade-right, .page--ready .insicht.insicht--fade-right.insicht--sichtbar {
  opacity: 1;
  transform: none;
  transition: opacity 2s 0.4s cubic-bezier(0.25, 0.46, 0.45, 0.94), transform 2.4s 0.4s cubic-bezier(0.19, 1, 0.22, 1);
}
.no-js .insicht.insicht--fade-right.insicht--fast, .page--ready .insicht.insicht--fade-right.insicht--sichtbar.insicht--fast {
  transition-duration: 1s, 1.2s;
}

.insicht.insicht--scale-up {
  opacity: 0;
  transform: scale(0);
}
.no-js .insicht.insicht--scale-up, .page--ready .insicht.insicht--scale-up.insicht--sichtbar {
  opacity: 1;
  transform: none;
  transition: opacity 0s 0.4s, transform 2.4s 0.4s cubic-bezier(0.19, 1, 0.22, 1);
}
.no-js .insicht.insicht--scale-up.insicht--fast, .page--ready .insicht.insicht--scale-up.insicht--sichtbar.insicht--fast {
  transition-duration: 0s, 0.6s;
}

.insicht.insicht--scale-down {
  opacity: 0;
  transform: scale(2);
}
.no-js .insicht.insicht--scale-down, .page--ready .insicht.insicht--scale-down.insicht--sichtbar {
  opacity: 1;
  transform: none;
  transition: opacity 0s 0.4s, transform 2.4s 0.4s cubic-bezier(0.19, 1, 0.22, 1);
}
.no-js .insicht.insicht--scale-down.insicht--fast, .page--ready .insicht.insicht--scale-down.insicht--sichtbar.insicht--fast {
  transition-duration: 0s, 0.6s;
}

.insicht.insicht--scale-fade-up {
  opacity: 0;
  transform: scale(0);
}
.no-js .insicht.insicht--scale-fade-up, .page--ready .insicht.insicht--scale-fade-up.insicht--sichtbar {
  opacity: 1;
  transform: none;
  transition: opacity 2s 0.4s cubic-bezier(0.25, 0.46, 0.45, 0.94), transform 2.4s 0.4s cubic-bezier(0.19, 1, 0.22, 1);
}
.no-js .insicht.insicht--scale-fade-up.insicht--fast, .page--ready .insicht.insicht--scale-fade-up.insicht--sichtbar.insicht--fast {
  transition-duration: 1s, 0.6s;
}

.insicht.insicht--scale-fade-down {
  opacity: 0;
  transform: scale(2);
}
.no-js .insicht.insicht--scale-fade-down, .page--ready .insicht.insicht--scale-fade-down.insicht--sichtbar {
  opacity: 1;
  transform: none;
  transition: opacity 2s 0.4s cubic-bezier(0.25, 0.46, 0.45, 0.94), transform 2.4s 0.4s cubic-bezier(0.19, 1, 0.22, 1);
}
.no-js .insicht.insicht--scale-fade-down.insicht--fast, .page--ready .insicht.insicht--scale-fade-down.insicht--sichtbar.insicht--fast {
  transition-duration: 1s, 0.6s;
}

.page-loader {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: flex;
  transition: 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94);
  z-index: 99999999999;
}
.page--loaded .page-loader {
  opacity: 0;
  pointer-events: none;
  visibility: hidden;
}

.header {
  width: auto;
  margin-right: calc(-1 * (100vw - 94vw) / 2);
  margin-left: calc(-1 * (100vw - 94vw) / 2);
  height: 5.4rem;
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  padding: 0;
  margin: 0;
  -webkit-flex-wrap: wrap;
  flex-wrap: wrap;
  justify-content: space-around;
  justify-content: center;
  align-items: flex-start;
  margin: 0 auto;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  background: none;
  z-index: 99;
}
@media screen and (min-width: 320px) {
  .header {
    height: 5.4rem;
  }
}
@media screen and (min-width: 640px) {
  .header {
    height: 5.4rem;
  }
}
@media screen and (min-width: 768px) {
  .header {
    height: 5.4rem;
  }
}
@media screen and (min-width: 1024px) {
  .header {
    height: 6.7rem;
  }
}
@media screen and (min-width: 1280px) {
  .header {
    height: 6.7rem;
  }
}
@media screen and (min-width: 1440px) {
  .header {
    height: 6.7rem;
  }
}
@media screen and (min-width: 1600px) {
  .header {
    height: 6.7rem;
  }
}
@media screen and (min-width: 768px) {
  .header {
    padding-bottom: 0;
  }
}
@media screen and (min-width: 768px) and (min-width: 320px) {
  .header {
    padding-bottom: 0;
  }
}
@media screen and (min-width: 768px) and (min-width: 640px) {
  .header {
    padding-bottom: 0;
  }
}
@media screen and (min-width: 768px) and (min-width: 768px) {
  .header {
    padding-bottom: 0;
  }
}
.header__container {
  margin: 0 auto;
  width: 94vw;
  max-width: calc(100vw - 30px);
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  padding: 0;
  margin: 0;
  -webkit-flex-wrap: wrap;
  flex-wrap: wrap;
  justify-content: space-around;
  justify-content: space-between;
  align-items: center;
  margin: 0 auto;
  position: relative;
  z-index: 99999;
}

.footer {
  overflow: hidden;
}
.footer__content {
  padding-top: 3.75rem;
  padding-bottom: 3.75rem;
  line-height: 1.2;
  margin: 0 auto;
  width: 94vw;
  max-width: calc(100vw - 30px);
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  padding: 0;
  margin: 0;
  -webkit-flex-wrap: wrap;
  flex-wrap: wrap;
  justify-content: space-around;
  flex-flow: column;
  align-items: center;
  margin: 0 auto;
  color: #ffffff;
  position: relative;
}
@media screen and (min-width: 320px) {
  .footer__content {
    padding-top: 3.75rem;
  }
}
@media screen and (min-width: 640px) {
  .footer__content {
    padding-top: 3.75rem;
  }
}
@media screen and (min-width: 768px) {
  .footer__content {
    padding-top: 5.3125rem;
  }
}
@media screen and (min-width: 1024px) {
  .footer__content {
    padding-top: 6.25rem;
  }
}
@media screen and (min-width: 1280px) {
  .footer__content {
    padding-top: 6.25rem;
  }
}
@media screen and (min-width: 320px) {
  .footer__content {
    padding-bottom: 3.75rem;
  }
}
@media screen and (min-width: 640px) {
  .footer__content {
    padding-bottom: 3.75rem;
  }
}
@media screen and (min-width: 768px) {
  .footer__content {
    padding-bottom: 5.3125rem;
  }
}
@media screen and (min-width: 1024px) {
  .footer__content {
    padding-bottom: 6.25rem;
  }
}
@media screen and (min-width: 1280px) {
  .footer__content {
    padding-bottom: 6.25rem;
  }
}
@media screen and (min-width: 320px) {
  .footer__content {
    line-height: 1.2;
  }
}
@media screen and (min-width: 640px) {
  .footer__content {
    line-height: 1.2;
  }
}
@media screen and (min-width: 768px) {
  .footer__content {
    line-height: 1.2;
  }
}
@media screen and (min-width: 1024px) {
  .footer__content {
    line-height: 1.2;
  }
}
.footer__content h2 {
  text-align: center;
}
.footer__form {
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  padding: 0;
  margin: 0;
  -webkit-flex-wrap: wrap;
  flex-wrap: wrap;
  justify-content: space-around;
  justify-content: space-between;
  flex-flow: column;
  max-width: 585px;
  width: 100%;
  overflow: visible;
}
.footer__bottom {
  margin: 0 auto;
  width: 90%;
  max-width: 385px;
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  padding: 0;
  margin: 0;
  -webkit-flex-wrap: wrap;
  flex-wrap: wrap;
  justify-content: space-around;
  justify-content: center;
  flex-flow: row wrap;
}
.footer__bottom > .link {
  flex: 0 50%;
  text-align: center;
  font-weight: 400;
}
.footer__bottom .location {
  margin-bottom: 1.5625rem;
  max-width: 200px;
  text-align: center;
  font-weight: 400;
}
@media screen and (min-width: 320px) {
  .footer__bottom .location {
    margin-bottom: 1.5625rem;
  }
}
@media screen and (min-width: 640px) {
  .footer__bottom .location {
    margin-bottom: 1.5625rem;
  }
}
@media screen and (min-width: 768px) {
  .footer__bottom .location {
    margin-bottom: 1.875rem;
  }
}
@media screen and (min-width: 1024px) {
  .footer__bottom .location {
    margin-bottom: 2.5rem;
  }
}
.footer__bottom a {
  color: #ffffff;
}

.main-logo {
  font-size: 1.125rem;
  line-height: 0;
}
@media screen and (min-width: 320px) {
  .main-logo {
    font-size: 1.125rem;
  }
}
@media screen and (min-width: 640px) {
  .main-logo {
    font-size: 1.25rem;
  }
}
@media screen and (min-width: 768px) {
  .main-logo {
    font-size: 1.375rem;
  }
}
@media screen and (min-width: 1024px) {
  .main-logo {
    font-size: 1.375rem;
  }
}
@media screen and (min-width: 1280px) {
  .main-logo {
    font-size: 1.5rem;
  }
}
.main-logo a {
  display: block;
  color: #ffffff;
}
.main-logo__logo {
  width: 8rem;
  height: auto;
}
@media screen and (min-width: 1024px) {
  .main-logo__logo {
    width: 12rem;
  }
}

.navigation {
  padding-top: 1.25rem;
  padding-bottom: 1.25rem;
}
@media screen and (min-width: 320px) {
  .navigation {
    padding-top: 1.25rem;
  }
}
@media screen and (min-width: 640px) {
  .navigation {
    padding-top: 1.25rem;
  }
}
@media screen and (min-width: 768px) {
  .navigation {
    padding-top: 1.25rem;
  }
}
@media screen and (min-width: 1024px) {
  .navigation {
    padding-top: 1.875rem;
  }
}
@media screen and (min-width: 320px) {
  .navigation {
    padding-bottom: 1.25rem;
  }
}
@media screen and (min-width: 640px) {
  .navigation {
    padding-bottom: 1.25rem;
  }
}
@media screen and (min-width: 768px) {
  .navigation {
    padding-bottom: 1.25rem;
  }
}
@media screen and (min-width: 1024px) {
  .navigation {
    padding-bottom: 1.875rem;
  }
}
.navigation__items {
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  padding: 0;
  margin: 0;
  -webkit-flex-wrap: wrap;
  flex-wrap: wrap;
  justify-content: space-around;
  justify-content: space-between;
  align-items: center;
}
.navigation__element {
  font-size: 1rem;
  margin-right: 0.9375rem;
}
@media screen and (min-width: 320px) {
  .navigation__element {
    font-size: 1rem;
  }
}
@media screen and (min-width: 640px) {
  .navigation__element {
    font-size: 1rem;
  }
}
@media screen and (min-width: 768px) {
  .navigation__element {
    font-size: 1.25rem;
  }
}
@media screen and (min-width: 1024px) {
  .navigation__element {
    font-size: 1.25rem;
  }
}
@media screen and (min-width: 320px) {
  .navigation__element {
    margin-right: 0.9375rem;
  }
}
@media screen and (min-width: 640px) {
  .navigation__element {
    margin-right: 0.9375rem;
  }
}
@media screen and (min-width: 768px) {
  .navigation__element {
    margin-right: 1.25rem;
  }
}
.navigation__element:last-of-type {
  margin-right: 0;
}
@media screen and (min-width: 320px) {
  .navigation__element:last-of-type {
    margin-right: 0;
  }
}
@media screen and (min-width: 640px) {
  .navigation__element:last-of-type {
    margin-right: 0;
  }
}
@media screen and (min-width: 768px) {
  .navigation__element:last-of-type {
    margin-right: 0;
  }
}
.navigation__element a {
  color: #ffffff;
}
.navigation__element--solutions {
  display: none;
}
@media screen and (min-width: 768px) {
  .navigation__element--solutions {
    display: block;
  }
}
.navigation__element--request {
  padding-top: 0.3125rem;
  padding-bottom: 0.3125rem;
  display: block;
  padding-left: 0.5375rem;
  padding-right: 0.5375rem;
  border-radius: 5px;
  background: #ffffff;
}
@media screen and (min-width: 320px) {
  .navigation__element--request {
    padding-top: 0.3125rem;
  }
}
@media screen and (min-width: 640px) {
  .navigation__element--request {
    padding-top: 0.3125rem;
  }
}
@media screen and (min-width: 768px) {
  .navigation__element--request {
    padding-top: 0.625rem;
  }
}
@media screen and (min-width: 320px) {
  .navigation__element--request {
    padding-bottom: 0.3125rem;
  }
}
@media screen and (min-width: 640px) {
  .navigation__element--request {
    padding-bottom: 0.3125rem;
  }
}
@media screen and (min-width: 768px) {
  .navigation__element--request {
    padding-bottom: 0.625rem;
  }
}
@media screen and (min-width: 640px) {
  .navigation__element--request {
    padding-right: 0.9375rem;
    padding-left: 0.9375rem;
  }
}
@media screen and (min-width: 640px) and (min-width: 320px) {
  .navigation__element--request {
    padding-right: 0.9375rem;
  }
}
@media screen and (min-width: 640px) and (min-width: 640px) {
  .navigation__element--request {
    padding-right: 0.9375rem;
  }
}
@media screen and (min-width: 640px) and (min-width: 768px) {
  .navigation__element--request {
    padding-right: 1.25rem;
  }
}
@media screen and (min-width: 640px) and (min-width: 320px) {
  .navigation__element--request {
    padding-left: 0.9375rem;
  }
}
@media screen and (min-width: 640px) and (min-width: 640px) {
  .navigation__element--request {
    padding-left: 0.9375rem;
  }
}
@media screen and (min-width: 640px) and (min-width: 768px) {
  .navigation__element--request {
    padding-left: 1.25rem;
  }
}
.navigation__element--request a {
  display: flex;
  justify-content: space-between;
  color: #000000;
}
@media screen and (min-width: 768px) {
  .navigation__element--request a:hover svg {
    transform: scale(1.06);
  }
}
.navigation__element--request a span {
  -webkit-transition: transform 0.2s ease-in-out;
  -moz-transition: transform 0.2s ease-in-out;
  -ms-transition: transform 0.2s ease-in-out;
  -o-transition: transform 0.2s ease-in-out;
  transition: transform 0.2s ease-in-out;
}
@media screen and (min-width: 768px) {
  .navigation__element--request a svg {
    -webkit-transition: transform 0.2s ease-in-out;
    -moz-transition: transform 0.2s ease-in-out;
    -ms-transition: transform 0.2s ease-in-out;
    -o-transition: transform 0.2s ease-in-out;
    transition: transform 0.2s ease-in-out;
    display: block;
    width: 20px;
    margin-left: 5px;
  }
}
.navigation__element--mobile-menu {
  position: relative;
  width: 30px;
  height: 30px;
}
@media screen and (min-width: 768px) {
  .navigation__element--mobile-menu {
    display: none;
  }
}
@media screen and (min-width: 768px) {
  .navigation__element--ext-link {
    margin-right: 0;
  }
}
.navigation__custom-link {
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  padding: 0;
  margin: 0;
  -webkit-flex-wrap: wrap;
  flex-wrap: wrap;
  justify-content: space-around;
  justify-content: center;
  align-items: center;
  height: 47px;
}
.navigation__custom-link > .navigation__text {
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  padding: 0;
  margin: 0;
  -webkit-flex-wrap: wrap;
  flex-wrap: wrap;
  justify-content: space-around;
  justify-content: center;
  align-items: center;
  font-weight: 400;
}
.navigation__text {
  font-weight: 400;
}
.navigation__text--hide-mobile {
  display: none;
}
@media screen and (min-width: 768px) {
  .navigation__text--hide-mobile {
    display: block;
  }
}
.navigation__mobile-icon {
  display: flex;
  align-items: center;
  justify-content: center;
}
@media screen and (min-width: 768px) {
  .navigation__mobile-icon {
    display: none;
  }
}
body:not(.home) .navigation__element--request .navigation__link {
  animation: none;
}

.hero-image {
  width: auto;
  margin-right: calc(-1 * (100vw - 100vw) / 2);
  margin-left: calc(-1 * (100vw - 100vw) / 2);
  margin-right: calc(-1 * (100vw - 100%) / 2);
  margin-left: calc(-1 * (100vw - 100%) / 2);
  margin-left: 0;
  margin-right: 0;
  position: relative;
}
@media screen and (max-width: 100%) {
  .hero-image {
    margin-right: calc(-1 * (100vw - 100vw) / 2);
    margin-left: calc(-1 * (100vw - 100vw) / 2);
  }
}
@media screen and (min-width: 320px) {
  .hero-image {
    height: calc(80vh - 5.4rem);
  }
}
@media screen and (min-width: 640px) {
  .hero-image {
    height: calc(80vh - 5.4rem);
  }
}
@media screen and (min-width: 768px) {
  .hero-image {
    height: calc(80vh - 5.4rem);
  }
}
@media screen and (min-width: 1024px) {
  .hero-image {
    height: calc(80vh - 6.7rem);
  }
}
@media screen and (min-width: 1280px) {
  .hero-image {
    height: calc(80vh - 6.7rem);
  }
}
@media screen and (min-width: 1440px) {
  .hero-image {
    height: calc(80vh - 6.7rem);
  }
}
@media screen and (min-width: 1600px) {
  .hero-image {
    height: calc(80vh - 6.7rem);
  }
}
@media screen and (max-width: 1023px) {
  .hero-image {
    background-image: url("/files/eatcommerce/images/placeholder_1_small.png");
  }
}
@media screen and (min-width: 1024px) {
  .hero-image {
    background-image: url("/files/eatcommerce/images/placeholder_1.png");
  }
}
.hero-image:before {
  content: "";
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(180deg, #000000, #7e7e7e);
  opacity: 0.2;
}
.danke .hero-image {
  height: 100vh;
}

.image-header-slider {
  position: relative;
}
@media screen and (min-width: 320px) {
  .image-header-slider {
    height: calc(80vh - 5.4rem);
  }
}
@media screen and (min-width: 640px) {
  .image-header-slider {
    height: calc(80vh - 5.4rem);
  }
}
@media screen and (min-width: 768px) {
  .image-header-slider {
    height: calc(80vh - 5.4rem);
  }
}
@media screen and (min-width: 1024px) {
  .image-header-slider {
    height: calc(80vh - 6.7rem);
  }
}
@media screen and (min-width: 1280px) {
  .image-header-slider {
    height: calc(80vh - 6.7rem);
  }
}
@media screen and (min-width: 1440px) {
  .image-header-slider {
    height: calc(80vh - 6.7rem);
  }
}
@media screen and (min-width: 1600px) {
  .image-header-slider {
    height: calc(80vh - 6.7rem);
  }
}
@media screen and (min-width: 320px) {
  .image-header-slider .slideshow__slide {
    height: calc(80vh - 5.4rem);
  }
}
@media screen and (min-width: 640px) {
  .image-header-slider .slideshow__slide {
    height: calc(80vh - 5.4rem);
  }
}
@media screen and (min-width: 768px) {
  .image-header-slider .slideshow__slide {
    height: calc(80vh - 5.4rem);
  }
}
@media screen and (min-width: 1024px) {
  .image-header-slider .slideshow__slide {
    height: calc(80vh - 6.7rem);
  }
}
@media screen and (min-width: 1280px) {
  .image-header-slider .slideshow__slide {
    height: calc(80vh - 6.7rem);
  }
}
@media screen and (min-width: 1440px) {
  .image-header-slider .slideshow__slide {
    height: calc(80vh - 6.7rem);
  }
}
@media screen and (min-width: 1600px) {
  .image-header-slider .slideshow__slide {
    height: calc(80vh - 6.7rem);
  }
}
.image-header-slider .slideshow__bullets:before {
  display: none;
}

.main-top {
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  padding: 0;
  margin: 0;
  -webkit-flex-wrap: wrap;
  flex-wrap: wrap;
  justify-content: space-around;
  flex-flow: column;
  justify-content: space-between;
  align-items: center;
  min-height: 100vh;
  color: #ffffff;
  overflow: hidden;
}
@media screen and (-ms-high-contrast: active), screen and (-ms-high-contrast: none) {
  .main-top {
    height: 100vh;
  }
}
@media screen and (min-width: 1024px) {
  .main-top {
    flex-flow: row;
  }
}
.main-top__content {
  margin: 0 auto;
  width: 94vw;
  max-width: calc(100vw - 30px);
  padding-top: 5.4rem;
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  padding: 0;
  margin: 0;
  -webkit-flex-wrap: wrap;
  flex-wrap: wrap;
  justify-content: space-around;
  flex-flow: column;
  justify-content: center;
  align-items: center;
  position: relative;
  height: 100%;
}
@media screen and (min-width: 320px) {
  .main-top__content {
    padding-top: 5.4rem;
  }
}
@media screen and (min-width: 640px) {
  .main-top__content {
    padding-top: 5.4rem;
  }
}
@media screen and (min-width: 768px) {
  .main-top__content {
    padding-top: 5.4rem;
  }
}
@media screen and (min-width: 1024px) {
  .main-top__content {
    padding-top: 6.7rem;
  }
}
@media screen and (min-width: 1280px) {
  .main-top__content {
    padding-top: 6.7rem;
  }
}
@media screen and (min-width: 1440px) {
  .main-top__content {
    padding-top: 6.7rem;
  }
}
@media screen and (min-width: 1600px) {
  .main-top__content {
    padding-top: 6.7rem;
  }
}
@media screen and (min-width: 1024px) {
  .main-top__content {
    margin: 0 auto;
    width: 90%;
    max-width: 1600px;
    flex-flow: row;
  }
}
.main-top__title {
  font-size: 2rem;
}
@media screen and (min-width: 320px) {
  .main-top__title {
    font-size: 2rem;
  }
}
@media screen and (min-width: 640px) {
  .main-top__title {
    font-size: 2.5rem;
  }
}
@media screen and (min-width: 768px) {
  .main-top__title {
    font-size: 3.375rem;
  }
}
@media screen and (min-width: 1024px) {
  .main-top__title {
    font-size: 4.2rem;
  }
}
@media screen and (min-width: 1280px) {
  .main-top__title {
    font-size: 4.2rem;
  }
}
@media screen and (min-width: 1440px) {
  .main-top__title {
    font-size: 4.2rem;
  }
}
@media screen and (min-width: 1920px) {
  .main-top__title {
    font-size: 5.625rem;
  }
}
.main-top__undertitle {
  margin-top: 1.5625rem;
  font-size: 1rem;
}
@media screen and (min-width: 320px) {
  .main-top__undertitle {
    margin-top: 1.5625rem;
  }
}
@media screen and (min-width: 640px) {
  .main-top__undertitle {
    margin-top: 1.5625rem;
  }
}
@media screen and (min-width: 768px) {
  .main-top__undertitle {
    margin-top: 1.875rem;
  }
}
@media screen and (min-width: 1024px) {
  .main-top__undertitle {
    margin-top: 2.5rem;
  }
}
@media screen and (min-width: 320px) {
  .main-top__undertitle {
    font-size: 1rem;
  }
}
@media screen and (min-width: 640px) {
  .main-top__undertitle {
    font-size: 1rem;
  }
}
@media screen and (min-width: 768px) {
  .main-top__undertitle {
    font-size: 1.25rem;
  }
}
@media screen and (min-width: 1024px) {
  .main-top__undertitle {
    font-size: 1.25rem;
  }
}
.main-top__left {
  margin-bottom: 1.25rem;
  margin-bottom: 0;
}
@media screen and (min-width: 320px) {
  .main-top__left {
    margin-bottom: 1.25rem;
  }
}
@media screen and (min-width: 640px) {
  .main-top__left {
    margin-bottom: 1.25rem;
  }
}
@media screen and (min-width: 768px) {
  .main-top__left {
    margin-bottom: 1.25rem;
  }
}
@media screen and (min-width: 1024px) {
  .main-top__left {
    margin-bottom: 1.875rem;
  }
}
@media screen and (min-width: 1024px) {
  .main-top__left {
    margin-right: 2.5rem;
    flex: 1;
  }
}
@media screen and (min-width: 1024px) and (min-width: 320px) {
  .main-top__left {
    margin-right: 2.5rem;
  }
}
@media screen and (min-width: 1024px) and (min-width: 640px) {
  .main-top__left {
    margin-right: 2.5rem;
  }
}
@media screen and (min-width: 1024px) and (min-width: 768px) {
  .main-top__left {
    margin-right: 3.125rem;
  }
}
@media screen and (min-width: 1024px) and (min-width: 1024px) {
  .main-top__left {
    margin-right: 3.75rem;
  }
}
.main-top__right {
  width: 100%;
}
@media screen and (min-width: 1024px) {
  .main-top__right {
    flex: 1;
  }
}

.product {
  padding-top: 5.4rem;
  display: block;
  color: #ffffff;
  background-size: cover;
  background-repeat: no-repeat;
}
@media screen and (min-width: 320px) {
  .product {
    padding-top: 5.4rem;
  }
}
@media screen and (min-width: 640px) {
  .product {
    padding-top: 5.4rem;
  }
}
@media screen and (min-width: 768px) {
  .product {
    padding-top: 5.4rem;
  }
}
@media screen and (min-width: 1024px) {
  .product {
    padding-top: 6.7rem;
  }
}
@media screen and (min-width: 1280px) {
  .product {
    padding-top: 6.7rem;
  }
}
@media screen and (min-width: 1440px) {
  .product {
    padding-top: 6.7rem;
  }
}
@media screen and (min-width: 1600px) {
  .product {
    padding-top: 6.7rem;
  }
}
.product__top {
  margin: 0 auto;
  width: 94vw;
  max-width: calc(100vw - 30px);
  position: relative;
  top: 50%;
  transform: translateY(-50%);
}
@media screen and (min-width: 1024px) {
  .product__top {
    margin: 0 auto;
    width: 90%;
    max-width: 1600px;
  }
}
.product__title {
  font-size: 2rem;
}
@media screen and (min-width: 320px) {
  .product__title {
    font-size: 2rem;
  }
}
@media screen and (min-width: 640px) {
  .product__title {
    font-size: 2.5rem;
  }
}
@media screen and (min-width: 768px) {
  .product__title {
    font-size: 3.375rem;
  }
}
@media screen and (min-width: 1024px) {
  .product__title {
    font-size: 4.2rem;
  }
}
@media screen and (min-width: 1280px) {
  .product__title {
    font-size: 4.2rem;
  }
}
@media screen and (min-width: 1440px) {
  .product__title {
    font-size: 4.2rem;
  }
}
.product__undertitle {
  margin-top: 0.9375rem;
  font-size: 1rem;
  font-weight: 400;
}
@media screen and (min-width: 320px) {
  .product__undertitle {
    margin-top: 0.9375rem;
  }
}
@media screen and (min-width: 640px) {
  .product__undertitle {
    margin-top: 0.9375rem;
  }
}
@media screen and (min-width: 768px) {
  .product__undertitle {
    margin-top: 1.25rem;
  }
}
@media screen and (min-width: 320px) {
  .product__undertitle {
    font-size: 1rem;
  }
}
@media screen and (min-width: 640px) {
  .product__undertitle {
    font-size: 1rem;
  }
}
@media screen and (min-width: 768px) {
  .product__undertitle {
    font-size: 1.25rem;
  }
}
@media screen and (min-width: 1024px) {
  .product__undertitle {
    font-size: 1.25rem;
  }
}
.product iframe {
  width: 100%;
  height: 100%;
}

.section {
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  padding: 0;
  margin: 0;
  -webkit-flex-wrap: wrap;
  flex-wrap: wrap;
  justify-content: space-around;
  flex-flow: column;
  justify-content: center;
  align-items: center;
  padding-top: 2.5rem;
  padding-bottom: 2.5rem;
  height: auto;
}
@media screen and (min-width: 320px) {
  .section {
    padding-top: 2.5rem;
  }
}
@media screen and (min-width: 640px) {
  .section {
    padding-top: 2.5rem;
  }
}
@media screen and (min-width: 768px) {
  .section {
    padding-top: 3.125rem;
  }
}
@media screen and (min-width: 1024px) {
  .section {
    padding-top: 3.75rem;
  }
}
@media screen and (min-width: 320px) {
  .section {
    padding-bottom: 2.5rem;
  }
}
@media screen and (min-width: 640px) {
  .section {
    padding-bottom: 2.5rem;
  }
}
@media screen and (min-width: 768px) {
  .section {
    padding-bottom: 3.125rem;
  }
}
@media screen and (min-width: 1024px) {
  .section {
    padding-bottom: 3.75rem;
  }
}
@media screen and (min-width: 1440px) {
  .section {
    min-height: 100vh;
  }
}
.section--bg-1 {
  color: #ffffff;
  background: linear-gradient(-45deg, #3B94F4, #5FECAB);
}
.section--bg-2 {
  color: #ffffff;
  background: linear-gradient(-45deg, #832DA3, #6429D4);
}
.section--bg-3 {
  color: #ffffff;
  background: linear-gradient(-45deg, #B04363, #D64263);
}
.section--bg-4 {
  color: #ffffff;
  background: linear-gradient(-45deg, #DB8235, #EF4571);
}
.section--no-bg {
  position: relative;
  color: #000000;
  background: none;
}
.section--no-bg:before {
  background: linear-gradient(to bottom, hsl(0, 0%, 0%) 0%, hsla(0, 0%, 0%, 0.738) 19%, hsla(0, 0%, 0%, 0.541) 34%, hsla(0, 0%, 0%, 0.382) 47%, hsla(0, 0%, 0%, 0.278) 56.5%, hsla(0, 0%, 0%, 0.194) 65%, hsla(0, 0%, 0%, 0.126) 73%, hsla(0, 0%, 0%, 0.075) 80.2%, hsla(0, 0%, 0%, 0.042) 86.1%, hsla(0, 0%, 0%, 0.021) 91%, hsla(0, 0%, 0%, 0.008) 95.2%, hsla(0, 0%, 0%, 0.002) 98.2%, hsla(0, 0%, 0%, 0) 100%);
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  display: block;
  width: 100%;
  height: 100%;
  opacity: 0.05;
}
.section--divided {
  position: relative;
}
.section--divided:before {
  background: linear-gradient(to bottom, hsl(0, 0%, 0%) 0%, hsla(0, 0%, 0%, 0.738) 19%, hsla(0, 0%, 0%, 0.541) 34%, hsla(0, 0%, 0%, 0.382) 47%, hsla(0, 0%, 0%, 0.278) 56.5%, hsla(0, 0%, 0%, 0.194) 65%, hsla(0, 0%, 0%, 0.126) 73%, hsla(0, 0%, 0%, 0.075) 80.2%, hsla(0, 0%, 0%, 0.042) 86.1%, hsla(0, 0%, 0%, 0.021) 91%, hsla(0, 0%, 0%, 0.008) 95.2%, hsla(0, 0%, 0%, 0.002) 98.2%, hsla(0, 0%, 0%, 0) 100%);
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  display: block;
  width: 100%;
  height: 100%;
  opacity: 0.05;
}
.section--anim-bg {
  position: relative;
  color: #ffffff;
  overflow: hidden;
}
.section--anim-bg:before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  display: block;
  height: 100%;
  width: 900vw;
  background: linear-gradient(83deg, rgb(95, 236, 171) 0%, rgb(71, 178, 219) 7%, rgb(110, 41, 220) 21%, rgb(129, 45, 170) 31%, rgb(178, 68, 100) 44%, rgb(209, 69, 102) 51%, rgb(178, 68, 100) 59%, rgb(129, 45, 170) 68%, rgb(110, 41, 220) 78%, rgb(71, 178, 219) 91%, rgb(95, 236, 171) 100%);
  animation: falloff 55s linear infinite;
  animation-fill-mode: forwards;
  z-index: -1;
}
.section--double {
  margin-top: 0.9375rem;
  justify-content: flex-start;
  height: auto;
}
@media screen and (min-width: 320px) {
  .section--double {
    margin-top: 0.9375rem;
  }
}
@media screen and (min-width: 640px) {
  .section--double {
    margin-top: 0.9375rem;
  }
}
@media screen and (min-width: 768px) {
  .section--double {
    margin-top: 1.25rem;
  }
}
@media screen and (min-width: 768px) {
  .section--double {
    margin-top: 0;
  }
}
@media screen and (min-width: 768px) and (min-width: 320px) {
  .section--double {
    margin-top: 0;
  }
}
@media screen and (min-width: 768px) and (min-width: 640px) {
  .section--double {
    margin-top: 0;
  }
}
@media screen and (min-width: 768px) and (min-width: 768px) {
  .section--double {
    margin-top: 0;
  }
}
.section--double .segment__main {
  align-items: flex-start;
}
.section .trailing-text {
  margin: 0 auto;
  width: 90%;
  max-width: 1600px;
  max-width: 450px;
  margin: 0 auto;
  text-align: center;
  font-weight: 400;
}

.button {
  display: inline-block;
  text-align: center;
  cursor: pointer;
  text-decoration: none;
  vertical-align: middle;
  border: none;
  outline: none;
  font-size: 1rem;
  padding-top: 0.3125rem;
  padding-bottom: 0.3125rem;
  padding-left: 1.25rem;
  padding-right: 1.25rem;
  line-height: 1;
  position: relative;
  font-family: "DM Sans", sans-serif;
  transition: background 0.3s ease-in-out;
  text-transform: lowercase;
  background: #ffffff;
  overflow: hidden;
}
@media screen and (min-width: 320px) {
  .button {
    font-size: 1rem;
  }
}
@media screen and (min-width: 640px) {
  .button {
    font-size: 1rem;
  }
}
@media screen and (min-width: 768px) {
  .button {
    font-size: 1.25rem;
  }
}
@media screen and (min-width: 1024px) {
  .button {
    font-size: 1.25rem;
  }
}
@media screen and (min-width: 320px) {
  .button {
    padding-top: 0.3125rem;
  }
}
@media screen and (min-width: 640px) {
  .button {
    padding-top: 0.3125rem;
  }
}
@media screen and (min-width: 768px) {
  .button {
    padding-top: 0.625rem;
  }
}
@media screen and (min-width: 320px) {
  .button {
    padding-bottom: 0.3125rem;
  }
}
@media screen and (min-width: 640px) {
  .button {
    padding-bottom: 0.3125rem;
  }
}
@media screen and (min-width: 768px) {
  .button {
    padding-bottom: 0.625rem;
  }
}
@media screen and (min-width: 320px) {
  .button {
    padding-left: 1.25rem;
  }
}
@media screen and (min-width: 640px) {
  .button {
    padding-left: 1.25rem;
  }
}
@media screen and (min-width: 768px) {
  .button {
    padding-left: 1.25rem;
  }
}
@media screen and (min-width: 1024px) {
  .button {
    padding-left: 1.875rem;
  }
}
@media screen and (min-width: 320px) {
  .button {
    padding-right: 1.25rem;
  }
}
@media screen and (min-width: 640px) {
  .button {
    padding-right: 1.25rem;
  }
}
@media screen and (min-width: 768px) {
  .button {
    padding-right: 1.25rem;
  }
}
@media screen and (min-width: 1024px) {
  .button {
    padding-right: 1.875rem;
  }
}
@media screen and (min-width: 320px) {
  .button {
    line-height: 1;
  }
}
@media screen and (min-width: 640px) {
  .button {
    line-height: 1;
  }
}
@media screen and (min-width: 768px) {
  .button {
    line-height: 1;
  }
}
@media screen and (min-width: 1024px) {
  .button {
    line-height: 1;
  }
}
.button--primary {
  border-radius: 5px;
}
.button--secondary {
  border-radius: 5px;
}
.button--red {
  color: #ffffff;
  background: #EF4571;
}
.button--purple {
  color: #ffffff;
  background: #6A28E6;
}
.button--white {
  color: #000000;
  background: #ffffff;
}
.button--w-icon {
  display: -webkit-inline-flex;
  display: -moz-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-flex-wrap: wrap;
  flex-wrap: wrap;
  justify-content: space-around;
  align-items: center;
  justify-content: center;
}
.button--w-icon:hover .icon--arrow {
  transform: translateX(5px);
}
.button--w-icon:hover .icon--paper-plane {
  transform: translate3d(2px, -3px, 0);
}
.button--w-icon span {
  margin-right: 10px;
  font-weight: 500;
}
.button--w-icon .icon--arrow {
  -webkit-transition: transform 0.2s ease-in-out;
  -moz-transition: transform 0.2s ease-in-out;
  -ms-transition: transform 0.2s ease-in-out;
  -o-transition: transform 0.2s ease-in-out;
  transition: transform 0.2s ease-in-out;
}
.button--w-icon .icon--paper-plane {
  -webkit-transition: transform 0.2s ease-in-out;
  -moz-transition: transform 0.2s ease-in-out;
  -ms-transition: transform 0.2s ease-in-out;
  -o-transition: transform 0.2s ease-in-out;
  transition: transform 0.2s ease-in-out;
}
.button--animated-color {
  color: #000000;
  animation: colorblend 55s linear infinite;
  animation-fill-mode: forwards;
}
.button--animated-color path,
.button--animated-color line {
  animation: dashcolor 55s linear infinite;
}
.button--padded {
  padding-top: 0.875rem;
  padding-bottom: 0.875rem;
}
@media screen and (min-width: 320px) {
  .button--padded {
    padding-top: 0.875rem;
  }
}
@media screen and (min-width: 640px) {
  .button--padded {
    padding-top: 1rem;
  }
}
@media screen and (min-width: 768px) {
  .button--padded {
    padding-top: 1.125rem;
  }
}
@media screen and (min-width: 1024px) {
  .button--padded {
    padding-top: 1.125rem;
  }
}
@media screen and (min-width: 320px) {
  .button--padded {
    padding-bottom: 0.875rem;
  }
}
@media screen and (min-width: 640px) {
  .button--padded {
    padding-bottom: 1rem;
  }
}
@media screen and (min-width: 768px) {
  .button--padded {
    padding-bottom: 1.125rem;
  }
}
@media screen and (min-width: 1024px) {
  .button--padded {
    padding-bottom: 1.125rem;
  }
}
.link {
  -webkit-transition: color 0.2s ease-in-out;
  -moz-transition: color 0.2s ease-in-out;
  -ms-transition: color 0.2s ease-in-out;
  -o-transition: color 0.2s ease-in-out;
  transition: color 0.2s ease-in-out;
  color: #ffffff;
}

.icon {
  width: 1em;
  height: auto;
}
.icon--in-rounded-section {
  max-width: 68%;
  width: 100%;
}
.icon--in-small-section {
  width: 80%;
}
@media screen and (min-width: 768px) {
  .icon--in-small-section {
    width: 100%;
  }
}
.icon--in-small-section path,
.icon--in-small-section line,
.icon--in-small-section circle,
.icon--in-small-section polyline {
  stroke: #ffffff !important;
}
.icon--down {
  stroke: #ffffff !important;
}
.icon--arrow path,
.icon--arrow line {
  stroke: #000000;
}
.icon--large {
  max-width: 100%;
}
.icon--paper-plane path:nth-of-type(2) {
  fill: #000000;
}
.icon--envelope {
  width: 2em;
}
.icon--envelope path,
.icon--envelope g,
.icon--envelope rect {
  stroke: #000000;
}
.icon--twitter {
  width: 1.6em;
}
.icon--fill path {
  animation: fillblend 55s linear infinite;
}

.contact {
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  padding: 0;
  margin: 0;
  -webkit-flex-wrap: wrap;
  flex-wrap: wrap;
  justify-content: space-around;
  flex-flow: column;
  align-items: center;
  padding-bottom: 2.5rem;
}
@media screen and (min-width: 320px) {
  .contact {
    padding-bottom: 2.5rem;
  }
}
@media screen and (min-width: 640px) {
  .contact {
    padding-bottom: 2.5rem;
  }
}
@media screen and (min-width: 768px) {
  .contact {
    padding-bottom: 3.125rem;
  }
}
@media screen and (min-width: 1024px) {
  .contact {
    padding-bottom: 3.75rem;
  }
}
.contact__image {
  margin-right: 1.25rem;
  -webkit-box-shadow: -20px 8px 1px 20px rgba(223, 34, 245, 0.44), 20px 8px 1px 9px rgba(223, 34, 245, 0.44);
  -moz-box-shadow: -20px 8px 1px 20px rgba(223, 34, 245, 0.44), 20px 8px 1px 9px rgba(223, 34, 245, 0.44);
  box-shadow: -20px 8px 1px 20px rgba(223, 34, 245, 0.44), 20px 8px 1px 9px rgba(223, 34, 245, 0.44);
  border-radius: 100%;
  line-height: 0;
}
@media screen and (min-width: 320px) {
  .contact__image {
    margin-right: 1.25rem;
  }
}
@media screen and (min-width: 640px) {
  .contact__image {
    margin-right: 1.25rem;
  }
}
@media screen and (min-width: 768px) {
  .contact__image {
    margin-right: 1.25rem;
  }
}
@media screen and (min-width: 1024px) {
  .contact__image {
    margin-right: 1.875rem;
  }
}
.contact__text {
  margin-top: 2.5rem;
  font-size: 1.125rem;
  flex: 0 60%;
}
@media screen and (min-width: 320px) {
  .contact__text {
    margin-top: 2.5rem;
  }
}
@media screen and (min-width: 640px) {
  .contact__text {
    margin-top: 2.5rem;
  }
}
@media screen and (min-width: 768px) {
  .contact__text {
    margin-top: 3.125rem;
  }
}
@media screen and (min-width: 1024px) {
  .contact__text {
    margin-top: 3.75rem;
  }
}
@media screen and (min-width: 320px) {
  .contact__text {
    font-size: 1.125rem;
  }
}
@media screen and (min-width: 640px) {
  .contact__text {
    font-size: 1.25rem;
  }
}
@media screen and (min-width: 768px) {
  .contact__text {
    font-size: 1.375rem;
  }
}
@media screen and (min-width: 1024px) {
  .contact__text {
    font-size: 1.375rem;
  }
}
@media screen and (min-width: 768px) {
  .contact__text {
    margin-top: 0;
  }
}
@media screen and (min-width: 768px) and (min-width: 320px) {
  .contact__text {
    margin-top: 0;
  }
}
@media screen and (min-width: 768px) and (min-width: 640px) {
  .contact__text {
    margin-top: 0;
  }
}
@media screen and (min-width: 768px) and (min-width: 768px) {
  .contact__text {
    margin-top: 0;
  }
}
.contact__form {
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  padding: 0;
  margin: 0;
  -webkit-flex-wrap: wrap;
  flex-wrap: wrap;
  justify-content: space-around;
  flex-flow: column;
  align-items: center;
  width: 100%;
}
.contact__form > * {
  box-sizing: border-box;
}
.contact .button {
  font-weight: 500;
}

.input__container {
  position: relative;
  width: 100%;
  line-height: 0;
}
.input__input {
  padding-right: 0.9375rem;
  padding-left: 0.9375rem;
  font-size: 1rem;
  width: 100%;
  padding-bottom: 6px;
  margin: 8px 0;
  border: solid 2px #ffffff;
  background: transparent;
  color: #ffffff;
}
@media screen and (min-width: 320px) {
  .input__input {
    padding-right: 0.9375rem;
  }
}
@media screen and (min-width: 640px) {
  .input__input {
    padding-right: 0.9375rem;
  }
}
@media screen and (min-width: 768px) {
  .input__input {
    padding-right: 1.25rem;
  }
}
@media screen and (min-width: 320px) {
  .input__input {
    padding-left: 0.9375rem;
  }
}
@media screen and (min-width: 640px) {
  .input__input {
    padding-left: 0.9375rem;
  }
}
@media screen and (min-width: 768px) {
  .input__input {
    padding-left: 1.25rem;
  }
}
@media screen and (min-width: 320px) {
  .input__input {
    font-size: 1rem;
  }
}
@media screen and (min-width: 640px) {
  .input__input {
    font-size: 1rem;
  }
}
@media screen and (min-width: 768px) {
  .input__input {
    font-size: 1.25rem;
  }
}
@media screen and (min-width: 1024px) {
  .input__input {
    font-size: 1.25rem;
  }
}
@-webkit-keyframes autofill {
  0%, 100% {
    color: #ffffff;
    background: transparent;
  }
}
.input__input:-webkit-autofill {
  -webkit-animation-delay: 1s; /* Safari support - any positive time runs instantly */
  -webkit-animation-name: autofill;
  -webkit-animation-fill-mode: both;
}
.input__input:hover, .input__input:focus {
  outline: none;
}
.input__input:focus + .input__label, .input__input:not(:placeholder-shown) + .input__label {
  -webkit-transition: transform 0.2s ease-in-out, font-size 0.2s ease-in-out;
  -moz-transition: transform 0.2s ease-in-out, font-size 0.2s ease-in-out;
  -ms-transition: transform 0.2s ease-in-out, font-size 0.2s ease-in-out;
  -o-transition: transform 0.2s ease-in-out, font-size 0.2s ease-in-out;
  transition: transform 0.2s ease-in-out, font-size 0.2s ease-in-out;
  font-size: 1rem;
  transform: translateY(-18px);
}
@media screen and (min-width: 320px) {
  .input__input:focus + .input__label, .input__input:not(:placeholder-shown) + .input__label {
    font-size: 1rem;
  }
}
@media screen and (min-width: 640px) {
  .input__input:focus + .input__label, .input__input:not(:placeholder-shown) + .input__label {
    font-size: 1rem;
  }
}
@media screen and (min-width: 768px) {
  .input__input:focus + .input__label, .input__input:not(:placeholder-shown) + .input__label {
    font-size: 1rem;
  }
}
@media screen and (min-width: 1024px) {
  .input__input:focus + .input__label, .input__input:not(:placeholder-shown) + .input__label {
    font-size: 1rem;
  }
}
.input__input:not(:-ms-input-placeholder) + .input__label {
  -webkit-transition: transform 0.2s ease-in-out, font-size 0.2s ease-in-out;
  -moz-transition: transform 0.2s ease-in-out, font-size 0.2s ease-in-out;
  -ms-transition: transform 0.2s ease-in-out, font-size 0.2s ease-in-out;
  -o-transition: transform 0.2s ease-in-out, font-size 0.2s ease-in-out;
  transition: transform 0.2s ease-in-out, font-size 0.2s ease-in-out;
  font-size: 1rem;
  transform: translateY(-8px);
}
@media screen and (min-width: 320px) {
  .input__input:not(:-ms-input-placeholder) + .input__label {
    font-size: 1rem;
  }
}
@media screen and (min-width: 640px) {
  .input__input:not(:-ms-input-placeholder) + .input__label {
    font-size: 1rem;
  }
}
@media screen and (min-width: 768px) {
  .input__input:not(:-ms-input-placeholder) + .input__label {
    font-size: 1rem;
  }
}
@media screen and (min-width: 1024px) {
  .input__input:not(:-ms-input-placeholder) + .input__label {
    font-size: 1rem;
  }
}
.input__input::placeholder {
  font-size: 0;
}
.input__container--bordered .input__input {
  padding-top: 1.25rem;
  padding-right: 0.9375rem;
  padding-left: 0.9375rem;
  margin-top: 0;
  margin-bottom: 1.25rem;
  width: 100%;
  height: 70px;
  padding-bottom: 6px;
  border: solid 2px #ffffff;
  border-radius: 8px;
}
@media screen and (min-width: 320px) {
  .input__container--bordered .input__input {
    padding-top: 1.25rem;
  }
}
@media screen and (min-width: 640px) {
  .input__container--bordered .input__input {
    padding-top: 1.25rem;
  }
}
@media screen and (min-width: 768px) {
  .input__container--bordered .input__input {
    padding-top: 1.25rem;
  }
}
@media screen and (min-width: 1024px) {
  .input__container--bordered .input__input {
    padding-top: 1.875rem;
  }
}
@media screen and (min-width: 320px) {
  .input__container--bordered .input__input {
    padding-right: 0.9375rem;
  }
}
@media screen and (min-width: 640px) {
  .input__container--bordered .input__input {
    padding-right: 0.9375rem;
  }
}
@media screen and (min-width: 768px) {
  .input__container--bordered .input__input {
    padding-right: 1.25rem;
  }
}
@media screen and (min-width: 320px) {
  .input__container--bordered .input__input {
    padding-left: 0.9375rem;
  }
}
@media screen and (min-width: 640px) {
  .input__container--bordered .input__input {
    padding-left: 0.9375rem;
  }
}
@media screen and (min-width: 768px) {
  .input__container--bordered .input__input {
    padding-left: 1.25rem;
  }
}
@media screen and (min-width: 320px) {
  .input__container--bordered .input__input {
    margin-top: 0;
  }
}
@media screen and (min-width: 640px) {
  .input__container--bordered .input__input {
    margin-top: 0;
  }
}
@media screen and (min-width: 768px) {
  .input__container--bordered .input__input {
    margin-top: 0;
  }
}
@media screen and (min-width: 320px) {
  .input__container--bordered .input__input {
    margin-bottom: 1.25rem;
  }
}
@media screen and (min-width: 640px) {
  .input__container--bordered .input__input {
    margin-bottom: 1.25rem;
  }
}
@media screen and (min-width: 768px) {
  .input__container--bordered .input__input {
    margin-bottom: 1.25rem;
  }
}
@media screen and (min-width: 1024px) {
  .input__container--bordered .input__input {
    margin-bottom: 1.875rem;
  }
}
.input__input:invalid {
  color: #D0021B;
  border-bottom: 2px solid #D0021B;
}
.input__input:invalid:focus ~ .input__error {
  display: block;
}
.input__label {
  padding: 0.3125rem;
  font-size: 1rem;
  position: absolute;
  bottom: 42px;
  left: 10px;
  padding-bottom: 2px;
  color: #ffffff;
  pointer-events: none;
  font-weight: 400;
}
@media screen and (min-width: 320px) {
  .input__label {
    padding: 0.3125rem;
  }
}
@media screen and (min-width: 640px) {
  .input__label {
    padding: 0.3125rem;
  }
}
@media screen and (min-width: 768px) {
  .input__label {
    padding: 0.625rem;
  }
}
@media screen and (min-width: 320px) {
  .input__label {
    font-size: 1rem;
  }
}
@media screen and (min-width: 640px) {
  .input__label {
    font-size: 1rem;
  }
}
@media screen and (min-width: 768px) {
  .input__label {
    font-size: 1.25rem;
  }
}
@media screen and (min-width: 1024px) {
  .input__label {
    font-size: 1.25rem;
  }
}
.input__container--bordered .input__label {
  left: 13px;
  bottom: 50px;
}
@media screen and (min-width: 768px) {
  .input__container--bordered .input__label {
    bottom: 45px;
  }
}
@media screen and (min-width: 1024px) {
  .input__container--bordered .input__label {
    bottom: 55px;
  }
}
.input__error {
  position: absolute;
  top: 73px;
  left: 0;
  display: none;
  padding: 2px 10px;
  line-height: 1;
  font-size: 16px;
  color: #ffffff;
  border-radius: 8px;
  background: rgba(255, 0, 0, 0.4);
  white-space: normal;
}
@media screen and (max-width: 320px) {
  .input__error {
    top: 69px;
    width: 250px;
    font-size: 12px;
  }
}
@media screen and (min-width: 768px) {
  .input__error {
    padding: 4px 10px;
  }
}

.textarea__container {
  position: relative;
  width: 100%;
  line-height: 0;
}
.textarea__label {
  padding: 0.3125rem;
  position: absolute;
  top: 20px;
  left: 10px;
  padding-bottom: 2px;
  color: #ffffff;
  pointer-events: none;
  font-weight: 400;
}
@media screen and (min-width: 320px) {
  .textarea__label {
    padding: 0.3125rem;
  }
}
@media screen and (min-width: 640px) {
  .textarea__label {
    padding: 0.3125rem;
  }
}
@media screen and (min-width: 768px) {
  .textarea__label {
    padding: 0.625rem;
  }
}
@media screen and (min-width: 768px) {
  .textarea__label {
    top: 24px;
  }
}
.textarea__textarea {
  padding: 0.9375rem;
  margin-bottom: 1.25rem;
  font-size: 1rem;
  width: 100%;
  min-height: 144px;
  border: solid 2px #ffffff;
  resize: none;
  border-radius: 8px;
  background: transparent;
  color: #ffffff;
}
@media screen and (min-width: 320px) {
  .textarea__textarea {
    padding: 0.9375rem;
  }
}
@media screen and (min-width: 640px) {
  .textarea__textarea {
    padding: 0.9375rem;
  }
}
@media screen and (min-width: 768px) {
  .textarea__textarea {
    padding: 1.25rem;
  }
}
@media screen and (min-width: 320px) {
  .textarea__textarea {
    margin-bottom: 1.25rem;
  }
}
@media screen and (min-width: 640px) {
  .textarea__textarea {
    margin-bottom: 1.25rem;
  }
}
@media screen and (min-width: 768px) {
  .textarea__textarea {
    margin-bottom: 1.25rem;
  }
}
@media screen and (min-width: 1024px) {
  .textarea__textarea {
    margin-bottom: 1.875rem;
  }
}
@media screen and (min-width: 320px) {
  .textarea__textarea {
    font-size: 1rem;
  }
}
@media screen and (min-width: 640px) {
  .textarea__textarea {
    font-size: 1rem;
  }
}
@media screen and (min-width: 768px) {
  .textarea__textarea {
    font-size: 1.25rem;
  }
}
@media screen and (min-width: 1024px) {
  .textarea__textarea {
    font-size: 1.25rem;
  }
}
.textarea__textarea:focus + .textarea__label, .textarea__textarea:not(:placeholder-shown) + .textarea__label {
  -webkit-transition: font-size 0.2s ease-in-out;
  -moz-transition: font-size 0.2s ease-in-out;
  -ms-transition: font-size 0.2s ease-in-out;
  -o-transition: font-size 0.2s ease-in-out;
  transition: font-size 0.2s ease-in-out;
  font-size: 0;
}

.custom-select {
  width: 100%;
}
.custom-select__select {
  padding: 0.9375rem;
  margin-bottom: 1.25rem;
  font-size: 1rem;
  width: 100%;
  min-height: 40px;
  color: #ffffff;
  background: transparent;
  border: solid 2px #ffffff;
  border-radius: 8px;
  font-family: "DM Sans", sans-serif;
  white-space: normal;
  background: url("/files/eatcommerce/icons/arrow-down.svg") no-repeat right 10px center;
}
@media screen and (min-width: 320px) {
  .custom-select__select {
    padding: 0.9375rem;
  }
}
@media screen and (min-width: 640px) {
  .custom-select__select {
    padding: 0.9375rem;
  }
}
@media screen and (min-width: 768px) {
  .custom-select__select {
    padding: 1.25rem;
  }
}
@media screen and (min-width: 320px) {
  .custom-select__select {
    margin-bottom: 1.25rem;
  }
}
@media screen and (min-width: 640px) {
  .custom-select__select {
    margin-bottom: 1.25rem;
  }
}
@media screen and (min-width: 768px) {
  .custom-select__select {
    margin-bottom: 1.25rem;
  }
}
@media screen and (min-width: 1024px) {
  .custom-select__select {
    margin-bottom: 1.875rem;
  }
}
@media screen and (min-width: 320px) {
  .custom-select__select {
    font-size: 1rem;
  }
}
@media screen and (min-width: 640px) {
  .custom-select__select {
    font-size: 1rem;
  }
}
@media screen and (min-width: 768px) {
  .custom-select__select {
    font-size: 1.25rem;
  }
}
@media screen and (min-width: 1024px) {
  .custom-select__select {
    font-size: 1.25rem;
  }
}
@media screen and (min-width: 768px) {
  .custom-select__select {
    background-position: right 20px center;
  }
}
.custom-select__option {
  color: #000000;
  background: transparent;
}

.widget-captcha {
  margin-bottom: 1.25rem;
}
@media screen and (min-width: 320px) {
  .widget-captcha {
    margin-bottom: 1.25rem;
  }
}
@media screen and (min-width: 640px) {
  .widget-captcha {
    margin-bottom: 1.25rem;
  }
}
@media screen and (min-width: 768px) {
  .widget-captcha {
    margin-bottom: 1.25rem;
  }
}
@media screen and (min-width: 1024px) {
  .widget-captcha {
    margin-bottom: 1.875rem;
  }
}
.widget-captcha .captcha {
  padding-right: 0.9375rem;
  padding-left: 0.9375rem;
  font-size: 1rem;
  width: 100%;
  padding-bottom: 6px;
  margin: 8px 0;
  border: solid 2px #ffffff;
  background: transparent;
  color: #ffffff;
}
@media screen and (min-width: 320px) {
  .widget-captcha .captcha {
    padding-right: 0.9375rem;
  }
}
@media screen and (min-width: 640px) {
  .widget-captcha .captcha {
    padding-right: 0.9375rem;
  }
}
@media screen and (min-width: 768px) {
  .widget-captcha .captcha {
    padding-right: 1.25rem;
  }
}
@media screen and (min-width: 320px) {
  .widget-captcha .captcha {
    padding-left: 0.9375rem;
  }
}
@media screen and (min-width: 640px) {
  .widget-captcha .captcha {
    padding-left: 0.9375rem;
  }
}
@media screen and (min-width: 768px) {
  .widget-captcha .captcha {
    padding-left: 1.25rem;
  }
}
@media screen and (min-width: 320px) {
  .widget-captcha .captcha {
    font-size: 1rem;
  }
}
@media screen and (min-width: 640px) {
  .widget-captcha .captcha {
    font-size: 1rem;
  }
}
@media screen and (min-width: 768px) {
  .widget-captcha .captcha {
    font-size: 1.25rem;
  }
}
@media screen and (min-width: 1024px) {
  .widget-captcha .captcha {
    font-size: 1.25rem;
  }
}
.widget-captcha .captcha:focus ~ .captcha__label, .widget-captcha .captcha:not(:placeholder-shown) ~ .captcha__label {
  -webkit-transition: transform 0.2s ease-in-out, font-size 0.2s ease-in-out;
  -moz-transition: transform 0.2s ease-in-out, font-size 0.2s ease-in-out;
  -ms-transition: transform 0.2s ease-in-out, font-size 0.2s ease-in-out;
  -o-transition: transform 0.2s ease-in-out, font-size 0.2s ease-in-out;
  transition: transform 0.2s ease-in-out, font-size 0.2s ease-in-out;
  font-size: 1rem;
  transform: translateY(-18px);
}
@media screen and (min-width: 320px) {
  .widget-captcha .captcha:focus ~ .captcha__label, .widget-captcha .captcha:not(:placeholder-shown) ~ .captcha__label {
    font-size: 1rem;
  }
}
@media screen and (min-width: 640px) {
  .widget-captcha .captcha:focus ~ .captcha__label, .widget-captcha .captcha:not(:placeholder-shown) ~ .captcha__label {
    font-size: 1rem;
  }
}
@media screen and (min-width: 768px) {
  .widget-captcha .captcha:focus ~ .captcha__label, .widget-captcha .captcha:not(:placeholder-shown) ~ .captcha__label {
    font-size: 1rem;
  }
}
@media screen and (min-width: 1024px) {
  .widget-captcha .captcha:focus ~ .captcha__label, .widget-captcha .captcha:not(:placeholder-shown) ~ .captcha__label {
    font-size: 1rem;
  }
}
.widget-captcha .captcha_text {
  margin-bottom: 0.3125rem;
  display: block;
  padding: 2px 10px;
  color: #ffffff;
  border-radius: 8px;
  background: rgba(255, 0, 0, 0.4);
}
@media screen and (min-width: 320px) {
  .widget-captcha .captcha_text {
    margin-bottom: 0.3125rem;
  }
}
@media screen and (min-width: 640px) {
  .widget-captcha .captcha_text {
    margin-bottom: 0.3125rem;
  }
}
@media screen and (min-width: 768px) {
  .widget-captcha .captcha_text {
    margin-bottom: 0.625rem;
  }
}
.widget-captcha label {
  padding: 0.3125rem;
  font-size: 1rem;
  position: absolute;
  bottom: 42px;
  left: 10px;
  padding-bottom: 2px;
  color: #ffffff;
  pointer-events: none;
  font-weight: 400;
}
@media screen and (min-width: 320px) {
  .widget-captcha label {
    padding: 0.3125rem;
  }
}
@media screen and (min-width: 640px) {
  .widget-captcha label {
    padding: 0.3125rem;
  }
}
@media screen and (min-width: 768px) {
  .widget-captcha label {
    padding: 0.625rem;
  }
}
@media screen and (min-width: 320px) {
  .widget-captcha label {
    font-size: 1rem;
  }
}
@media screen and (min-width: 640px) {
  .widget-captcha label {
    font-size: 1rem;
  }
}
@media screen and (min-width: 768px) {
  .widget-captcha label {
    font-size: 1.25rem;
  }
}
@media screen and (min-width: 1024px) {
  .widget-captcha label {
    font-size: 1.25rem;
  }
}
@keyframes falloff {
  0% {
    transform: translateX(0%);
  }
  100% {
    transform: translateX(calc(-100% + 100vw));
  }
}
@keyframes falloffblend {
  0% {
    background: linear-gradient(47deg, rgba(95, 236, 171, 0.7) 70%, rgba(95, 236, 171, 0.2) 100%);
  }
  5% {
    background: linear-gradient(47deg, rgba(71, 178, 219, 0.7) 0%, rgba(71, 178, 219, 0.2) 100%);
  }
  20% {
    background: linear-gradient(47deg, rgba(110, 41, 220, 0.7) 0%, rgba(110, 41, 220, 0.2) 100%);
  }
  30% {
    background: linear-gradient(47deg, rgba(129, 45, 170, 0.7) 0%, rgba(129, 45, 170, 0.2) 100%);
  }
  44% {
    background: linear-gradient(47deg, rgba(178, 68, 100, 0.7) 0%, rgba(178, 68, 100, 0.2) 100%);
  }
  51% {
    background: linear-gradient(47deg, rgba(209, 69, 102, 0.7) 0%, rgba(209, 69, 102, 0.2) 100%);
  }
  59% {
    background: linear-gradient(47deg, rgba(209, 69, 102, 0.7) 0%, rgba(209, 69, 102, 0.2) 100%);
  }
  68% {
    background: linear-gradient(47deg, rgba(129, 45, 170, 0.7) 0%, rgba(129, 45, 170, 0.2) 100%);
  }
  78% {
    background: linear-gradient(47deg, rgba(110, 41, 220, 0.7) 0%, rgba(110, 41, 220, 0.2) 100%);
  }
  91% {
    background: linear-gradient(47deg, rgba(71, 178, 219, 0.7) 0%, rgba(71, 178, 219, 0.2) 100%);
  }
  100% {
    background: linear-gradient(47deg, rgba(95, 236, 171, 0.7) 0%, rgba(95, 236, 171, 0.2) 100%);
  }
}
@keyframes change {
  0% {
    transform: translateX(0%) translateY(0%);
  }
  22% {
    transform: translateX(0%) translateY(0%);
  }
  25% {
    transform: translateX(-33%) translateY(-22%);
  }
  47% {
    transform: translateX(-33%) translateY(-22%);
  }
  50% {
    transform: translateX(0%) translateY(0%);
  }
  72% {
    transform: translateX(0%) translateY(0%);
  }
  75% {
    transform: translateX(33%) translateY(22%);
  }
  97% {
    transform: translateX(33%) translateY(22%);
  }
  100% {
    transform: translateX(0%) translateY(0%);
  }
}
@keyframes movearound {
  0% {
    transform: translateY(0%);
  }
  50% {
    transform: translateY(0.3%);
  }
  100% {
    transform: translateY(0%);
  }
}
@keyframes colorblend {
  0% {
    color: rgb(95, 236, 171);
  }
  6% {
    color: rgb(71, 178, 219);
  }
  18% {
    color: rgb(110, 41, 220);
  }
  27% {
    color: rgb(129, 45, 170);
  }
  39% {
    color: rgb(178, 68, 100);
  }
  46% {
    color: rgb(209, 69, 102);
  }
  52% {
    color: rgb(178, 68, 100);
  }
  60% {
    color: rgb(129, 45, 170);
  }
  69% {
    color: rgb(110, 41, 220);
  }
  78% {
    color: rgb(71, 178, 219);
  }
  89% {
    color: rgb(95, 236, 171);
  }
  95% {
    color: rgb(71, 178, 219);
  }
  100% {
    color: rgb(88, 120, 219);
  }
}
@keyframes fillblend {
  0% {
    fill: rgb(95, 236, 171);
  }
  6% {
    fill: rgb(71, 178, 219);
  }
  18% {
    fill: rgb(110, 41, 220);
  }
  27% {
    fill: rgb(129, 45, 170);
  }
  39% {
    fill: rgb(178, 68, 100);
  }
  46% {
    fill: rgb(209, 69, 102);
  }
  52% {
    fill: rgb(178, 68, 100);
  }
  60% {
    fill: rgb(129, 45, 170);
  }
  69% {
    fill: rgb(110, 41, 220);
  }
  78% {
    fill: rgb(71, 178, 219);
  }
  89% {
    fill: rgb(95, 236, 171);
  }
  95% {
    fill: rgb(71, 178, 219);
  }
  100% {
    fill: rgb(88, 120, 219);
  }
}
@keyframes dashcolor {
  0% {
    stroke: rgb(95, 236, 171);
  }
  6% {
    stroke: rgb(71, 178, 219);
  }
  18% {
    stroke: rgb(110, 41, 220);
  }
  27% {
    stroke: rgb(129, 45, 170);
  }
  39% {
    stroke: rgb(178, 68, 100);
  }
  46% {
    stroke: rgb(209, 69, 102);
  }
  52% {
    stroke: rgb(178, 68, 100);
  }
  60% {
    stroke: rgb(129, 45, 170);
  }
  69% {
    stroke: rgb(110, 41, 220);
  }
  78% {
    stroke: rgb(71, 178, 219);
  }
  89% {
    stroke: rgb(95, 236, 171);
  }
  95% {
    stroke: rgb(71, 178, 219);
  }
  100% {
    stroke: rgb(88, 120, 219);
  }
}
.slideshow {
  position: relative;
  height: 100%;
  overflow: hidden;
}
.section__left-image .slideshow, .section__right-image .slideshow {
  overflow: visible;
}
.slideshow__slides {
  position: relative;
  display: block;
  width: 100%;
  height: 100%;
  padding: 0;
  margin: 0;
  list-style: none;
  z-index: 10;
}
.section__left-image .slideshow__slides, .section__right-image .slideshow__slides {
  width: 300px;
  height: 300px;
}
@media screen and (min-width: 768px) {
  .section__left-image .slideshow__slides, .section__right-image .slideshow__slides {
    width: 450px;
    height: 400px;
  }
}
@media screen and (min-width: 1600px) {
  .section__left-image .slideshow__slides, .section__right-image .slideshow__slides {
    width: 650px;
    height: 650px;
  }
}
.slideshow__slide {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  transition: opacity 0s 0.8s;
  will-change: clip-path;
  cursor: grab;
  opacity: 0;
  z-index: 1;
}
.section__right .slideshow__slide img, .section__left .slideshow__slide img {
  object-fit: contain;
}
.slideshow__slide:active {
  cursor: grabbing;
}
@supports (clip-path: polygon(0 0, 0 0, 0 0, 0 0)) {
  .slideshow__slide {
    clip-path: polygon(0 0, 100% 0, 100% 100%, 0 100%);
    transform: none;
  }
}
.slideshow__slide--last {
  z-index: 5;
}
.slideshow__slide--active {
  opacity: 1;
  transition: opacity 0.8s cubic-bezier(0.25, 0.46, 0.45, 0.94);
  z-index: 10;
}
@supports (clip-path: polygon(0 0, 0 0, 0 0, 0 0)) {
  .slideshow__slide--active.slideshow__slide--next {
    animation: slideshow-next 0.8s cubic-bezier(0.25, 0.46, 0.45, 0.94) forwards;
    clip-path: polygon(100% 0, 100% 0, 100% 100%, 100% 100%);
    transition: all 0s;
  }
  .slideshow__slide--active.slideshow__slide--prev {
    animation: slideshow-prev 0.8s cubic-bezier(0.25, 0.46, 0.45, 0.94) forwards;
    clip-path: polygon(0 0, 0 0, 0 100%, 0 100%);
    transition: all 0s;
  }
}
@keyframes slideshow-prev {
  0% {
    clip-path: polygon(0 0, 0 0, 0 100%, 0 100%);
    transform: translate3d(-5%, 0, 0);
  }
  100% {
    clip-path: polygon(0 0, 100% 0, 100% 100%, 0 100%);
    transform: none;
  }
}
@keyframes slideshow-next {
  0% {
    clip-path: polygon(100% 0, 100% 0, 100% 100%, 100% 100%);
    transform: translate3d(5%, 0, 0);
  }
  100% {
    clip-path: polygon(0 0, 100% 0, 100% 100%, 0 100%);
    transform: none;
  }
}
.slideshow__slide img {
  pointer-events: none;
}
.main-top__right .slideshow__slide img {
  height: 100%;
  object-fit: cover;
}
.slideshow__bullets {
  font-size: 0.375rem;
  position: absolute;
  right: 0;
  bottom: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-content: center;
  align-items: center;
  padding: 1.5em;
  pointer-events: none;
  z-index: 20;
}
@media screen and (min-width: 320px) {
  .slideshow__bullets {
    font-size: 0.375rem;
  }
}
@media screen and (min-width: 640px) {
  .slideshow__bullets {
    font-size: 0.375rem;
  }
}
@media screen and (min-width: 768px) {
  .slideshow__bullets {
    font-size: 0.375rem;
  }
}
@media screen and (min-width: 1024px) {
  .slideshow__bullets {
    font-size: 0.5rem;
  }
}
@media screen and (min-width: 1280px) {
  .slideshow__bullets {
    font-size: 0.5rem;
  }
}
@media screen and (min-width: 1440px) {
  .slideshow__bullets {
    font-size: 0.575rem;
  }
}
.slideshow__bullets:before {
  background: linear-gradient(to top, hsl(0, 0%, 0%) 0%, hsla(0, 0%, 0%, 0.738) 19%, hsla(0, 0%, 0%, 0.541) 34%, hsla(0, 0%, 0%, 0.382) 47%, hsla(0, 0%, 0%, 0.278) 56.5%, hsla(0, 0%, 0%, 0.194) 65%, hsla(0, 0%, 0%, 0.126) 73%, hsla(0, 0%, 0%, 0.075) 80.2%, hsla(0, 0%, 0%, 0.042) 86.1%, hsla(0, 0%, 0%, 0.021) 91%, hsla(0, 0%, 0%, 0.008) 95.2%, hsla(0, 0%, 0%, 0.002) 98.2%, hsla(0, 0%, 0%, 0) 100%);
  content: "";
  position: absolute;
  right: 0;
  bottom: 0;
  left: 0;
  opacity: 0.75;
  pointer-events: none;
  height: 400%;
  transform-origin: center bottom;
  z-index: -1;
}
.slideshow__bullet {
  width: 1em;
  height: 1em;
  padding: 0;
  margin: 0.75em;
  appearance: none;
  background: #ffffff;
  border: 0;
  border-radius: 50%;
  color: transparent;
  cursor: pointer;
  font-size: 1em;
  opacity: 0.8;
  pointer-events: all;
  transition: 0.3s cubic-bezier(0.3, 0, 0, 1.3);
}
.slideshow__bullet:hover {
  box-shadow: 0 0 0 0.375em rgba(255, 255, 255, 0.3);
  opacity: 1;
}
.slideshow__bullet--active {
  opacity: 1;
  transform: scale(1.4);
}
.slideshow__bullet--active:hover {
  box-shadow: none;
}
.slideshow__buttons {
  font-size: 0.375rem;
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  justify-content: space-between;
  align-content: stretch;
  align-items: stretch;
  pointer-events: none;
  width: 100%;
  height: 100%;
  z-index: 15;
}
@media screen and (min-width: 320px) {
  .slideshow__buttons {
    font-size: 0.375rem;
  }
}
@media screen and (min-width: 640px) {
  .slideshow__buttons {
    font-size: 0.375rem;
  }
}
@media screen and (min-width: 768px) {
  .slideshow__buttons {
    font-size: 0.375rem;
  }
}
@media screen and (min-width: 1024px) {
  .slideshow__buttons {
    font-size: 0.5rem;
  }
}
@media screen and (min-width: 1280px) {
  .slideshow__buttons {
    font-size: 0.5rem;
  }
}
@media screen and (min-width: 1440px) {
  .slideshow__buttons {
    font-size: 0.575rem;
  }
}
.slideshow__button {
  position: relative;
  width: 5em;
  padding: 0;
  margin: 0;
  appearance: none;
  background: transparent;
  border: 0;
  border-radius: 0;
  box-shadow: none;
  color: transparent;
  cursor: pointer;
  font-size: 1em;
  pointer-events: all;
}
.slideshow__button:hover:before {
  opacity: 0.5;
}
.slideshow__button:before {
  content: "";
  position: absolute;
  top: 0;
  bottom: 0;
  width: 300%;
  transform-origin: center bottom;
  transition: 0.3s cubic-bezier(0.25, 0.46, 0.45, 0.94);
  opacity: 0;
  z-index: -1;
}
.slideshow__button:after {
  content: "";
  margin-top: -1em;
  position: absolute;
  top: 50%;
  width: 2em;
  height: 2em;
  transition: transform 0.3s cubic-bezier(0.25, 0.46, 0.45, 0.94);
  border: 0 solid #ffffff;
  border-width: 0 0.125em 0.125em 0;
}
.slideshow__button--prev {
  cursor: w-resize;
}
.slideshow__button--prev:hover:after {
  transform: translate3d(-0.5em, 0, 0) rotate(135deg);
}
.slideshow__button--prev:before {
  background: linear-gradient(to right, hsl(0, 0%, 0%) 0%, hsla(0, 0%, 0%, 0.738) 19%, hsla(0, 0%, 0%, 0.541) 34%, hsla(0, 0%, 0%, 0.382) 47%, hsla(0, 0%, 0%, 0.278) 56.5%, hsla(0, 0%, 0%, 0.194) 65%, hsla(0, 0%, 0%, 0.126) 73%, hsla(0, 0%, 0%, 0.075) 80.2%, hsla(0, 0%, 0%, 0.042) 86.1%, hsla(0, 0%, 0%, 0.021) 91%, hsla(0, 0%, 0%, 0.008) 95.2%, hsla(0, 0%, 0%, 0.002) 98.2%, hsla(0, 0%, 0%, 0) 100%);
  left: 0;
}
.slideshow__button--prev:after {
  left: 2em;
  transform: rotate(135deg);
}
.slideshow__button--next {
  cursor: e-resize;
}
.slideshow__button--next:hover:after {
  transform: translate3d(0.5em, 0, 0) rotate(-45deg);
}
.slideshow__button--next:before {
  background: linear-gradient(to left, hsl(0, 0%, 0%) 0%, hsla(0, 0%, 0%, 0.738) 19%, hsla(0, 0%, 0%, 0.541) 34%, hsla(0, 0%, 0%, 0.382) 47%, hsla(0, 0%, 0%, 0.278) 56.5%, hsla(0, 0%, 0%, 0.194) 65%, hsla(0, 0%, 0%, 0.126) 73%, hsla(0, 0%, 0%, 0.075) 80.2%, hsla(0, 0%, 0%, 0.042) 86.1%, hsla(0, 0%, 0%, 0.021) 91%, hsla(0, 0%, 0%, 0.008) 95.2%, hsla(0, 0%, 0%, 0.002) 98.2%, hsla(0, 0%, 0%, 0) 100%);
  right: 0;
}
.slideshow__button--next:after {
  right: 2em;
  transform: rotate(-45deg);
}

.media-container {
  position: relative;
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  overflow: hidden;
}
.media-container, .media-container--square, .media-container--ratio-1-1, .media-container--xs-square, .media-container--xs-ratio-1-1 {
  padding-bottom: 100%;
}
.media-container--portrait, .media-container--ratio-3-4, .media-container--xs-portrait, .media-container--xs-ratio-3-4 {
  padding-bottom: 133.3333333333%;
}
.media-container--ratio-4-3, .media-container--xs-ratio-4-3 {
  padding-bottom: 75%;
}
.media-container--landscape, .media-container--ratio-16-9, .media-container--xs-landscape, .media-container--xs-ratio-16-9 {
  padding-bottom: 56.25%;
}
.media-container--ratio-16-10, .media-container--xs-ratio-16-10 {
  padding-bottom: 62.5%;
}
.media-container--round, .media-container--xs-round {
  border-radius: 50%;
}
.media-container--rectangle, .media-container--xs-rectangle {
  border-radius: 0;
}
.media-container__placeholder {
  background: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' viewBox='0 0 {{w}} {{h}}'><defs><symbol id='a' viewBox='0 0 90 66' opacity='0.3'><path d='M85 5v56H5V5h80m5-5H0v66h90V0z'/><circle cx='18' cy='20' r='6'/><path d='M56 14L37 39l-8-6-17 23h67z'/></symbol></defs><use xlink:href='#a' width='30%' x='35%'/></svg>") no-repeat;
}
@media screen and (min-width: 320px) {
  .media-container---sm-square, .media-container---sm-ratio-1-1 {
    padding-bottom: 100%;
  }
  .media-container---sm-portrait, .media-container---sm-ratio-3-4 {
    padding-bottom: 133.3333333333%;
  }
  .media-container---sm-ratio-4-3 {
    padding-bottom: 75%;
  }
  .media-container---sm-landscape, .media-container---sm-ratio-16-9 {
    padding-bottom: 56.25%;
  }
  .media-container---sm-ratio-16-10 {
    padding-bottom: 62.5%;
  }
  .media-container---sm-round {
    border-radius: 50%;
  }
  .media-container---sm-rectangle {
    border-radius: 0;
  }
}
@media screen and (min-width: 768px) {
  .media-container---md-square, .media-container---md-ratio-1-1 {
    padding-bottom: 100%;
  }
  .media-container---md-portrait, .media-container---md-ratio-3-4 {
    padding-bottom: 133.3333333333%;
  }
  .media-container---md-ratio-4-3 {
    padding-bottom: 75%;
  }
  .media-container---md-landscape, .media-container---md-ratio-16-9 {
    padding-bottom: 56.25%;
  }
  .media-container---md-ratio-16-10 {
    padding-bottom: 62.5%;
  }
  .media-container---md-round {
    border-radius: 50%;
  }
  .media-container---md-rectangle {
    border-radius: 0;
  }
}
@media screen and (min-width: 1024px) {
  .media-container---lg-square, .media-container---lg-ratio-1-1 {
    padding-bottom: 100%;
  }
  .media-container---lg-portrait, .media-container---lg-ratio-3-4 {
    padding-bottom: 133.3333333333%;
  }
  .media-container---lg-ratio-4-3 {
    padding-bottom: 75%;
  }
  .media-container---lg-landscape, .media-container---lg-ratio-16-9 {
    padding-bottom: 56.25%;
  }
  .media-container---lg-ratio-16-10 {
    padding-bottom: 62.5%;
  }
  .media-container---lg-round {
    border-radius: 50%;
  }
  .media-container---lg-rectangle {
    border-radius: 0;
  }
}
@media screen and (min-width: 1280px) {
  .media-container---xl-square, .media-container---xl-ratio-1-1 {
    padding-bottom: 100%;
  }
  .media-container---xl-portrait, .media-container---xl-ratio-3-4 {
    padding-bottom: 133.3333333333%;
  }
  .media-container---xl-ratio-4-3 {
    padding-bottom: 75%;
  }
  .media-container---xl-landscape, .media-container---xl-ratio-16-9 {
    padding-bottom: 56.25%;
  }
  .media-container---xl-ratio-16-10 {
    padding-bottom: 62.5%;
  }
  .media-container---xl-round {
    border-radius: 50%;
  }
  .media-container---xl-rectangle {
    border-radius: 0;
  }
}
@media screen and (min-width: 1440px) {
  .media-container---xxl-square, .media-container---xxl-ratio-1-1 {
    padding-bottom: 100%;
  }
  .media-container---xxl-portrait, .media-container---xxl-ratio-3-4 {
    padding-bottom: 133.3333333333%;
  }
  .media-container---xxl-ratio-4-3 {
    padding-bottom: 75%;
  }
  .media-container---xxl-landscape, .media-container---xxl-ratio-16-9 {
    padding-bottom: 56.25%;
  }
  .media-container---xxl-ratio-16-10 {
    padding-bottom: 62.5%;
  }
  .media-container---xxl-round {
    border-radius: 50%;
  }
  .media-container---xxl-rectangle {
    border-radius: 0;
  }
}
.media-container img,
.media-container video {
  object-fit: cover;
  object-position: center;
  pointer-events: none;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  /**
   * TODO use this workaround if no object-fit polyfill is used
   */
  /* .no-objectfit & {
  	top: 50%;
  	left: 50%;
  	width: auto;
  	height: auto;
  	min-width: 100%;
  	min-height: 100%;
  	max-width: none;
  	max-height: none;
  	transform: translate(-50%, -50%);
  } */
}
.media-container img::-webkit-media-controls,
.media-container video::-webkit-media-controls {
  display: none;
}
.media-container > iframe,
.media-container > div {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.scroll-to-top {
  position: fixed;
  right: 1rem;
  bottom: 1rem;
  display: flex;
  justify-content: center;
  cursor: pointer;
  align-content: center;
  align-items: center;
  width: 3rem;
  height: 3rem;
  padding: 0.5rem;
  transform: translate3d(200%, 0, 0);
  transition: 0.2s cubic-bezier(0.25, 0.46, 0.45, 0.94);
  font-size: 2rem;
  text-align: center;
  line-height: 1;
  opacity: 0;
  border-radius: 5px;
  background: #ffffff;
  box-shadow: 0 0 0.5rem rgba(0, 0, 0, 0.2);
  overflow: hidden;
  z-index: 999999;
}
.page--scrolled .scroll-to-top {
  opacity: 1;
  transform: none;
}
@media (hover: hover) {
  .scroll-to-top:hover svg {
    animation: scroll-to-top-icon 0.3s linear;
  }
}
.is-ie .scroll-to-top:hover svg {
  animation: scroll-to-top-icon 0.3s linear;
}
.scroll-to-top__arrow {
  display: flex;
  height: 24px;
  transform: rotate(-90deg);
}
.scroll-to-top__arrow svg {
  width: 0.8em;
}
.scroll-to-top__arrow path,
.scroll-to-top__arrow line {
  stroke: #000000;
}

@keyframes scroll-to-top-icon {
  0% {
    transform: none;
  }
  50% {
    transform: translate3d(200%, 0, 0);
  }
  51% {
    transform: translate3d(-200%, 0, 0);
  }
  100% {
    transform: none;
  }
}
.animated-svg text {
  font-family: "DM Sans", sans-serif !important;
}
.animated-svg #shopmanager {
  transform: translateY(-10%);
  transition: 0.5s ease 0.75s;
  opacity: 0;
}
.insicht--sichtbar .animated-svg #shopmanager {
  opacity: 1;
  transform: translateY(0%);
}
.animated-svg #cash {
  transform: translateY(-10%);
  transition: 0.5s ease 1.1s;
  opacity: 0;
}
.insicht--sichtbar .animated-svg #cash {
  transform: translateY(0%);
  opacity: 1;
}
.animated-svg #system-two {
  transform: translateY(-10%);
  opacity: 0;
  transition: 0.5s ease 1.5s;
}
.insicht--sichtbar .animated-svg #system-two {
  opacity: 1;
  transform: translateY(0%);
}
.animated-svg #system-one {
  transform: translateY(-10%);
  opacity: 0;
  transition: 0.5s ease 1.9s;
}
.insicht--sichtbar .animated-svg #system-one {
  opacity: 1;
  transform: translateY(0%);
}
.animated-svg #selfordering {
  transform: translateY(-10%);
  opacity: 0;
  transition: 0.5s ease 0.75s;
}
.insicht--sichtbar .animated-svg #selfordering {
  opacity: 1;
  transform: translateY(0%);
}
.animated-svg #website {
  transform: translateY(-10%);
  opacity: 0;
  transition: 0.5s ease 1.1s;
}
.insicht--sichtbar .animated-svg #website {
  opacity: 1;
  transform: translateY(0%);
}
.animated-svg #smartphone {
  transform: translateY(-10%);
  opacity: 0;
  transition: 0.5s ease 1.5s;
}
.insicht--sichtbar .animated-svg #smartphone {
  opacity: 1;
  transform: translateY(0%);
}
.animated-svg .change-container {
  animation: change 12s linear infinite;
}

#connections .gradient-stroke {
  stroke-dasharray: 1000;
  stroke-dashoffset: -500;
  transition: all 1s ease 2.5s, opacity 0.5s ease 3.75s;
}
.insicht--sichtbar #connections .gradient-stroke {
  stroke-dashoffset: 0;
}

#Gradient stop {
  stop-opacity: 1;
}

.gradient-element {
  opacity: 0;
  transition: opacity 0.5s ease 3.75s;
  fill: rgba(71, 178, 219, 0.3) !important;
}
.gradient-element--instant {
  transition: none;
  opacity: 1;
}
.insicht--sichtbar .gradient-element {
  opacity: 1;
}
.gradient-element--violet {
  fill: rgba(129, 45, 170, 0.3) !important;
}
.gradient-element--green {
  fill: rgba(95, 236, 171, 0.3) !important;
}
.gradient-element--red {
  fill: rgba(209, 69, 102, 0.3) !important;
}

.initial-element {
  transition: opacity 0.5s ease 3.75s;
  will-change: transform, opacity;
  opacity: 0.3;
}
.insicht--sichtbar .initial-element {
  opacity: 0;
}

.screencasts {
  position: relative;
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  padding-bottom: 100%;
}
.screencasts__slide {
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  flex-flow: column;
  justify-content: center;
  align-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  opacity: 0;
  transition: 1.5s cubic-bezier(0.25, 0.46, 0.45, 0.94);
  visibility: hidden;
}
.screencasts__slide--active {
  opacity: 1;
  visibility: visible;
}
.screencasts__slide-text {
  margin-top: 0.9375rem;
  font-size: 1rem;
  font-weight: normal;
  text-align: center;
  width: 100%;
}
@media screen and (min-width: 320px) {
  .screencasts__slide-text {
    margin-top: 0.9375rem;
  }
}
@media screen and (min-width: 640px) {
  .screencasts__slide-text {
    margin-top: 0.9375rem;
  }
}
@media screen and (min-width: 768px) {
  .screencasts__slide-text {
    margin-top: 1.25rem;
  }
}
@media screen and (min-width: 320px) {
  .screencasts__slide-text {
    font-size: 1rem;
  }
}
@media screen and (min-width: 640px) {
  .screencasts__slide-text {
    font-size: 1rem;
  }
}
@media screen and (min-width: 768px) {
  .screencasts__slide-text {
    font-size: 1.25rem;
  }
}
@media screen and (min-width: 1024px) {
  .screencasts__slide-text {
    font-size: 1.25rem;
  }
}
.screencasts__device {
  position: relative;
  filter: drop-shadow(0 0 1rem rgba(0, 0, 0, 0.5));
}
.screencasts__device svg {
  display: block;
  width: 100%;
  height: auto;
}
.screencasts__device--desktop {
  width: 100%;
}
.screencasts__device--kiosk {
  width: 40%;
}
.screencasts__device--mobile {
  width: 30%;
}
.screencasts__device-screen {
  position: relative;
  padding-bottom: 177.7777777778%;
  overflow: hidden;
}
.screencasts__device--desktop .screencasts__device-screen {
  padding-bottom: 55%;
  margin-top: -0.0625rem;
}
.screencasts__device--kiosk .screencasts__device-screen {
  padding-bottom: 177.7777777778%;
  margin-bottom: -0.0625rem;
}
.screencasts__device--mobile .screencasts__device-screen {
  padding-bottom: 177.7777777778%;
  margin-top: -0.0625rem;
}
.screencasts__device-screen-image, .screencasts__device-screen-video {
  object-fit: cover;
  object-position: center;
  pointer-events: none;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  /**
   * TODO use this workaround if no object-fit polyfill is used
   */
  /* .no-objectfit & {
  	top: 50%;
  	left: 50%;
  	width: auto;
  	height: auto;
  	min-width: 100%;
  	min-height: 100%;
  	max-width: none;
  	max-height: none;
  	transform: translate(-50%, -50%);
  } */
}
.screencasts__device-screen-image::-webkit-media-controls, .screencasts__device-screen-video::-webkit-media-controls {
  display: none;
}
.screencasts__device-screen-video--selforder {
  transform: scale(1.07);
}

.data-main {
  margin: 0 auto;
  width: 90%;
  max-width: 1600px;
  padding-top: 3.75rem;
  padding-bottom: 3.75rem;
}
@media screen and (min-width: 320px) {
  .data-main {
    padding-top: 3.75rem;
  }
}
@media screen and (min-width: 640px) {
  .data-main {
    padding-top: 3.75rem;
  }
}
@media screen and (min-width: 768px) {
  .data-main {
    padding-top: 5.3125rem;
  }
}
@media screen and (min-width: 1024px) {
  .data-main {
    padding-top: 6.25rem;
  }
}
@media screen and (min-width: 1280px) {
  .data-main {
    padding-top: 6.25rem;
  }
}
@media screen and (min-width: 320px) {
  .data-main {
    padding-bottom: 3.75rem;
  }
}
@media screen and (min-width: 640px) {
  .data-main {
    padding-bottom: 3.75rem;
  }
}
@media screen and (min-width: 768px) {
  .data-main {
    padding-bottom: 5.3125rem;
  }
}
@media screen and (min-width: 1024px) {
  .data-main {
    padding-bottom: 6.25rem;
  }
}
@media screen and (min-width: 1280px) {
  .data-main {
    padding-bottom: 6.25rem;
  }
}
.data-main h1,
.data-main h2,
.data-main h3 {
  text-align: center;
  word-break: break-all;
}
@media screen and (min-width: 640px) {
  .data-main h1,
  .data-main h2,
  .data-main h3 {
    word-break: normal;
  }
}
.data-main h2,
.data-main h4 {
  padding-top: 1.25rem;
  padding-bottom: 1.25rem;
}
@media screen and (min-width: 320px) {
  .data-main h2,
  .data-main h4 {
    padding-top: 1.25rem;
  }
}
@media screen and (min-width: 640px) {
  .data-main h2,
  .data-main h4 {
    padding-top: 1.25rem;
  }
}
@media screen and (min-width: 768px) {
  .data-main h2,
  .data-main h4 {
    padding-top: 1.25rem;
  }
}
@media screen and (min-width: 1024px) {
  .data-main h2,
  .data-main h4 {
    padding-top: 1.875rem;
  }
}
@media screen and (min-width: 320px) {
  .data-main h2,
  .data-main h4 {
    padding-bottom: 1.25rem;
  }
}
@media screen and (min-width: 640px) {
  .data-main h2,
  .data-main h4 {
    padding-bottom: 1.25rem;
  }
}
@media screen and (min-width: 768px) {
  .data-main h2,
  .data-main h4 {
    padding-bottom: 1.25rem;
  }
}
@media screen and (min-width: 1024px) {
  .data-main h2,
  .data-main h4 {
    padding-bottom: 1.875rem;
  }
}
.data-main h3 {
  padding-top: 2.5rem;
  padding-bottom: 1.5625rem;
}
@media screen and (min-width: 320px) {
  .data-main h3 {
    padding-top: 2.5rem;
  }
}
@media screen and (min-width: 640px) {
  .data-main h3 {
    padding-top: 2.5rem;
  }
}
@media screen and (min-width: 768px) {
  .data-main h3 {
    padding-top: 3.125rem;
  }
}
@media screen and (min-width: 1024px) {
  .data-main h3 {
    padding-top: 3.75rem;
  }
}
@media screen and (min-width: 320px) {
  .data-main h3 {
    padding-bottom: 1.5625rem;
  }
}
@media screen and (min-width: 640px) {
  .data-main h3 {
    padding-bottom: 1.5625rem;
  }
}
@media screen and (min-width: 768px) {
  .data-main h3 {
    padding-bottom: 1.875rem;
  }
}
@media screen and (min-width: 1024px) {
  .data-main h3 {
    padding-bottom: 2.5rem;
  }
}
.data-main p {
  font-weight: 400;
  line-height: 1.4;
}
.data-main a {
  word-break: break-all;
  text-decoration: underline;
}
@media screen and (min-width: 1024px) {
  .data-main a {
    word-break: normal;
  }
}
@media screen and (min-width: 1024px) {
  .data-main__content {
    margin: 0 auto;
    width: 90%;
    max-width: 1100px;
  }
}
.data-main__content--impressum {
  text-align: center;
}

.segment__single-col {
  margin-bottom: 3.75rem;
}
@media screen and (min-width: 320px) {
  .segment__single-col {
    margin-bottom: 3.75rem;
  }
}
@media screen and (min-width: 640px) {
  .segment__single-col {
    margin-bottom: 3.75rem;
  }
}
@media screen and (min-width: 768px) {
  .segment__single-col {
    margin-bottom: 5.3125rem;
  }
}
@media screen and (min-width: 1024px) {
  .segment__single-col {
    margin-bottom: 6.25rem;
  }
}
@media screen and (min-width: 1280px) {
  .segment__single-col {
    margin-bottom: 6.25rem;
  }
}
@media screen and (min-width: 1280px) {
  .segment__single-col {
    margin-bottom: 0;
  }
}
@media screen and (min-width: 1280px) and (min-width: 320px) {
  .segment__single-col {
    margin-bottom: 0;
  }
}
@media screen and (min-width: 1280px) and (min-width: 640px) {
  .segment__single-col {
    margin-bottom: 0;
  }
}
@media screen and (min-width: 1280px) and (min-width: 768px) {
  .segment__single-col {
    margin-bottom: 0;
  }
}
@media screen and (min-width: 1024px) {
  .segment__single-col:nth-of-type(2) {
    margin-left: 1.5625rem;
    margin-right: 1.5625rem;
  }
}
@media screen and (min-width: 1024px) and (min-width: 320px) {
  .segment__single-col:nth-of-type(2) {
    margin-left: 1.5625rem;
  }
}
@media screen and (min-width: 1024px) and (min-width: 640px) {
  .segment__single-col:nth-of-type(2) {
    margin-left: 1.5625rem;
  }
}
@media screen and (min-width: 1024px) and (min-width: 768px) {
  .segment__single-col:nth-of-type(2) {
    margin-left: 1.875rem;
  }
}
@media screen and (min-width: 1024px) and (min-width: 1024px) {
  .segment__single-col:nth-of-type(2) {
    margin-left: 2.5rem;
  }
}
@media screen and (min-width: 1024px) and (min-width: 320px) {
  .segment__single-col:nth-of-type(2) {
    margin-right: 1.5625rem;
  }
}
@media screen and (min-width: 1024px) and (min-width: 640px) {
  .segment__single-col:nth-of-type(2) {
    margin-right: 1.5625rem;
  }
}
@media screen and (min-width: 1024px) and (min-width: 768px) {
  .segment__single-col:nth-of-type(2) {
    margin-right: 1.875rem;
  }
}
@media screen and (min-width: 1024px) and (min-width: 1024px) {
  .segment__single-col:nth-of-type(2) {
    margin-right: 2.5rem;
  }
}
.segment__single-col button {
  margin-bottom: 1.5625rem;
}
@media screen and (min-width: 320px) {
  .segment__single-col button {
    margin-bottom: 1.5625rem;
  }
}
@media screen and (min-width: 640px) {
  .segment__single-col button {
    margin-bottom: 1.5625rem;
  }
}
@media screen and (min-width: 768px) {
  .segment__single-col button {
    margin-bottom: 1.875rem;
  }
}
@media screen and (min-width: 1024px) {
  .segment__single-col button {
    margin-bottom: 2.5rem;
  }
}
@media screen and (min-width: 768px) {
  .segment__single-col button {
    margin-bottom: 0;
  }
}
.segment__single-col--w-margin .segment__undertitle,
.segment__single-col--w-margin .segment__image {
  margin-bottom: 1.5625rem;
}
@media screen and (min-width: 320px) {
  .segment__single-col--w-margin .segment__undertitle,
  .segment__single-col--w-margin .segment__image {
    margin-bottom: 1.5625rem;
  }
}
@media screen and (min-width: 640px) {
  .segment__single-col--w-margin .segment__undertitle,
  .segment__single-col--w-margin .segment__image {
    margin-bottom: 1.5625rem;
  }
}
@media screen and (min-width: 768px) {
  .segment__single-col--w-margin .segment__undertitle,
  .segment__single-col--w-margin .segment__image {
    margin-bottom: 1.875rem;
  }
}
@media screen and (min-width: 1024px) {
  .segment__single-col--w-margin .segment__undertitle,
  .segment__single-col--w-margin .segment__image {
    margin-bottom: 2.5rem;
  }
}
.segment__single-col--w-margin .segment__undertitle:last-of-type,
.segment__single-col--w-margin .segment__image:last-of-type {
  margin-bottom: 0;
}
.segment__single-col .linked {
  color: #ffffff;
  font-weight: 500;
}
.segment__container {
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  padding: 0;
  margin: 0;
  -webkit-flex-wrap: wrap;
  flex-wrap: wrap;
  justify-content: space-around;
  flex-flow: column;
  justify-content: center;
  align-items: center;
  position: relative;
}
.segment__title {
  margin: 0 auto;
  width: 90%;
  max-width: 1600px;
  padding-top: 3.75rem;
  padding-bottom: 3.75rem;
  font-size: 2rem;
  position: relative;
  text-align: center;
}
@media screen and (min-width: 320px) {
  .segment__title {
    padding-top: 3.75rem;
  }
}
@media screen and (min-width: 640px) {
  .segment__title {
    padding-top: 3.75rem;
  }
}
@media screen and (min-width: 768px) {
  .segment__title {
    padding-top: 5.3125rem;
  }
}
@media screen and (min-width: 1024px) {
  .segment__title {
    padding-top: 6.25rem;
  }
}
@media screen and (min-width: 1280px) {
  .segment__title {
    padding-top: 6.25rem;
  }
}
@media screen and (min-width: 320px) {
  .segment__title {
    padding-bottom: 3.75rem;
  }
}
@media screen and (min-width: 640px) {
  .segment__title {
    padding-bottom: 3.75rem;
  }
}
@media screen and (min-width: 768px) {
  .segment__title {
    padding-bottom: 5.3125rem;
  }
}
@media screen and (min-width: 1024px) {
  .segment__title {
    padding-bottom: 6.25rem;
  }
}
@media screen and (min-width: 1280px) {
  .segment__title {
    padding-bottom: 6.25rem;
  }
}
@media screen and (min-width: 320px) {
  .segment__title {
    font-size: 2rem;
  }
}
@media screen and (min-width: 640px) {
  .segment__title {
    font-size: 2.4375rem;
  }
}
@media screen and (min-width: 768px) {
  .segment__title {
    font-size: 3.4375rem;
  }
}
@media screen and (min-width: 1024px) {
  .segment__title {
    font-size: 3.75rem;
  }
}
@media screen and (min-width: 1280px) {
  .segment__title {
    font-size: 4rem;
  }
}
@media screen and (min-width: 1440px) {
  .segment__title {
    font-size: 4rem;
  }
}
.segment__image {
  margin-bottom: 1.5625rem;
  height: 100px;
  background: #ffffff;
  border-radius: 100%;
}
@media screen and (min-width: 320px) {
  .segment__image {
    margin-bottom: 1.5625rem;
  }
}
@media screen and (min-width: 640px) {
  .segment__image {
    margin-bottom: 1.5625rem;
  }
}
@media screen and (min-width: 768px) {
  .segment__image {
    margin-bottom: 1.875rem;
  }
}
@media screen and (min-width: 1024px) {
  .segment__image {
    margin-bottom: 2.5rem;
  }
}
@media screen and (min-width: 768px) {
  .segment__image {
    height: 333px;
  }
}
.segment__image--rounded {
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  padding: 0;
  margin: 0;
  -webkit-flex-wrap: wrap;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  width: 150px;
  height: 150px;
  margin: 0 auto;
  line-height: 0;
}
@media screen and (min-width: 1024px) {
  .segment__image--rounded {
    width: 250px;
    height: 250px;
  }
}
@media screen and (min-width: 1280px) {
  .segment__image--rounded {
    width: 300px;
    height: 300px;
  }
}
.section--no-bg .segment__image--rounded {
  background: transparent;
}
.segment__image--rounded-small {
  width: 120px;
  height: auto;
  background: transparent;
  border-radius: 0;
}
.segment__image--rounded-small .segment__text {
  margin-bottom: 0;
}
.segment__undertitle {
  font-size: 1.575rem;
}
@media screen and (min-width: 320px) {
  .segment__undertitle {
    font-size: 1.575rem;
  }
}
@media screen and (min-width: 640px) {
  .segment__undertitle {
    font-size: 2.1875rem;
  }
}
@media screen and (min-width: 768px) {
  .segment__undertitle {
    font-size: 2.1875rem;
  }
}
@media screen and (min-width: 1024px) {
  .segment__undertitle {
    font-size: 2.8125rem;
  }
}
@media screen and (min-width: 1280px) {
  .segment__undertitle {
    font-size: 2.8125rem;
  }
}
.segment__text {
  font-size: 1.125rem;
  font-weight: 400;
}
@media screen and (min-width: 320px) {
  .segment__text {
    font-size: 1.125rem;
  }
}
@media screen and (min-width: 640px) {
  .segment__text {
    font-size: 1.25rem;
  }
}
@media screen and (min-width: 768px) {
  .segment__text {
    font-size: 1.375rem;
  }
}
@media screen and (min-width: 1024px) {
  .segment__text {
    font-size: 1.375rem;
  }
}
.segment__main {
  margin: 0 auto;
  width: 90%;
  max-width: 1600px;
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  padding: 0;
  margin: 0;
  -webkit-flex-wrap: wrap;
  flex-wrap: wrap;
  justify-content: space-around;
  flex-flow: column;
  justify-content: center;
  align-items: center;
  position: relative;
  text-align: center;
}
@media screen and (min-width: 768px) {
  .segment__main {
    justify-content: space-between;
  }
}
@media screen and (min-width: 1024px) {
  .segment__main {
    flex-flow: row;
  }
}
.segment__main--flex-3 {
  justify-content: center;
}
@media screen and (min-width: 1280px) {
  .segment__main--flex-3 {
    margin-bottom: 3.75rem;
  }
}
@media screen and (min-width: 1280px) and (min-width: 320px) {
  .segment__main--flex-3 {
    margin-bottom: 3.75rem;
  }
}
@media screen and (min-width: 1280px) and (min-width: 640px) {
  .segment__main--flex-3 {
    margin-bottom: 3.75rem;
  }
}
@media screen and (min-width: 1280px) and (min-width: 768px) {
  .segment__main--flex-3 {
    margin-bottom: 5.3125rem;
  }
}
@media screen and (min-width: 1280px) and (min-width: 1024px) {
  .segment__main--flex-3 {
    margin-bottom: 6.25rem;
  }
}
@media screen and (min-width: 1280px) and (min-width: 1280px) {
  .segment__main--flex-3 {
    margin-bottom: 6.25rem;
  }
}
.segment__main--flex-3 .segment__single-col {
  flex: 0 100%;
}
@media screen and (min-width: 1024px) {
  .segment__main--flex-3 .segment__single-col {
    flex: 0 50%;
  }
}
@media screen and (min-width: 1280px) {
  .segment__main--flex-3 .segment__single-col {
    flex: 0 33.3333%;
  }
}
.segment__main--flex-3:nth-of-type(2) .segment__text {
  margin-bottom: 0;
}
.segment__main--flex-3 .segment__undertitle,
.segment__main--flex-3 .segment__text,
.segment__main--flex-3 .segment__image {
  margin-bottom: 1.5625rem;
}
@media screen and (min-width: 320px) {
  .segment__main--flex-3 .segment__undertitle,
  .segment__main--flex-3 .segment__text,
  .segment__main--flex-3 .segment__image {
    margin-bottom: 1.5625rem;
  }
}
@media screen and (min-width: 640px) {
  .segment__main--flex-3 .segment__undertitle,
  .segment__main--flex-3 .segment__text,
  .segment__main--flex-3 .segment__image {
    margin-bottom: 1.5625rem;
  }
}
@media screen and (min-width: 768px) {
  .segment__main--flex-3 .segment__undertitle,
  .segment__main--flex-3 .segment__text,
  .segment__main--flex-3 .segment__image {
    margin-bottom: 1.875rem;
  }
}
@media screen and (min-width: 1024px) {
  .segment__main--flex-3 .segment__undertitle,
  .segment__main--flex-3 .segment__text,
  .segment__main--flex-3 .segment__image {
    margin-bottom: 2.5rem;
  }
}
.segment__main--flexible {
  display: block;
}
@media screen and (min-width: 1024px) {
  .segment__main--flexible {
    display: flex;
  }
}
.segment__main--text-left, .segment__main--reversed {
  justify-content: flex-start;
}
.segment__main--text-left h1,
.segment__main--text-left h2,
.segment__main--text-left h3,
.segment__main--text-left h4, .segment__main--reversed h1,
.segment__main--reversed h2,
.segment__main--reversed h3,
.segment__main--reversed h4 {
  margin-bottom: 1.5625rem;
}
@media screen and (min-width: 320px) {
  .segment__main--text-left h1,
  .segment__main--text-left h2,
  .segment__main--text-left h3,
  .segment__main--text-left h4, .segment__main--reversed h1,
  .segment__main--reversed h2,
  .segment__main--reversed h3,
  .segment__main--reversed h4 {
    margin-bottom: 1.5625rem;
  }
}
@media screen and (min-width: 640px) {
  .segment__main--text-left h1,
  .segment__main--text-left h2,
  .segment__main--text-left h3,
  .segment__main--text-left h4, .segment__main--reversed h1,
  .segment__main--reversed h2,
  .segment__main--reversed h3,
  .segment__main--reversed h4 {
    margin-bottom: 1.5625rem;
  }
}
@media screen and (min-width: 768px) {
  .segment__main--text-left h1,
  .segment__main--text-left h2,
  .segment__main--text-left h3,
  .segment__main--text-left h4, .segment__main--reversed h1,
  .segment__main--reversed h2,
  .segment__main--reversed h3,
  .segment__main--reversed h4 {
    margin-bottom: 1.875rem;
  }
}
@media screen and (min-width: 1024px) {
  .segment__main--text-left h1,
  .segment__main--text-left h2,
  .segment__main--text-left h3,
  .segment__main--text-left h4, .segment__main--reversed h1,
  .segment__main--reversed h2,
  .segment__main--reversed h3,
  .segment__main--reversed h4 {
    margin-bottom: 2.5rem;
  }
}
.segment__main--text-left .text-normal, .segment__main--reversed .text-normal {
  margin-bottom: 1.5625rem;
}
@media screen and (min-width: 320px) {
  .segment__main--text-left .text-normal, .segment__main--reversed .text-normal {
    margin-bottom: 1.5625rem;
  }
}
@media screen and (min-width: 640px) {
  .segment__main--text-left .text-normal, .segment__main--reversed .text-normal {
    margin-bottom: 1.5625rem;
  }
}
@media screen and (min-width: 768px) {
  .segment__main--text-left .text-normal, .segment__main--reversed .text-normal {
    margin-bottom: 1.875rem;
  }
}
@media screen and (min-width: 1024px) {
  .segment__main--text-left .text-normal, .segment__main--reversed .text-normal {
    margin-bottom: 2.5rem;
  }
}
@media screen and (min-width: 1024px) {
  .segment__main--reversed {
    flex-flow: row-reverse;
  }
}
.segment__main--reversed .segment__left {
  margin-right: 0;
}
@media screen and (min-width: 1024px) {
  .segment__main--reversed .segment__right {
    margin-right: 3rem;
  }
}
@media screen and (min-width: 1024px) and (min-width: 320px) {
  .segment__main--reversed .segment__right {
    margin-right: 3rem;
  }
}
@media screen and (min-width: 1024px) and (min-width: 640px) {
  .segment__main--reversed .segment__right {
    margin-right: 4rem;
  }
}
@media screen and (min-width: 1024px) and (min-width: 768px) {
  .segment__main--reversed .segment__right {
    margin-right: 3.5rem;
  }
}
@media screen and (min-width: 1024px) and (min-width: 1024px) {
  .segment__main--reversed .segment__right {
    margin-right: 4rem;
  }
}
@media screen and (min-width: 1024px) and (min-width: 1280px) {
  .segment__main--reversed .segment__right {
    margin-right: 5rem;
  }
}
@media screen and (min-width: 1024px) and (min-width: 1440px) {
  .segment__main--reversed .segment__right {
    margin-right: 9rem;
  }
}
@media screen and (min-width: 1024px) and (min-width: 1600px) {
  .segment__main--reversed .segment__right {
    margin-right: 14rem;
  }
}
.segment__main--icon-grid, .segment__main--text-grid {
  flex-flow: row wrap;
}
.segment__main--icon-grid .segment__single-col, .segment__main--text-grid .segment__single-col {
  margin-bottom: 3.75rem;
  flex: 0 100%;
}
@media screen and (min-width: 320px) {
  .segment__main--icon-grid .segment__single-col, .segment__main--text-grid .segment__single-col {
    margin-bottom: 3.75rem;
  }
}
@media screen and (min-width: 640px) {
  .segment__main--icon-grid .segment__single-col, .segment__main--text-grid .segment__single-col {
    margin-bottom: 3.75rem;
  }
}
@media screen and (min-width: 768px) {
  .segment__main--icon-grid .segment__single-col, .segment__main--text-grid .segment__single-col {
    margin-bottom: 5.3125rem;
  }
}
@media screen and (min-width: 1024px) {
  .segment__main--icon-grid .segment__single-col, .segment__main--text-grid .segment__single-col {
    margin-bottom: 6.25rem;
  }
}
@media screen and (min-width: 1280px) {
  .segment__main--icon-grid .segment__single-col, .segment__main--text-grid .segment__single-col {
    margin-bottom: 6.25rem;
  }
}
@media screen and (min-width: 1024px) {
  .segment__main--icon-grid .segment__single-col, .segment__main--text-grid .segment__single-col {
    margin-bottom: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: 160px;
  }
}
@media screen and (min-width: 1024px) and (min-width: 320px) {
  .segment__main--icon-grid .segment__single-col, .segment__main--text-grid .segment__single-col {
    margin-bottom: 0;
  }
}
@media screen and (min-width: 1024px) and (min-width: 640px) {
  .segment__main--icon-grid .segment__single-col, .segment__main--text-grid .segment__single-col {
    margin-bottom: 0;
  }
}
@media screen and (min-width: 1024px) and (min-width: 768px) {
  .segment__main--icon-grid .segment__single-col, .segment__main--text-grid .segment__single-col {
    margin-bottom: 0;
  }
}
@media screen and (min-width: 1280px) {
  .segment__main--icon-grid .segment__single-col, .segment__main--text-grid .segment__single-col {
    margin-bottom: 0;
  }
}
@media screen and (min-width: 1280px) and (min-width: 320px) {
  .segment__main--icon-grid .segment__single-col, .segment__main--text-grid .segment__single-col {
    margin-bottom: 0;
  }
}
@media screen and (min-width: 1280px) and (min-width: 640px) {
  .segment__main--icon-grid .segment__single-col, .segment__main--text-grid .segment__single-col {
    margin-bottom: 0;
  }
}
@media screen and (min-width: 1280px) and (min-width: 768px) {
  .segment__main--icon-grid .segment__single-col, .segment__main--text-grid .segment__single-col {
    margin-bottom: 0;
  }
}
.segment__main--icon-grid .segment__single-col:nth-of-type(2), .segment__main--text-grid .segment__single-col:nth-of-type(2) {
  margin-left: 0;
  margin-right: 0;
}
@media screen and (min-width: 320px) {
  .segment__main--icon-grid .segment__single-col:nth-of-type(2), .segment__main--text-grid .segment__single-col:nth-of-type(2) {
    margin-left: 0;
  }
}
@media screen and (min-width: 640px) {
  .segment__main--icon-grid .segment__single-col:nth-of-type(2), .segment__main--text-grid .segment__single-col:nth-of-type(2) {
    margin-left: 0;
  }
}
@media screen and (min-width: 768px) {
  .segment__main--icon-grid .segment__single-col:nth-of-type(2), .segment__main--text-grid .segment__single-col:nth-of-type(2) {
    margin-left: 0;
  }
}
@media screen and (min-width: 320px) {
  .segment__main--icon-grid .segment__single-col:nth-of-type(2), .segment__main--text-grid .segment__single-col:nth-of-type(2) {
    margin-right: 0;
  }
}
@media screen and (min-width: 640px) {
  .segment__main--icon-grid .segment__single-col:nth-of-type(2), .segment__main--text-grid .segment__single-col:nth-of-type(2) {
    margin-right: 0;
  }
}
@media screen and (min-width: 768px) {
  .segment__main--icon-grid .segment__single-col:nth-of-type(2), .segment__main--text-grid .segment__single-col:nth-of-type(2) {
    margin-right: 0;
  }
}
@media screen and (min-width: 1440px) {
  .segment__main--icon-grid .segment__single-col:last-of-type, .segment__main--text-grid .segment__single-col:last-of-type {
    margin-right: auto;
  }
}
.animated-bg .segment__main--icon-grid svg, .animated-bg .segment__main--text-grid svg {
  stroke: #ffffff;
}
.segment__main--icon-grid .segment__undertitle, .segment__main--text-grid .segment__undertitle {
  font-size: 1.5rem;
  padding: 0 1rem;
}
@media screen and (min-width: 320px) {
  .segment__main--icon-grid .segment__undertitle, .segment__main--text-grid .segment__undertitle {
    font-size: 1.5rem;
  }
}
@media screen and (min-width: 640px) {
  .segment__main--icon-grid .segment__undertitle, .segment__main--text-grid .segment__undertitle {
    font-size: 1.5rem;
  }
}
@media screen and (min-width: 768px) {
  .segment__main--icon-grid .segment__undertitle, .segment__main--text-grid .segment__undertitle {
    font-size: 1.7rem;
  }
}
@media screen and (min-width: 1024px) {
  .segment__main--icon-grid .segment__undertitle, .segment__main--text-grid .segment__undertitle {
    font-size: 1.7rem;
  }
}
.animated-bg .segment__main--icon-grid .segment__undertitle, .animated-bg .segment__main--text-grid .segment__undertitle {
  color: white;
}
.segment__main--icon-grid {
  justify-content: space-between;
}
@media screen and (min-width: 640px) {
  .segment__main--icon-grid .segment__single-col {
    flex: 0 30%;
  }
}
@media screen and (min-width: 768px) {
  .segment__main--icon-grid .segment__single-col {
    flex: 0 30%;
  }
}
@media screen and (min-width: 1024px) {
  .segment__main--icon-grid .segment__single-col {
    flex: 0 25%;
  }
}
@media screen and (min-width: 1280px) {
  .segment__main--icon-grid .segment__single-col {
    flex: 0 25%;
  }
}
.segment__main--text-grid {
  justify-content: space-around;
}
@media screen and (min-width: 640px) {
  .segment__main--text-grid .segment__single-col {
    flex: 1 50%;
  }
}
@media screen and (min-width: 1024px) {
  .segment__main--text-grid .segment__single-col {
    flex: 1 33.3333%;
  }
}
@media screen and (min-width: 1440px) {
  .segment__main--text-grid .segment__single-col {
    flex: 1 25%;
  }
}
@media screen and (min-width: 1024px) {
  .segment__main--w-bgs {
    flex-flow: row wrap;
  }
}

.segment__left {
  margin-bottom: 2.5rem;
  font-size: 1.125rem;
  flex: 1;
  text-align: left;
}
@media screen and (min-width: 320px) {
  .segment__left {
    margin-bottom: 2.5rem;
  }
}
@media screen and (min-width: 640px) {
  .segment__left {
    margin-bottom: 2.5rem;
  }
}
@media screen and (min-width: 768px) {
  .segment__left {
    margin-bottom: 3.125rem;
  }
}
@media screen and (min-width: 1024px) {
  .segment__left {
    margin-bottom: 3.75rem;
  }
}
@media screen and (min-width: 320px) {
  .segment__left {
    font-size: 1.125rem;
  }
}
@media screen and (min-width: 640px) {
  .segment__left {
    font-size: 1.25rem;
  }
}
@media screen and (min-width: 768px) {
  .segment__left {
    font-size: 1.375rem;
  }
}
@media screen and (min-width: 1024px) {
  .segment__left {
    font-size: 1.375rem;
  }
}
@media screen and (min-width: 1280px) {
  .segment__left {
    font-size: 1.5rem;
  }
}
@media screen and (min-width: 1024px) {
  .segment__left {
    margin-right: 3rem;
    margin-bottom: 0;
  }
}
@media screen and (min-width: 1024px) and (min-width: 320px) {
  .segment__left {
    margin-right: 3rem;
  }
}
@media screen and (min-width: 1024px) and (min-width: 640px) {
  .segment__left {
    margin-right: 4rem;
  }
}
@media screen and (min-width: 1024px) and (min-width: 768px) {
  .segment__left {
    margin-right: 3.5rem;
  }
}
@media screen and (min-width: 1024px) and (min-width: 1024px) {
  .segment__left {
    margin-right: 4rem;
  }
}
@media screen and (min-width: 1024px) and (min-width: 1280px) {
  .segment__left {
    margin-right: 5rem;
  }
}
@media screen and (min-width: 1024px) and (min-width: 1440px) {
  .segment__left {
    margin-right: 9rem;
  }
}
@media screen and (min-width: 1024px) and (min-width: 1600px) {
  .segment__left {
    margin-right: 14rem;
  }
}
@media screen and (min-width: 1024px) and (min-width: 320px) {
  .segment__left {
    margin-bottom: 0;
  }
}
@media screen and (min-width: 1024px) and (min-width: 640px) {
  .segment__left {
    margin-bottom: 0;
  }
}
@media screen and (min-width: 1024px) and (min-width: 768px) {
  .segment__left {
    margin-bottom: 0;
  }
}
.segment__left-text {
  font-weight: 400;
}
.segment__left-text p:nth-of-type(2) {
  margin-top: 1.5625rem;
  font-weight: 500;
}
@media screen and (min-width: 320px) {
  .segment__left-text p:nth-of-type(2) {
    margin-top: 1.5625rem;
  }
}
@media screen and (min-width: 640px) {
  .segment__left-text p:nth-of-type(2) {
    margin-top: 1.5625rem;
  }
}
@media screen and (min-width: 768px) {
  .segment__left-text p:nth-of-type(2) {
    margin-top: 1.875rem;
  }
}
@media screen and (min-width: 1024px) {
  .segment__left-text p:nth-of-type(2) {
    margin-top: 2.5rem;
  }
}
.segment__left--w-bg {
  margin-bottom: 20px;
}
@media screen and (min-width: 1024px) {
  .segment__left--w-bg {
    margin-right: 20px;
    margin-bottom: 0;
  }
}
.segment__right {
  font-size: 1.575rem;
  flex: 1;
  text-align: center;
  opacity: 0.5;
}
@media screen and (min-width: 320px) {
  .segment__right {
    font-size: 1.575rem;
  }
}
@media screen and (min-width: 640px) {
  .segment__right {
    font-size: 2.1875rem;
  }
}
@media screen and (min-width: 768px) {
  .segment__right {
    font-size: 2.1875rem;
  }
}
@media screen and (min-width: 1024px) {
  .segment__right {
    font-size: 2.8125rem;
  }
}
@media screen and (min-width: 1280px) {
  .segment__right {
    font-size: 2.8125rem;
  }
}
.segment__right-image {
  opacity: 1;
}
@media screen and (max-width: 1023px) {
  .segment__main--w-bgs > div:nth-child(1) .image {
    background-image: url("/files/eatcommerce/images/selfordering_negishi_small.jpg");
  }
}
@media screen and (min-width: 1024px) {
  .segment__main--w-bgs > div:nth-child(1) .image {
    background-image: url("/files/eatcommerce/images/selfordering_negishi.jpg");
  }
}
@media screen and (max-width: 1023px) {
  .segment__main--w-bgs > div:nth-child(2) .image {
    background-image: url("/files/eatcommerce/images/selfordering_butcher_small.jpg");
  }
}
@media screen and (min-width: 1024px) {
  .segment__main--w-bgs > div:nth-child(2) .image {
    background-image: url("/files/eatcommerce/images/selfordering_butcher.jpg");
  }
}
@media screen and (max-width: 1023px) {
  .segment__main--w-bgs > div:nth-child(3) .image {
    background-image: url("/files/eatcommerce/images/selfordering_nooch_small.jpg");
  }
}
@media screen and (min-width: 1024px) {
  .segment__main--w-bgs > div:nth-child(3) .image {
    background-image: url("/files/eatcommerce/images/selfordering_nooch.jpg");
  }
}
@media screen and (max-width: 1023px) {
  .segment__main--w-bgs > div:nth-child(4) .image {
    background-image: url("/files/eatcommerce/images/selfordering_missmiu_small.jpg");
  }
}
@media screen and (min-width: 1024px) {
  .segment__main--w-bgs > div:nth-child(4) .image {
    background-image: url("/files/eatcommerce/images/selfordering_missmiu.jpg");
  }
}
@media screen and (max-width: 1023px) {
  .segment__main--w-bgs-webshop > div:nth-child(1) .image {
    background-image: url("/files/eatcommerce/images/webshop_negishi_small.jpg");
  }
}
@media screen and (min-width: 1024px) {
  .segment__main--w-bgs-webshop > div:nth-child(1) .image {
    background-image: url("/files/eatcommerce/images/webshop_negishi.jpg");
  }
}
@media screen and (max-width: 1023px) {
  .segment__main--w-bgs-webshop > div:nth-child(2) .image {
    background-image: url("/files/eatcommerce/images/webshop_butcher_small.jpg");
  }
}
@media screen and (min-width: 1024px) {
  .segment__main--w-bgs-webshop > div:nth-child(2) .image {
    background-image: url("/files/eatcommerce/images/webshop_butcher.jpg");
  }
}
@media screen and (max-width: 1023px) {
  .segment__main--w-bgs-webshop > div:nth-child(3) .image {
    background-image: url("/files/eatcommerce/images/webshop_dieci_small.jpg");
  }
}
@media screen and (min-width: 1024px) {
  .segment__main--w-bgs-webshop > div:nth-child(3) .image {
    background-image: url("/files/eatcommerce/images/webshop_dieci.jpg");
  }
}
@media screen and (max-width: 1023px) {
  .segment__main--w-bgs-webshop > div:nth-child(4) .image {
    background-image: url("/files/eatcommerce/images/webshop_missmiu_small.jpg");
  }
}
@media screen and (min-width: 1024px) {
  .segment__main--w-bgs-webshop > div:nth-child(4) .image {
    background-image: url("/files/eatcommerce/images/webshop_missmiu.jpg");
  }
}
.segment__left--w-bg, .segment__right--w-bg {
  position: relative;
  max-width: none;
  width: 100%;
  opacity: 1;
  flex: 0 0 calc(50% - 10px);
  margin-bottom: 20px;
}
.segment__left--w-bg .image, .segment__right--w-bg .image {
  position: relative;
  display: block;
  width: 100%;
  height: 300px;
  margin: 0 auto;
  color: #ffffff;
  background-size: cover;
  background-repeat: no-repeat;
}
.segment__left--w-bg .image:before, .segment__right--w-bg .image:before {
  content: "";
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(180deg, #000000, #7e7e7e);
  opacity: 0.2;
}
@media screen and (min-width: 768px) {
  .segment__left--w-bg .image, .segment__right--w-bg .image {
    height: 400px;
  }
}
.segment__left--w-bg .text, .segment__right--w-bg .text {
  font-size: 1rem;
  font-family: "DM Sans", sans-serif;
  font-weight: 500;
  position: absolute;
  bottom: 50px;
  left: 50%;
  right: 0;
  transform: translateX(-50%);
  color: #ffffff;
  text-align: center;
}
@media screen and (min-width: 320px) {
  .segment__left--w-bg .text, .segment__right--w-bg .text {
    font-size: 1rem;
  }
}
@media screen and (min-width: 640px) {
  .segment__left--w-bg .text, .segment__right--w-bg .text {
    font-size: 1rem;
  }
}
@media screen and (min-width: 768px) {
  .segment__left--w-bg .text, .segment__right--w-bg .text {
    font-size: 1.25rem;
  }
}
@media screen and (min-width: 1024px) {
  .segment__left--w-bg .text, .segment__right--w-bg .text {
    font-size: 1.25rem;
  }
}
.segment__left--w-bg .text > p, .segment__right--w-bg .text > p {
  margin-bottom: 0.3125rem;
}
@media screen and (min-width: 320px) {
  .segment__left--w-bg .text > p, .segment__right--w-bg .text > p {
    margin-bottom: 0.3125rem;
  }
}
@media screen and (min-width: 640px) {
  .segment__left--w-bg .text > p, .segment__right--w-bg .text > p {
    margin-bottom: 0.3125rem;
  }
}
@media screen and (min-width: 768px) {
  .segment__left--w-bg .text > p, .segment__right--w-bg .text > p {
    margin-bottom: 0.625rem;
  }
}
.segment__left--w-bg .button .icon--arrow path,
.segment__left--w-bg .button .icon--arrow line, .segment__right--w-bg .button .icon--arrow path,
.segment__right--w-bg .button .icon--arrow line {
  stroke: #000000;
}
.product__main .segment__left--w-bg .button, .product__main .segment__right--w-bg .button {
  margin-top: 0.9375rem;
}
@media screen and (min-width: 320px) {
  .product__main .segment__left--w-bg .button, .product__main .segment__right--w-bg .button {
    margin-top: 0.9375rem;
  }
}
@media screen and (min-width: 640px) {
  .product__main .segment__left--w-bg .button, .product__main .segment__right--w-bg .button {
    margin-top: 0.9375rem;
  }
}
@media screen and (min-width: 768px) {
  .product__main .segment__left--w-bg .button, .product__main .segment__right--w-bg .button {
    margin-top: 1.25rem;
  }
}
.ce_form {
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  padding: 0;
  margin: 0;
  -webkit-flex-wrap: wrap;
  flex-wrap: wrap;
  justify-content: space-around;
  justify-content: space-between;
  flex-flow: column;
  max-width: 585px;
  width: 100%;
  overflow: visible;
}
.ce_form form {
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  padding: 0;
  margin: 0;
  -webkit-flex-wrap: wrap;
  flex-wrap: wrap;
  justify-content: space-around;
  flex-flow: column;
  align-items: center;
}
.ce_form form .formbody {
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  padding: 0;
  margin: 0;
  -webkit-flex-wrap: wrap;
  flex-wrap: wrap;
  justify-content: space-around;
  flex-flow: column;
  align-items: center;
  width: 100%;
}
.ce_form form .formbody > * {
  box-sizing: border-box;
  width: 100%;
}
.ce_form form .formbody .widget-submit {
  width: auto;
  text-align: center;
}

.widget.widget-text, .widget.widget-captcha {
  position: relative;
  width: 100%;
  line-height: 0;
}
.widget.widget-text input, .widget.widget-captcha input {
  padding-top: 1.25rem;
  padding-right: 0.9375rem;
  padding-left: 0.9375rem;
  margin-top: 0;
  margin-bottom: 1.5625rem;
  width: 100%;
  height: 70px;
  padding-bottom: 6px;
  border: solid 2px #ffffff;
  border-radius: 8px;
  background: transparent;
}
@media screen and (min-width: 320px) {
  .widget.widget-text input, .widget.widget-captcha input {
    padding-top: 1.25rem;
  }
}
@media screen and (min-width: 640px) {
  .widget.widget-text input, .widget.widget-captcha input {
    padding-top: 1.25rem;
  }
}
@media screen and (min-width: 768px) {
  .widget.widget-text input, .widget.widget-captcha input {
    padding-top: 1.25rem;
  }
}
@media screen and (min-width: 1024px) {
  .widget.widget-text input, .widget.widget-captcha input {
    padding-top: 1.875rem;
  }
}
@media screen and (min-width: 320px) {
  .widget.widget-text input, .widget.widget-captcha input {
    padding-right: 0.9375rem;
  }
}
@media screen and (min-width: 640px) {
  .widget.widget-text input, .widget.widget-captcha input {
    padding-right: 0.9375rem;
  }
}
@media screen and (min-width: 768px) {
  .widget.widget-text input, .widget.widget-captcha input {
    padding-right: 1.25rem;
  }
}
@media screen and (min-width: 320px) {
  .widget.widget-text input, .widget.widget-captcha input {
    padding-left: 0.9375rem;
  }
}
@media screen and (min-width: 640px) {
  .widget.widget-text input, .widget.widget-captcha input {
    padding-left: 0.9375rem;
  }
}
@media screen and (min-width: 768px) {
  .widget.widget-text input, .widget.widget-captcha input {
    padding-left: 1.25rem;
  }
}
@media screen and (min-width: 320px) {
  .widget.widget-text input, .widget.widget-captcha input {
    margin-top: 0;
  }
}
@media screen and (min-width: 640px) {
  .widget.widget-text input, .widget.widget-captcha input {
    margin-top: 0;
  }
}
@media screen and (min-width: 768px) {
  .widget.widget-text input, .widget.widget-captcha input {
    margin-top: 0;
  }
}
@media screen and (min-width: 320px) {
  .widget.widget-text input, .widget.widget-captcha input {
    margin-bottom: 1.5625rem;
  }
}
@media screen and (min-width: 640px) {
  .widget.widget-text input, .widget.widget-captcha input {
    margin-bottom: 1.5625rem;
  }
}
@media screen and (min-width: 768px) {
  .widget.widget-text input, .widget.widget-captcha input {
    margin-bottom: 1.875rem;
  }
}
@media screen and (min-width: 1024px) {
  .widget.widget-text input, .widget.widget-captcha input {
    margin-bottom: 2.5rem;
  }
}
@media screen and (min-width: 1024px) {
  .widget.widget-text input, .widget.widget-captcha input {
    margin-bottom: 1.25rem;
  }
}
@media screen and (min-width: 1024px) and (min-width: 320px) {
  .widget.widget-text input, .widget.widget-captcha input {
    margin-bottom: 1.25rem;
  }
}
@media screen and (min-width: 1024px) and (min-width: 640px) {
  .widget.widget-text input, .widget.widget-captcha input {
    margin-bottom: 1.25rem;
  }
}
@media screen and (min-width: 1024px) and (min-width: 768px) {
  .widget.widget-text input, .widget.widget-captcha input {
    margin-bottom: 1.25rem;
  }
}
@media screen and (min-width: 1024px) and (min-width: 1024px) {
  .widget.widget-text input, .widget.widget-captcha input {
    margin-bottom: 1.875rem;
  }
}
.widget.widget-text:hover, .widget.widget-captcha:hover {
  border-bottom-color: #000000;
}
.widget .widget-captcha input {
  background: transparent;
}

.widget-textarea {
  position: relative;
  width: 100%;
  line-height: 0;
}

.hamburger {
  position: relative;
  top: 50%;
  width: 30px;
  height: 100%;
  transform: scaleX(-1) translateY(-50%);
}
@media screen and (min-width: 768px) {
  .hamburger {
    display: none;
  }
}
.hamburger__inner {
  position: relative;
}
.hamburger__bar {
  -webkit-transition: transform 0.2s ease-in-out;
  -moz-transition: transform 0.2s ease-in-out;
  -ms-transition: transform 0.2s ease-in-out;
  -o-transition: transform 0.2s ease-in-out;
  transition: transform 0.2s ease-in-out;
  position: absolute;
  display: block;
  width: 15px;
  top: 14px;
  height: 2px;
  background: #ffffff;
}
.hamburger__bar:after, .hamburger__bar:before {
  -webkit-transition: transform 0.2s ease-in-out;
  -moz-transition: transform 0.2s ease-in-out;
  -ms-transition: transform 0.2s ease-in-out;
  -o-transition: transform 0.2s ease-in-out;
  transition: transform 0.2s ease-in-out;
  content: "";
  position: absolute;
  display: block;
  height: 2px;
  background: #ffffff;
}
.hamburger__bar:before {
  -webkit-transition: opacity 0.2s ease-in-out;
  -moz-transition: opacity 0.2s ease-in-out;
  -ms-transition: opacity 0.2s ease-in-out;
  -o-transition: opacity 0.2s ease-in-out;
  transition: opacity 0.2s ease-in-out;
  width: 30px;
  bottom: 10px;
}
.hamburger__bar:after {
  width: 24px;
  top: 10px;
}
.hamburger--is-active {
  outline: none;
}
.hamburger--is-active .hamburger__bar {
  top: 4px;
  width: 30px;
  transform: translate3d(0, 10px, 0) rotate(45deg);
}
.hamburger--is-active .hamburger__bar:before {
  transform: rotate(-45deg) translate3d(0, 0, 0);
  opacity: 0;
  visibility: hidden;
}
.hamburger--is-active .hamburger__bar:after {
  top: 20px;
  width: 30px;
  transform: translate3d(0, -20px, 0) rotate(-90deg);
}

.mobile-menu {
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  padding: 0;
  margin: 0;
  -webkit-flex-wrap: wrap;
  flex-wrap: wrap;
  justify-content: space-around;
  align-items: center;
  justify-content: center;
  flex-flow: column;
  margin: 0 auto;
  padding-top: 5.4rem;
  -webkit-transition: transform 0.2s ease-in-out;
  -moz-transition: transform 0.2s ease-in-out;
  -ms-transition: transform 0.2s ease-in-out;
  -o-transition: transform 0.2s ease-in-out;
  transition: transform 0.2s ease-in-out;
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  width: 90%;
  transform: translateX(120%);
  transform-origin: right;
  height: 100vh;
  margin: 0 auto;
  background: transparent;
  z-index: 3;
}
@media screen and (min-width: 320px) {
  .mobile-menu {
    padding-top: 5.4rem;
  }
}
@media screen and (min-width: 640px) {
  .mobile-menu {
    padding-top: 5.4rem;
  }
}
@media screen and (min-width: 768px) {
  .mobile-menu {
    padding-top: 5.4rem;
  }
}
@media screen and (min-width: 1024px) {
  .mobile-menu {
    padding-top: 6.7rem;
  }
}
@media screen and (min-width: 1280px) {
  .mobile-menu {
    padding-top: 6.7rem;
  }
}
@media screen and (min-width: 1440px) {
  .mobile-menu {
    padding-top: 6.7rem;
  }
}
@media screen and (min-width: 1600px) {
  .mobile-menu {
    padding-top: 6.7rem;
  }
}
@media screen and (min-width: 768px) {
  .mobile-menu {
    display: none;
  }
}
.mobile-menu--active {
  transform: translateX(0);
}

@keyframes slideIn {
  100% {
    transform: translateX(0);
  }
}
.menu-element {
  text-align: center;
}
.menu-element__single {
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  padding: 0;
  margin: 0;
  -webkit-flex-wrap: wrap;
  flex-wrap: wrap;
  justify-content: space-around;
  align-items: center;
  justify-content: center;
  flex-flow: column;
  font-size: 2rem;
  padding-top: 1.5625rem;
  padding-bottom: 1.5625rem;
  color: #ffffff;
}
@media screen and (min-width: 320px) {
  .menu-element__single {
    font-size: 2rem;
  }
}
@media screen and (min-width: 640px) {
  .menu-element__single {
    font-size: 2.4375rem;
  }
}
@media screen and (min-width: 768px) {
  .menu-element__single {
    font-size: 3.4375rem;
  }
}
@media screen and (min-width: 1024px) {
  .menu-element__single {
    font-size: 3.75rem;
  }
}
@media screen and (min-width: 1280px) {
  .menu-element__single {
    font-size: 4rem;
  }
}
@media screen and (min-width: 1440px) {
  .menu-element__single {
    font-size: 4rem;
  }
}
@media screen and (min-width: 320px) {
  .menu-element__single {
    padding-top: 1.5625rem;
  }
}
@media screen and (min-width: 640px) {
  .menu-element__single {
    padding-top: 1.5625rem;
  }
}
@media screen and (min-width: 768px) {
  .menu-element__single {
    padding-top: 1.875rem;
  }
}
@media screen and (min-width: 1024px) {
  .menu-element__single {
    padding-top: 2.5rem;
  }
}
@media screen and (min-width: 320px) {
  .menu-element__single {
    padding-bottom: 1.5625rem;
  }
}
@media screen and (min-width: 640px) {
  .menu-element__single {
    padding-bottom: 1.5625rem;
  }
}
@media screen and (min-width: 768px) {
  .menu-element__single {
    padding-bottom: 1.875rem;
  }
}
@media screen and (min-width: 1024px) {
  .menu-element__single {
    padding-bottom: 2.5rem;
  }
}
.menu-element__single:first-of-type {
  padding-top: 0;
}
@media screen and (min-width: 320px) {
  .menu-element__single:first-of-type {
    padding-top: 0;
  }
}
@media screen and (min-width: 640px) {
  .menu-element__single:first-of-type {
    padding-top: 0;
  }
}
@media screen and (min-width: 768px) {
  .menu-element__single:first-of-type {
    padding-top: 0;
  }
}
.menu-element__single:last-of-type {
  padding-bottom: 0;
}
@media screen and (min-width: 320px) {
  .menu-element__single:last-of-type {
    padding-bottom: 0;
  }
}
@media screen and (min-width: 640px) {
  .menu-element__single:last-of-type {
    padding-bottom: 0;
  }
}
@media screen and (min-width: 768px) {
  .menu-element__single:last-of-type {
    padding-bottom: 0;
  }
}
.menu-element__single--bordered {
  position: relative;
}
.menu-element__single--bordered:after, .menu-element__single--bordered:before {
  content: "";
  position: absolute;
  width: 120px;
  height: 2px;
  background: #ffffff;
}
.menu-element__single--bordered:before {
  bottom: 0;
}
.menu-element__single--bordered:after {
  top: 0;
}

.overlay-active {
  position: relative;
  overflow: hidden;
}
.overlay-active:before {
  -webkit-transition: max-height 0.2s ease-in-out, opacity 0.2s ease-in-out;
  -moz-transition: max-height 0.2s ease-in-out, opacity 0.2s ease-in-out;
  -ms-transition: max-height 0.2s ease-in-out, opacity 0.2s ease-in-out;
  -o-transition: max-height 0.2s ease-in-out, opacity 0.2s ease-in-out;
  transition: max-height 0.2s ease-in-out, opacity 0.2s ease-in-out;
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  display: block;
  width: 900vw;
  height: 100vh;
  max-height: 100vh;
  opacity: 1;
  background: linear-gradient(83deg, rgb(95, 236, 171) 0%, rgb(71, 178, 219) 6%, rgb(110, 41, 220) 18%, rgb(129, 45, 170) 27%, rgb(178, 68, 100) 39%, rgb(209, 69, 102) 46%, rgb(178, 68, 100) 52%, rgb(129, 45, 170) 60%, rgb(110, 41, 220) 69%, rgb(71, 178, 219) 78%, rgb(95, 236, 171) 89%, rgb(71, 178, 219) 95%, rgb(88, 120, 219) 100%);
  animation: falloff 55s linear infinite;
  animation-fill-mode: forwards;
  will-change: transform;
  z-index: 2;
}