.icon {
	width: 1em;
	height: auto;

	&--in-rounded-section {
		max-width: 68%;
		width: 100%;
	}

	&--in-small-section {
		width: 80%;

		@include media('screen-md') {
			width: 100%;
		}

		path,
		line,
		circle,
		polyline {
			stroke: $color-white !important;
		}
	}

	&--down {
		stroke: $color-white !important;
	}
	
	&--arrow {
		path,
		line {
			stroke: $color-black;
		}
	}
	
	&--large {
		max-width: 100%;
	}

	&--paper-plane {
		path {
			//animation: dashcolor 55s linear infinite;
			//stroke: $color-black;


			&:nth-of-type(2) {
				//animation: fillblend 55s linear infinite;
				fill: $color-black;
			}
		}
	}

	&--envelope {
		width: 2em;

		path,
		g,
		rect {
			//animation: dashcolor 55s linear infinite;
			stroke: $color-black;
		}
	}

	&--twitter {
		width: 1.6em;
	}

	&--fill {
		path {
			animation: fillblend 55s linear infinite;
		}
	}
}
