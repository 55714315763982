.media-container {
	position: relative;
	background-position: center center;
	background-repeat: no-repeat;
	background-size: cover;
	overflow: hidden;

	&,
	&--square,
	&--ratio-1-1,
	&--xs-square,
	&--xs-ratio-1-1 {
		padding-bottom: $ratio-square;
	}

	&--portrait,
	&--ratio-3-4,
	&--xs-portrait,
	&--xs-ratio-3-4 {
		padding-bottom: $ratio-portrait;
	}

	&--ratio-4-3,
	&--xs-ratio-4-3 {
		padding-bottom: $ratio-4-3;
	}

	&--landscape,
	&--ratio-16-9,
	&--xs-landscape,
	&--xs-ratio-16-9 {
		padding-bottom: $ratio-landscape;
	}

	&--ratio-16-10,
	&--xs-ratio-16-10 {
		padding-bottom: $ratio-16-10;
	}

	&--round,
	&--xs-round {
		border-radius: 50%;
	}

	&--rectangle,
	&--xs-rectangle {
		border-radius: 0;
	}

	&__placeholder {
		background: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' viewBox='0 0 {{w}} {{h}}'><defs><symbol id='a' viewBox='0 0 90 66' opacity='0.3'><path d='M85 5v56H5V5h80m5-5H0v66h90V0z'/><circle cx='18' cy='20' r='6'/><path d='M56 14L37 39l-8-6-17 23h67z'/></symbol></defs><use xlink:href='#a' width='30%' x='35%'/></svg>") no-repeat;

		&--mobile-only {
			@include media('>=md') {
				background: none;
			}
		}
	}

	@each $modifier, $breakpoint in $grid-map-props {
		@include media($breakpoint) {
			&--#{$modifier}-square,
			&--#{$modifier}-ratio-1-1 {
				padding-bottom: $ratio-square;
			}

			&--#{$modifier}-portrait,
			&--#{$modifier}-ratio-3-4 {
				padding-bottom: $ratio-portrait;
			}

			&--#{$modifier}-ratio-4-3 {
				padding-bottom: $ratio-4-3;
			}

			&--#{$modifier}-landscape,
			&--#{$modifier}-ratio-16-9 {
				padding-bottom: $ratio-landscape;
			}

			&--#{$modifier}-ratio-16-10 {
				padding-bottom: $ratio-16-10;
			}

			&--#{$modifier}-round {
				border-radius: 50%;
			}

			&--#{$modifier}-rectangle {
				border-radius: 0;
			}
		}
	}

	img,
	video {
		@include full-height-media;
	}

	> iframe,
	> div {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
	}
}
