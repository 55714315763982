.header {
	@include fullscreen();
	@include responsify($header-height, height);
	@include flex() {
		justify-content: center;
		align-items: flex-start;
		margin: 0 auto;
	}
	position: absolute;
	left: 50%;
	transform: translateX(-50%);
	background: none;
	z-index: 99;

	@include media('screen-md') {
		@include responsify($spacing-no, padding-bottom);
	}

	&__container {
		@include wrapped();
		@include flex() {
			justify-content: space-between;
			align-items: center;
			margin: 0 auto;
		}
		position: relative;
		z-index: 99999;
	}
}