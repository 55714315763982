.hero-image {
	@include fullscreen('full');
	@include fluidheight();
	@include bg-image('placeholder_1', $type: 'png');
	position: relative;

	&:before {
		content: "";
		position: absolute;
		left: 0;
		bottom: 0;
		width: 100%;
		height: 100%;
		background: linear-gradient(180deg, $start-bg-0, $color-grey);
		opacity: 0.2;
	}
	
	.danke & {
		height: 100vh;
	}
}

.image-header-slider {
	@include fluidheight();
	position: relative;

	.slideshow__slide {
		@include fluidheight();
	}
	
	.slideshow__bullets {
		&:before {
			display: none;
		}
	}
}