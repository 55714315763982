.navigation {
	$navigation: &;
	
	@include responsify($spacing-md, 'padding-top');
	@include responsify($spacing-md, 'padding-bottom');

	&__items {
		@include flex() {
			justify-content: space-between;
			align-items: center;
		}
	}

	&__element {
		@include responsify($font-sizes-sm);
		@include responsify($spacing-sm, 'margin-right');

		&:last-of-type {
			@include responsify($spacing-no, 'margin-right');
		}

		a {
			color: $color-white;
		}

		&--solutions {
			display: none;

			@include media('screen-md') {
				display: block;
			}
		}

		&--request {
			@include responsify($spacing-xs, 'padding-top');
			@include responsify($spacing-xs, 'padding-bottom');
			display: block;
			padding-left: 0.5375rem;
			padding-right: 0.5375rem;
			border-radius: 5px;
			background: $color-white;

			@include media('screen-sm') {
				@include responsify($spacing-sm, 'padding-right');
				@include responsify($spacing-sm, 'padding-left');
			}

			a {
				display: flex;				
				justify-content: space-between;
				color: $color-black;

				&:hover {
					@include media('screen-md') {
						svg {
							transform: scale(1.06);
						}
					}
				}

				span {
					@include transition(transform 0.2s ease-in-out);
				}

				svg {
					@include media('screen-md') {
						@include transition(transform 0.2s ease-in-out);
						display: block;
						width: 20px;
						margin-left: 5px;
						//transform: translateX(-120%);
					}
				}
			}
		}
		
		&--mobile-menu {
			position: relative;
			width: 30px;
			height: 30px;
			
			@include media('screen-md') {
				display: none;
			}
		}
		
		&--ext-link {
			@include media('screen-md') {
				margin-right: 0;
			}
		}
	}

	&__custom-link {
		@include flex() {
			justify-content: center;
			align-items: center;
		}
		height: 47px;

		> #{$navigation}__text {
			@include flex() {
				justify-content: center;
				align-items: center;
			}
			font-weight: 400;
		}
	}

	&__text {
		font-weight: 400;

		&--hide-mobile {
			display: none;

			@include media('screen-md') {
				display: block;
			}
		}
	}

	&__mobile-icon {
		display: flex;
		align-items: center;
		justify-content: center;

		@include media('screen-md') {
			display: none;
		}
	}

	&__link {
		#{$navigation}__element--request & {
			//animation: colorblend 55s linear infinite;
			//animation-fill-mode: forwards;

			body:not(.home) & {
				animation: none;
			}
		}
	}
}
