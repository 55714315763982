@mixin hover($class: null) {
	@media (hover: hover) {
		@content;
	}

	@if ($class) {
		@at-root {
			.is-ie#{$class} {
				@content;
			}
		}
	}
	@else {
		.is-ie & {
			@content;
		}
	}
}
