.main-top {
	@include flex() {
		flex-flow: column;
		justify-content: space-between;
		align-items: center;
	}
	min-height: 100vh;
	color: $color-white;
	overflow: hidden;

	// IE doesn't really support min-height :(
	@media screen and (-ms-high-contrast: active), screen and (-ms-high-contrast: none) {
		height: 100vh;
	}

	@include media('screen-lg') {
		flex-flow: row;
	}

	&__content {
		@include wrapped();
		@include responsify($header-height, padding-top);
		@include flex() {
			flex-flow: column;
			justify-content: center;
			align-items: center;
		}
		position: relative;
		height: 100%;

		@include media('screen-lg') {
			@include wrapped('normal');
			flex-flow: row;
		}
	}

	&__title {
		@include responsify($font-sizes-xxl);

		@media screen and (min-width: 1920px) {
			font-size: 5.625rem;
		}
	}

	&__undertitle {
		@include responsify($spacing-lg, 'margin-top');
		@include responsify($font-sizes-sm);
	}

	&__left {
		@include responsify($spacing-md, 'margin-bottom');

		@include media('screen-lg') {
			@include responsify($spacing-xl, 'margin-right');
			flex: 1;
		}
		margin-bottom: 0;
	}

	&__right {
		width: 100%;

		@include media('screen-lg') {
			flex: 1;
		}
	}
}
