.screencasts {
	position: relative;
	width: 100%;
	height: 100%;
	box-sizing: border-box;
	padding-bottom: 100%;

	&__slide {
		position: absolute;
		top: 0;
		left: 0;
		display: flex;
		flex-flow: column;
		justify-content: center;
		align-content: center;
		align-items: center;
		width: 100%;
		height: 100%;
		opacity: 0;
		transition: 1.5s $ease-out-quad;
		visibility: hidden;

		&--active {
			opacity: 1;
			visibility: visible;
		}

		&-text {
			@include responsify($spacing-sm, 'margin-top');
			@include responsify($font-sizes-sm);
			font-weight: normal;
			text-align: center;
			width: 100%;
		}
	}

	&__device {
		$device: &;
		position: relative;
		filter: drop-shadow(0 0 1rem rgba(0, 0, 0, 0.5));

		svg {
			display: block;
			width: 100%;
			height: auto;
		}

		&--desktop {
			width: 100%;
		}

		&--kiosk {
			width: 40%;
		}

		&--mobile {
			width: 30%;
		}

		&-screen {
			position: relative;
			padding-bottom: (100% / 9 * 16);
			overflow: hidden;

			#{$device}--desktop & {
				padding-bottom: 55%;
				margin-top: -0.0625rem;
			}

			#{$device}--kiosk & {
				padding-bottom: (100% / 9 * 16);
				margin-bottom: -0.0625rem;
			}

			#{$device}--mobile & {
				padding-bottom: (100% / 9 * 16);
				margin-top: -0.0625rem;
			}

			&-image,
			&-video {
				@include full-height-media;
			}
			&-video {

				&--selforder {
					transform: scale(1.07);
				}
			}
		}
	}
}
