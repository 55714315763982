
//font-sizes: add as many as you want and also as many steps as you want. They are used step by step for responsive screens, beginning with the smallest resolution
//example: [default size and size for first entry in responsive breakpoints list], [size for second entry upwards], [size for third entry upwars] ,...
$font-sizes-xs: 0.75rem, 0.75rem, 0.75rem, 0.75rem;
$font-sizes-sm: 1rem, 1rem, 1.25rem, 1.25rem;
$font-sizes-nm: 1.125rem, 1.25rem, 1.375rem, 1.375rem;
$font-sizes-md: 1.125rem, 1.25rem, 1.375rem, 1.375rem, 1.5rem;
$font-sizes-lg: 1.575rem, 2.1875rem, 2.1875rem, 2.8125rem, 2.8125rem;
$font-sizes-xl: 2rem, 2.4375rem, 3.4375rem, 3.75rem, 4rem, 4rem; //define line heights, to responsify them later
$font-sizes-xxl: 2rem, 2.5rem, 3.375rem, 4.2rem, 4.2rem, 4.2rem;

// Special Font Sizes
$font-sizes-special: 1.5rem, 1.5rem, 1.7rem, 1.7rem;

// Button Font Sizes
$font-sizes-buttons: 1rem, 1rem, 1.25rem, 1.25rem;

$font-sizes-labels: 1rem, 1rem, 1rem, 1rem;

$line-height-sm: 1, 1, 1, 1;
$line-height-md: 1.15, 1.17, 1.17, 1.17;
$line-height-nm: 1.2, 1.2, 1.2, 1.2;
$line-height-lg: 1.2, 1.3, 1.35;
$line-height-xl: 1.35, 1.4, 1.5;

$font-sans: 'DM Sans', sans-serif;

@mixin font-medium() {
	font-family: $font-sans;
	font-weight: 500;
}

@mixin font-face($name, $weight: null, $style: null, $exts: eot woff2 woff ttf svg) {
	$src: null;
	$extmods: (
		eot: "?",
		svg: "#" + str-replace($name, " ", "_")
	);
	$formats: (
		otf: "opentype",
		ttf: "truetype"
	);
	@each $ext in $exts {
		$extmod: if(map-has-key($extmods, $ext), $ext + map-get($extmods, $ext), $ext);
		$format: if(map-has-key($formats, $ext), map-get($formats, $ext), $ext);
		$src: append($src, url(quote($fonts-base-path + "." + $extmod)) format(quote($format)), comma);
	}
	@font-face {
		font-family: quote($name);
		font-style: $style;
		font-weight: $weight;
		src: $src;
	}
}

//Placeholders for inputs

//Additional Styles
::-webkit-input-placeholder {
	/* WebKit browsers */
	color: transparent;
	opacity: 0;
}

:-moz-placeholder {
	/* Mozilla Firefox 4 to 18 */
	color: transparent;
	opacity: 0;
}

::-moz-placeholder {
	/* Mozilla Firefox 19+ */
	color: transparent;
	opacity: 0;
}

:-ms-input-placeholder {
	/* Internet Explorer 10+ */
	color: transparent;
	opacity: 0;
}
