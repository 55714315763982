.scroll-to-top {
	position: fixed;
	right: 1rem;
	bottom: 1rem;
	display: flex;
	justify-content: center;
	cursor: pointer;
	align-content: center;
	align-items: center;
	width: 3rem;
	height: 3rem;
	padding: 0.5rem;
	transform: translate3d(200%, 0, 0);
	transition: 0.2s $ease-out-quad;
	font-size: 2rem;
	text-align: center;
	line-height: 1;
	opacity: 0;
	border-radius: 5px;
	background: $color-white;
	box-shadow: 0 0 0.5rem rgba($color-black, 0.2);
	overflow: hidden;
	z-index: 999999;

	.page--scrolled & {
		opacity: 1;
		transform: none;
	}

	@include hover {
		&:hover {
			svg {
				animation: scroll-to-top-icon 0.3s linear;
			}
		}
	}
	
	&__arrow {
		display: flex;
		height: 24px;
		transform: rotate(-90deg);
		
		svg {
			width: 0.8em;
		}
		
		path,
		line {
			stroke: $color-black;
		}
	}
}

@keyframes scroll-to-top-icon {
  0% {
    transform: none;
  }

  50% {
    transform: translate3d(200%, 0, 0);
  }

  51% {
    transform: translate3d(-200%, 0, 0);
  }

  100% {
    transform: none;
  }
}