.overlay-active {
	position: relative;
	overflow: hidden;
	
	&:before {
		@include transition(max-height 0.2s ease-in-out, opacity 0.2s ease-in-out);
		content: '';
		position: absolute;
		top: 0;
		left: 0;
		display: block;
		width: 900vw;
		height: 100vh;
		max-height: 100vh;
		opacity: 1;
		background: linear-gradient(83deg, rgba(95, 236, 171, 1) 0%, rgba(71, 178, 219, 1) 6%, rgba(110, 41, 220, 1) 18%, rgba(129, 45, 170, 1) 27%, rgba(178, 68, 100, 1) 39%, rgba(209, 69, 102, 1) 46%, rgba(178, 68, 100, 1) 52%, rgba(129, 45, 170, 1) 60%, rgba(110, 41, 220, 1) 69%, rgba(71, 178, 219, 1) 78%, rgba(95, 236, 171, 1) 89%, rgba(71, 178, 219, 1) 95%, rgba(88, 120, 219, 1) 100%);
		animation: falloff 55s linear infinite;
		animation-fill-mode: forwards;
		will-change: transform;
		z-index: 2;
	}
}