.section {
	@include flex() {
		flex-flow: column;
		justify-content: center;
		align-items: center;
	}
	@include responsify($spacing-xl, 'padding-top');
	@include responsify($spacing-xl, 'padding-bottom');
	height: auto;

	@include media('screen-xxl') {
		min-height: 100vh;
	}

	&--bg-1 {
		color: $color-white;
		background: linear-gradient(-45deg, $end-bg-1, $start-bg-1);
	}

	&--bg-2 {
		color: $color-white;
		background: linear-gradient(-45deg, $end-bg-2, $start-bg-2);
	}

	&--bg-3 {
		color: $color-white;
		background: linear-gradient(-45deg, $end-bg-3, $start-bg-3);
	}

	&--bg-4 {
		color: $color-white;
		background: linear-gradient(-45deg, $end-bg-4, $start-bg-4);
	}

	&--no-bg {
		position: relative;
		color: $color-black;
		background: none;

		&:before {
			@include scrim-gradient();
			content: '';
			position: absolute;
			top: 0;
			left: 0;
			display: block;
			width: 100%;
			height: 100%;
			opacity: 0.05;
		}
	}

	&--divided {
		position: relative;

		&:before {
			@include scrim-gradient();
			content: '';
			position: absolute;
			top: 0;
			left: 0;
			display: block;
			width: 100%;
			height: 100%;
			opacity: 0.05;
		}
	}

	&--anim-bg {
		position: relative;
		color: $color-white;
		overflow: hidden;

		&:before {
			content: "";
			position: absolute;
			top: 0;
			left: 0;
			display: block;
			height: 100%;
			width: 900vw;
			background: linear-gradient(83deg, rgba(95, 236, 171, 1) 0%, rgba(71, 178, 219, 1) 7%, rgba(110, 41, 220, 1) 21%, rgba(129, 45, 170, 1) 31%, rgba(178, 68, 100, 1) 44%, rgba(209, 69, 102, 1) 51%, rgba(178, 68, 100, 1) 59%, rgba(129, 45, 170, 1) 68%, rgba(110, 41, 220, 1) 78%, rgba(71, 178, 219, 1) 91%, rgba(95, 236, 171, 1) 100%);
			animation: falloff 55s linear infinite;
			animation-fill-mode: forwards;
			z-index: -1;
		}
	}

	&--double {
		@include responsify($spacing-sm, margin-top);
		justify-content: flex-start;
		height: auto;

		@include media('screen-md') {
			@include responsify($spacing-no, margin-top);
		}

		.segment__main {
			align-items: flex-start;
		}
	}

	.trailing-text {
		@include wrapped('normal');
		max-width: 450px;
		margin: 0 auto;
		text-align: center;
		font-weight: 400;
	}
}
