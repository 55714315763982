.product {
	@include responsify($header-height, padding-top);
	display: block;
	color: $color-white;
	background-size: cover;
	background-repeat: no-repeat;
	
	&__top {
		@include wrapped();
		position: relative;
		top: 50%;
		transform: translateY(-50%);

		@include media('screen-lg') {
			@include wrapped('normal');
		}
	}
	
	&__title {
		@include responsify($font-sizes-xxl);
	}
	
	&__undertitle {
		@include responsify($spacing-sm, 'margin-top');
		@include responsify($font-sizes-sm);
		font-weight: 400;
	}
	
	iframe {
		width: 100%;
		height: 100%;
	}
}

