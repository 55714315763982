.segment {
	$segment: &;

	&__left {
		@include responsify($spacing-xl, 'margin-bottom');
		@include responsify($font-sizes-md);
		flex: 1;
		text-align: left;

		@include media('screen-lg') {
			@include responsify($flex-gutter, 'margin-right');
			@include responsify($spacing-no, 'margin-bottom');
		}

		&-text {
			font-weight: 400;

			p {
				&:nth-of-type(2) {
					@include responsify($spacing-lg, 'margin-top');
					font-weight: 500;
				}
			}
		}

		&--w-bg {
			margin-bottom: 20px;

			@include media('screen-lg') {
				margin-right: 20px;
				margin-bottom: 0;
			}
		}
	}

	&__right {
		@include responsify($font-sizes-lg);
		flex: 1;
		text-align: center;
		opacity: 0.5;

		&-image {
			opacity: 1;
		}
	}

	&__main--w-bgs > div {
		&:nth-child(1) {
				.image {
					@include bg-image('selfordering_negishi', $type: 'jpg');
				}
		}

		&:nth-child(2) {
				.image {
					@include bg-image('selfordering_butcher', $type: 'jpg');
				}
		}

		&:nth-child(3) {
				.image {
					@include bg-image('selfordering_nooch', $type: 'jpg');
				}
		}

		&:nth-child(4) {
				.image {
					@include bg-image('selfordering_missmiu', $type: 'jpg');
				}
		}
	}

	&__main--w-bgs-webshop > div {
		&:nth-child(1) {
				.image {
					@include bg-image('webshop_negishi', $type: 'jpg');
				}
		}

		&:nth-child(2) {
				.image {
					@include bg-image('webshop_butcher', $type: 'jpg');
				}
		}

		&:nth-child(3) {
				.image {
					@include bg-image('webshop_dieci', $type: 'jpg');
				}
		}

		&:nth-child(4) {
				.image {
					@include bg-image('webshop_missmiu', $type: 'jpg');
				}
		}
	}

	&__left,
	&__right {
		&--w-bg {
			position: relative;
			max-width: none;
			width: 100%;
			opacity: 1;
			flex: 0 0 calc(50% - 10px);
			margin-bottom: 20px;

			.image {
				position: relative;
				display: block;
				width: 100%;
				height: 300px;
				margin: 0 auto;
				color: $color-white;
				background-size: cover;
				background-repeat: no-repeat;

				&:before {
					content: "";
					position: absolute;
					left: 0;
					bottom: 0;
					width: 100%;
					height: 100%;
					background: linear-gradient(180deg, $start-bg-0, $color-grey);
					opacity: 0.2;
				}

				@include media('screen-md') {
					height: 400px;
				}
			}

			.text {
				@include responsify($font-sizes-sm);
				@include font-medium();
				position: absolute;
				bottom: 50px;
				left: 50%;
				right: 0;
				transform: translateX(-50%);
				color: $color-white;
				text-align: center;

				> p {
					@include responsify($spacing-xs, 'margin-bottom');
				}
			}

			.button {
				.icon--arrow {
					path,
					line {
						stroke: $color-black;
					}
				}
			}

			.product__main & {
				.button {
					@include responsify($spacing-sm, 'margin-top');
				}
			}
		}
	}
}
