.contact {
	@include flex() {
		flex-flow: column;
		align-items: center;
	}
	@include responsify($spacing-xl, 'padding-bottom');	

	&__image {
		@include responsify($spacing-md, 'margin-right');
		@include box-shadow-double(-20px, 8px, 1px, 20px, rgba(223,34,245,0.44), 20px, 8px, 1px, 9px, rgba(223,34,245,0.44));
		border-radius: 100%;
		line-height: 0;
	}

	&__text {
		@include responsify($spacing-xl, 'margin-top');
		@include responsify($font-sizes-nm);
		flex: 0 60%;
		
		@include media('screen-md') {
			@include responsify($spacing-no, 'margin-top');
		}
	}

	&__form {
		@include flex() {
			flex-flow: column;
			align-items: center;
		}
		width: 100%;

		> * {
			box-sizing: border-box;
		}
	}
	
	.button {
		font-weight: 500;
	}
}