@-ms-viewport {
	width: device-width;
}

* {
	&,
	&:before,
	&:after {
		box-sizing: inherit;
	}
}

html {
	box-sizing: border-box;
	-webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

body {
	@include responsify($font-sizes-sm);
	@include responsify($line-height-nm, 'line-height');
	@include font-medium();
	font-family: $font-sans;
	-webkit-overflow-scrolling: touch;
}

h1 {
	@include responsify($font-sizes-xxl);
	@include responsify($line-height-nm, 'line-height');
	font-family: $font-sans;

	&.ce_headline {
		@include wrapped('small');
		@include responsify($spacing-lg, 'margin-bottom');
		text-align: center;

		// @TODO custom spacing override for certain element combinations - better solution?
		& + .lead-text {
			@include responsify($spacing-no, 'padding');
			@include responsify($spacing-xl, 'margin-top', "-")
		}
	}
}

h2 {
	@include responsify($font-sizes-xl);
	@include responsify($line-height-nm, 'line-height');
}

h3 {
	@include responsify($font-sizes-lg);
	@include responsify($line-height-nm, 'line-height');
}

h4 {
	@include responsify($font-sizes-md);
	@include responsify($line-height-nm, 'line-height');
}

a {
	color: $color-black;
	font-weight: 400;
}

img {
	width: 100%;
	height: auto;
}

input,
textarea {
	font-family: $font-sans;
}

p,
span {
	@include responsify($line-height-nm, 'line-height');
}

.invisible {
	display: none;
}

.text-normal {
	@include responsify($font-sizes-nm);
	font-weight: 400;

	.footer & {
		@include responsify($spacing-xs, 'margin-top');
		@include responsify($spacing-lg, 'margin-bottom');
		max-width: 600px;
		width: 100%;
		text-align: center;
	}
}

select {
	-moz-appearance: none;
	-webkit-appearance: none;
}

select::-ms-expand {
	display: none;
}

.animated-bg {
	position: relative;
	overflow: hidden;

	&:before {
		content: "";
		position: absolute;
		left: 0;
		display: block;
		width: 900vw;
		height: 100%;
		background: linear-gradient(83deg, rgba(95, 236, 171, 1) 0%, rgba(71, 178, 219, 1) 6%, rgba(110, 41, 220, 1) 18%, rgba(129, 45, 170, 1) 27%, rgba(178, 68, 100, 1) 39%, rgba(209, 69, 102, 1) 46%, rgba(178, 68, 100, 1) 52%, rgba(129, 45, 170, 1) 60%, rgba(110, 41, 220, 1) 69%, rgba(71, 178, 219, 1) 78%, rgba(95, 236, 171, 1) 89%, rgba(71, 178, 219, 1) 95%, rgba(88, 120, 219, 1) 100%);
		animation: falloff 55s linear infinite;
		animation-fill-mode: forwards;
		will-change: transform;
	}

	> * {
		color: $color-white;
	}
}

.danke {
	.product__top {
		text-align: center;
	}

	.footer {
		display: none;
	}
}

.data,
.impressum {
	header {
		position: relative;
		overflow-x: hidden;

		&:before {
			content: "";
			position: absolute;
			left: 0;
			display: block;
			width: 900vw;
			height: 100%;
			background: linear-gradient(83deg, rgba(95, 236, 171, 1) 0%, rgba(71, 178, 219, 1) 6%, rgba(110, 41, 220, 1) 18%, rgba(129, 45, 170, 1) 27%, rgba(178, 68, 100, 1) 39%, rgba(209, 69, 102, 1) 46%, rgba(178, 68, 100, 1) 52%, rgba(129, 45, 170, 1) 60%, rgba(110, 41, 220, 1) 69%, rgba(71, 178, 219, 1) 78%, rgba(95, 236, 171, 1) 89%, rgba(71, 178, 219, 1) 95%, rgba(88, 120, 219, 1) 100%);
			animation: falloff 55s linear infinite;
			animation-fill-mode: forwards;
		}
	}
}

// Add class to html on mobile menu to prevent scrolling
.no-scroll {
	overflow: hidden;
}

// browser auto-fill styling
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
	border: 1px solid #FFFFFF;
	-webkit-text-fill-color: #FFFFFF;
	-webkit-box-shadow: 0 0 0 1000px rgb(135, 135, 135) inset;	
}
