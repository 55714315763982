.data-main {
	@include wrapped('normal');
	@include responsify($spacing-xxl, padding-top);
	@include responsify($spacing-xxl, 'padding-bottom');	
	
	h1,
	h2,
	h3 {
		text-align: center;
		word-break: break-all;
		
		@include media('screen-sm') {
			word-break: normal;
		}
	}

	h2,
	h4 {
		@include responsify($spacing-md, 'padding-top');
		@include responsify($spacing-md, 'padding-bottom');
	}

	h3 {
		@include responsify($spacing-xl, 'padding-top');
		@include responsify($spacing-lg, 'padding-bottom');
	}

	p {
		font-weight: 400;
		line-height: 1.4;
	}
	
	a {
		word-break: break-all;
		text-decoration: underline;

		@include media('screen-lg') {
			word-break: normal;		
		}
	}

	&__content {
		@include media('screen-lg') {
			@include wrapped('small');
		}
		
		&--impressum {
			text-align: center;
		}
	}
}