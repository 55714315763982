.input {
	$input: &;
	
	&__container {
		position: relative;
		width: 100%;
		line-height: 0;
	}

	&__input {
		@include responsify($spacing-sm, 'padding-right');
		@include responsify($spacing-sm, 'padding-left');
		@include responsify($font-sizes-sm);
		width: 100%;
		padding-bottom: 6px;
		margin: 8px 0;
		border: solid 2px $color-white;
		background: transparent;
		color: $color-white;

		@at-root {
			@-webkit-keyframes autofill {
				0%, 100% {
					color: $color-white;
					background: transparent;
				}
			}
		}

		&:-webkit-autofill {
			-webkit-animation-delay: 1s; /* Safari support - any positive time runs instantly */
			-webkit-animation-name: autofill;
			-webkit-animation-fill-mode: both;
		}

		&:hover,
		&:focus {
			outline: none;
		}

		&:focus,
		&:not(:placeholder-shown) {
			& + #{$input}__label {
				@include transition(transform 0.2s ease-in-out, font-size 0.2s ease-in-out);
				@include responsify($font-sizes-labels);
				transform: translateY(-18px);
			}
		}

		&:not(:-ms-input-placeholder) {
			& + #{$input}__label {
				@include transition(transform 0.2s ease-in-out, font-size 0.2s ease-in-out);
				@include responsify($font-sizes-labels);
				transform: translateY(-8px);
			}
		}

		&::placeholder {
			font-size: 0;
		}

		#{$input}__container--bordered & {
			@include responsify($spacing-md, 'padding-top');
			@include responsify($spacing-sm, 'padding-right');
			@include responsify($spacing-sm, 'padding-left');
			@include responsify($spacing-no, 'margin-top');
			@include responsify($spacing-md, 'margin-bottom');
			width: 100%;
			height: 70px;
			padding-bottom: 6px;
			border: solid 2px $color-white;
			border-radius: 8px;
		}

		&:invalid {
			color: $color-red;
			border-bottom: 2px solid $color-red;		
			
			&:focus {
				~ #{$input}__error {
					display: block;
				}
			}			
		}
	}

	&__label {
		@include responsify($spacing-xs, 'padding');
		@include responsify($font-sizes-sm);
		position: absolute;
		bottom: 42px;
		left: 10px;
		padding-bottom: 2px;
		color: $color-white;
		pointer-events: none;
		font-weight: 400;

		#{$input}__container--bordered & {
			left: 13px;
			bottom: 50px;

			@include media('screen-md') {
				bottom: 45px;
			}

			@include media('screen-lg') {
				bottom: 55px;
			}
		}
	}
	
	&__error {
		position: absolute;
		top: 73px;
		left: 0;
		display: none;
		padding: 2px 10px;
		line-height: 1;
		font-size: 16px;
		color: $color-white;
		border-radius: 8px;
		background: rgba(255, 0, 0, 0.4);
		white-space: normal;

		@media screen and (max-width: 320px) {
			top: 69px;
			width: 250px;
			font-size: 12px;
		}

		@include media('screen-md') {
			padding: 4px 10px;
		}
	}
}

.textarea {
	$textarea: &;
	
	&__container {
		position: relative;
		width: 100%;
		line-height: 0;
	}

	&__label {
		@include responsify($spacing-xs, 'padding');
		position: absolute;
		top: 20px;
		left: 10px;
		padding-bottom: 2px;
		color: $color-white;
		pointer-events: none;
		font-weight: 400;
		
		@include media('screen-md') {
			top: 24px;
		}
	}

	&__textarea {
		@include responsify($spacing-sm, 'padding');
		@include responsify($spacing-md, 'margin-bottom');
		@include responsify($font-sizes-sm);
		width: 100%;
		min-height: 144px;
		border: solid 2px $color-white;
		resize: none;
		border-radius: 8px;
		background: transparent;
		color: $color-white;

		&:focus,
		&:not(:placeholder-shown) {
			& + #{$textarea}__label {
				@include transition(font-size 0.2s ease-in-out);
				font-size: 0;
			}
		}
	}
}

.custom-select {
	width: 100%;

	&__select {
		@include responsify($spacing-sm, 'padding');
		@include responsify($spacing-md, 'margin-bottom');
		@include responsify($font-sizes-sm);
		width: 100%;
		min-height: 40px;
		color: $color-white;
		background: transparent;
		border: solid 2px $color-white;
		border-radius: 8px;
		font-family: $font-sans;
		white-space: normal;
		background: url('#{$icons-base-path}arrow-down.svg') no-repeat right 10px center;

		@include media('screen-md') {
			background-position: right 20px center;
		}
	}

	&__option {
		color: $color-black;
		background: transparent;
	}
}

// invisible captcha. If you are a bot, it will show
.widget-captcha {
	@include responsify($spacing-md, 'margin-bottom');

	.captcha {
		@include responsify($spacing-sm, 'padding-right');
		@include responsify($spacing-sm, 'padding-left');
		@include responsify($font-sizes-sm);
		width: 100%;
		padding-bottom: 6px;
		margin: 8px 0;
		border: solid 2px $color-white;
		background: transparent;
		color: $color-white;

		&:focus,
		&:not(:placeholder-shown) {
			& ~ .captcha__label {
				@include transition(transform 0.2s ease-in-out, font-size 0.2s ease-in-out);
				@include responsify($font-sizes-labels);
				transform: translateY(-18px);
			}
		}
	}

	.captcha_text {
		@include responsify($spacing-xs, 'margin-bottom');
		display: block;
		padding: 2px 10px;
		color: $color-white;
		border-radius: 8px;
		background: rgba(255, 0, 0, 0.4);
	}

	label {
		@include responsify($spacing-xs, 'padding');
		@include responsify($font-sizes-sm);
		position: absolute;
		bottom: 42px;
		left: 10px;
		padding-bottom: 2px;
		color: $color-white;
		pointer-events: none;
		font-weight: 400;
	}
}