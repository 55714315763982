.ce_form {
	@include flex() {
		justify-content: space-between;
		flex-flow: column;
	}
	max-width: 585px;
	width: 100%;
	overflow: visible;

	form {
		@include flex() {
			flex-flow: column;
			align-items: center;
		}

		.formbody {
			@include flex() {
				flex-flow: column;
				align-items: center;
			}
			width: 100%;

			> * {
				box-sizing: border-box;
				width: 100%;
			}
			
			.widget-submit {
				width: auto;
				text-align: center;
			}
		}
	}
}

.widget {
	&.widget-text,
	&.widget-captcha {
		position: relative;
		width: 100%;
		line-height: 0;
		
		input {
			@include responsify($spacing-md, 'padding-top');
			@include responsify($spacing-sm, 'padding-right');
			@include responsify($spacing-sm, 'padding-left');
			@include responsify($spacing-no, 'margin-top');
			@include responsify($spacing-lg, 'margin-bottom');
			width: 100%;
			height: 70px;
			padding-bottom: 6px;
			border: solid 2px $color-white;
			border-radius: 8px;
			background: transparent;

			@include media('screen-lg') {
				@include responsify($spacing-md, 'margin-bottom');			
			}
		}

		label {
		}

		&:hover {
			//override contao default behavior
			border-bottom-color: $color-primary;
		}
	}
	
	.widget-captcha {
		
		input {
			background: transparent;

		}
	}
}

.widget-textarea {
	position: relative;
	width: 100%;
	line-height: 0;
}
