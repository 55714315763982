.button {
	@include button() {
		@include responsify($font-sizes-sm);
		@include responsify($spacing-xs, 'padding-top');
		@include responsify($spacing-xs, 'padding-bottom');
		@include responsify($spacing-md, 'padding-left');
		@include responsify($spacing-md, 'padding-right');
		@include responsify($line-height-sm, 'line-height');
		position: relative;
		font-family: $font-sans;
		transition: background 0.3s ease-in-out;
		text-transform: lowercase;
		background: $color-white;
		overflow: hidden;
	}

	&--primary {
		border-radius: 5px;
	}

	&--secondary {
		border-radius: 5px;
	}

	&--red {
		color: $color-btn-text;
		background: $color-btn-primary;
	}

	&--purple {
		color: $color-white;
		background: $color-custom-purple;
	}

	&--white {
		color: $color-black;
		background: $color-white;
	}

	&--w-icon {
		@include flex-inline() {
			align-items: center;
			justify-content: center;
		}

		&:hover {
			.icon--arrow {
				transform: translateX(5px);
			}

			.icon--paper-plane {
				transform: translate3d(2px, -3px, 0);
			}
		}

		span {
			margin-right: 10px;
			font-weight: 500;
		}

		.icon--arrow {
			@include transition(transform 0.2s ease-in-out);			
		}
		
		.icon--paper-plane {
			@include transition(transform 0.2s ease-in-out);			
		}
	}

	&--animated-color {
		color: $color-black;
		animation: colorblend 55s linear infinite;
		animation-fill-mode: forwards;

		path,
		line {
			animation: dashcolor 55s linear infinite;
		}
	}

	&--padded {
		@include responsify($btn-padded, 'padding-top');
		@include responsify($btn-padded, 'padding-bottom');
	}
}

.link {
	@include transition(color 0.2s ease-in-out);
	color: $color-white;
}
