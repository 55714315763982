@keyframes falloff {
	0% {
		transform: translateX(0%);
	}

	100% {
		transform: translateX(calc(-100% + 100vw));
	}
}

//falloffblend mixin
@keyframes falloffblend {
	0% {
		background: linear-gradient(47deg, rgba(95, 236, 171, 0.7) 70%, rgba(95, 236, 171, 0.2) 100%);
	}

	5% {
		background: linear-gradient(47deg, rgba(71, 178, 219, 0.7) 0%, rgba(71, 178, 219, 0.2) 100%);
	}

	20% {
		background: linear-gradient(47deg, rgba(110, 41, 220, 0.7) 0%, rgba(110, 41, 220, 0.2) 100%);
	}

	30% {
		background: linear-gradient(47deg, rgba(129, 45, 170, 0.7) 0%, rgba(129, 45, 170, 0.2) 100%);
	}

	44% {
		background: linear-gradient(47deg, rgba(178, 68, 100, 0.7) 0%, rgba(178, 68, 100, 0.2) 100%);
	}

	51% {
		background: linear-gradient(47deg, rgba(209, 69, 102, 0.7) 0%, rgba(209, 69, 102, 0.2) 100%);
	}

	59% {
		background: linear-gradient(47deg, rgba(209, 69, 102, 0.7) 0%, rgba(209, 69, 102, 0.2) 100%);
	}

	68% {
		background: linear-gradient(47deg, rgba(129, 45, 170, 0.7) 0%, rgba(129, 45, 170, 0.2) 100%);
	}

	78% {
		background: linear-gradient(47deg, rgba(110, 41, 220, 0.7) 0%, rgba(110, 41, 220, 0.2) 100%);
	}

	91% {
		background: linear-gradient(47deg, rgba(71, 178, 219, 0.7) 0%, rgba(71, 178, 219, 0.2) 100%);
	}

	100% {
		background: linear-gradient(47deg, rgba(95, 236, 171, 0.7) 0%, rgba(95, 236, 171, 0.2) 100%);
	}
}

@mixin colorblend($name:colorblend, $var:color) {
	@keyframes #{$name} {
		0% {
			#{$var}: rgba(95, 236, 171, 1);
		}

		6% {
			#{$var}: rgba(71, 178, 219, 1);
		}

		18% {
			#{$var}: rgba(110, 41, 220, 1);
		}

		27% {
			#{$var}: rgba(129, 45, 170, 1);
		}

		39% {
			#{$var}: rgba(178, 68, 100, 1);
		}

		46% {
			#{$var}: rgba(209, 69, 102, 1);
		}

		52% {
			#{$var}: rgba(178, 68, 100, 1);
		}

		60% {
			#{$var}: rgba(129, 45, 170, 1);
		}

		69% {
			#{$var}: rgba(110, 41, 220, 1);
		}

		78% {
			#{$var}: rgba(71, 178, 219, 1);
		}

		89% {
			#{$var}: rgba(95, 236, 171, 1);
		}

		95% {
			#{$var}: rgba(71, 178, 219, 1);
		}

		100% {
			#{$var}: rgba(88, 120, 219, 1);
		}
	}
}

@keyframes change {
	0% {
		transform: translateX(0%) translateY(0%);
	}

	22% {
		transform: translateX(0%) translateY(0%);
	}

	25% {
		transform: translateX(-33%) translateY(-22%);
	}
	47% {
		transform: translateX(-33%) translateY(-22%);
	}
	50% {
		transform: translateX(0%) translateY(0%);
	}
	72% {
		transform: translateX(0%) translateY(0%);
	}
	75% {
		transform: translateX(33%) translateY(22%);
	}
	97% {
		transform: translateX(33%) translateY(22%);
	}
	100% {
		transform: translateX(0%) translateY(0%);
	}
}

@keyframes movearound {
	0% {
		transform: translateY(0%);
	}
	50% {
		transform: translateY(0.3%);
	}
	100% {
		transform: translateY(0%);
	}
}

//generate mixins
@include colorblend();
@include colorblend('fillblend', 'fill');
@include colorblend('dashcolor', 'stroke');
