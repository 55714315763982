.footer {
	overflow: hidden;

	&__content {
		@include responsify($spacing-xxl, 'padding-top');
		@include responsify($spacing-xxl, 'padding-bottom');
		@include responsify($line-height-nm, 'line-height');
		@include wrapped();
		@include flex() {
			flex-flow: column;
			align-items: center;
			margin: 0 auto;
			color: $color-white;
		}
		position: relative;

		h2 {
			text-align: center;
		}
	}

	&__form {
		@include flex() {
			justify-content: space-between;
			flex-flow: column;
		}
		max-width: 585px;
		width: 100%;
		overflow: visible;
	}

	&__bottom {
		@include wrapped('extra-small');
		@include flex() {
			justify-content: center;
			flex-flow: row wrap;
		}

		> .link {
			flex: 0 50%;
			text-align: center;
			font-weight: 400;
		}

		.location {
			@include responsify($spacing-lg, 'margin-bottom');
			max-width: 200px;
			text-align: center;
			font-weight: 400;
		}
		
		a {
			color: $color-white;
		}
	}
}
