.animated-svg {

	text {
    font-family: $font-sans !important;
  }

	#shopmanager {
		transform: translateY(-10%);
		transition: 0.5s ease 0.75s;
		opacity: 0;

		.insicht--sichtbar & {
			opacity: 1;
			transform: translateY(0%);
		}
	}

	#cash {
		transform: translateY(-10%);
		transition: 0.5s ease 1.1s;
		opacity: 0;

		.insicht--sichtbar & {
			transform: translateY(0%);
			opacity: 1;
		}
	}

	#system-two {
		transform: translateY(-10%);
		opacity: 0;
		transition: 0.5s ease 1.5s;

		.insicht--sichtbar & {
			opacity: 1;
			transform: translateY(0%);
		}
	}

	#system-one {
		transform: translateY(-10%);
		opacity: 0;
		transition: 0.5s ease 1.9s;

		.insicht--sichtbar & {
			opacity: 1;
			transform: translateY(0%);
		}
	}

	#selfordering {
		transform: translateY(-10%);
		opacity: 0;
		transition: 0.5s ease 0.75s;


		.insicht--sichtbar & {
			opacity: 1;
			transform: translateY(0%);
		}
	}

	#website {
		transform: translateY(-10%);
		opacity: 0;
		transition: 0.5s ease 1.1s;

		.insicht--sichtbar & {
			opacity: 1;
			transform: translateY(0%);
		}
	}

	#smartphone {
		transform: translateY(-10%);
		opacity: 0;
		transition: 0.5s ease 1.5s;

		.insicht--sichtbar & {
			opacity: 1;
			transform: translateY(0%);
		}
	}

	.change-container {
		animation: change 12s linear infinite;
	}
}

#connections {
	.gradient-stroke {
		stroke-dasharray: 1000;
		stroke-dashoffset: -500;
		//will-change: dashcolor;
		//animation: dashcolor 55s linear infinite;
		transition: all 1s ease 2.5s, opacity 0.5s ease 3.75s;

		.insicht--sichtbar & {
			stroke-dashoffset: 0;
		}
	}
}

.movearound {
	//animation: movearound 1.75s linear infinite;
	//will-change: transform;
}

#Gradient {
	stop {
		stop-opacity: 1;
	}
}

.gradient-element {
	opacity: 0;
	//will-change: color, transform, opacity;
	//animation: fillblend 55s linear infinite;
	transition: opacity 0.5s ease 3.75s;
	fill: rgba(71, 178, 219, 0.3) !important;

	&--instant {
		transition: none;
		opacity: 1;
	}

	&--nomove {
		//animation: fillblend 55s linear infinite;
	}

	.insicht--sichtbar & {
		opacity: 1;
	}

	&--violet {
		fill: rgba(129, 45, 170, 0.3) !important;

	}

	&--green {
		fill: rgba(95, 236, 171, 0.3) !important;
	}
	
	&--red {
		fill: rgba(209, 69, 102, 0.3) !important;
	}

}

.initial-element {
	//opacity: 1;
	transition: opacity 0.5s ease 3.75s;
	will-change: transform, opacity;
	opacity: 0.3;

	.insicht--sichtbar & {
		opacity: 0;
	}
}
