//Wrapper mixins
// @todo: responsive wrapping (mobile)

@mixin wrapped($size:'big', $mode:'normal') {
	margin: 0 auto;
	@each $sizeitem in $wrapper-sizes {
		@if $sizeitem == $size {
			$index: index($wrapper-sizes, $size);

			@if $mode == 'normal' {
				width: nth($wrapper-widths, $index); //set with to according list value
			}
			max-width: nth($wrapper-max-widths, $index); //set max-width to according list value
		}
	}
}

// Fluid height for top sections on pages in relation to top-padding (header)
@mixin fluidheight($mode: 'less') {
	@each $item, $screensize in $screens {
		$index: index(($screens), ($item $screensize));
		//loop for every screen until list of values is empty with min-width queries
		@media screen and (min-width: $screensize) {
			$height: nth($header-height, $index);
			
			@if $mode == 'less' {
				height: calc(80vh - #{$height});
			}
			
			@if $mode == 'more' {
				height: calc(100vh - #{$height});
			}
		}
	}
}

@mixin fullscreen($parent:'big', $mode: 'normal') {
	//@TODO: Fullscreen wrappers do not calculate scrollbars, as they work with "vw" -> Ideas?

	@if $mode == 'normal' {
		width: auto;
		$activewidth: nth($wrapper-widths, index($wrapper-sizes, $parent));
		$activemaxwidth: nth($wrapper-max-widths, index($wrapper-sizes, $parent));

		//default and no max-width
		margin-right: calc(-1 * ((100vw - #{$activewidth}) / 2));
		margin-left: calc(-1 * ((100vw - #{$activewidth}) / 2));
		//value with max width set
		@if type-of($activemaxwidth) == 'number' {
			margin-right: calc(-1 * ((100vw - #{$activemaxwidth}) / 2));
			margin-left: calc(-1 * ((100vw - #{$activemaxwidth}) / 2));
		}

		//query when max-width set
		@if type-of($activemaxwidth) == 'number' {
			@media screen and (max-width: $activemaxwidth) {
				margin-right: calc(-1 * ((100vw - #{$activewidth}) / 2));
				margin-left: calc(-1 * ((100vw - #{$activewidth}) / 2));
			}
		}

		@if $parent == 'full' {
			margin-left: 0;
			margin-right: 0;
		}
	}

	@if $mode == 'fullscreen' {
		width: auto;
		$activewidth: nth($wrapper-widths, index($wrapper-sizes, $parent));
		$activemaxwidth: nth($wrapper-max-widths, index($wrapper-sizes, $parent));

		//default and no max-width
		margin-right: calc(-1 * ((100vw - #{$activewidth} + #{$scrollbar}) / 2));
		margin-left: calc(-1 * ((100vw - #{$activewidth} + #{$scrollbar}) / 2));
		//value with max width set
		@if type-of($activemaxwidth) == 'number' {
			margin-right: calc(-1 * ((100vw - #{$activemaxwidth} + #{$scrollbar}) / 2));
			margin-left: calc(-1 * ((100vw - #{$activemaxwidth} + #{$scrollbar}) / 2));
		}

		//query when max-width set
		@if type-of($activemaxwidth) == 'number' {
			@media screen and (max-width: $activemaxwidth) {
				margin-right: calc(-1 * ((100vw - #{$activewidth} + #{$scrollbar}) / 2));
				margin-left: calc(-1 * ((100vw - #{$activewidth} + #{$scrollbar}) / 2));
			}
		}

		@if $parent == 'full' {
			margin-left: 0;
			margin-right: 0;
		}
	}
}


@mixin full-height-media($fit: cover, $position: center) {
	object-fit: $fit;
	object-position: $position;
	pointer-events: none;
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;

	&::-webkit-media-controls {
		display: none;
	}

	/**
	 * TODO use this workaround if no object-fit polyfill is used
	 */
	/* .no-objectfit & {
		top: 50%;
		left: 50%;
		width: auto;
		height: auto;
		min-width: 100%;
		min-height: 100%;
		max-width: none;
		max-height: none;
		transform: translate(-50%, -50%);
	} */
}


@mixin reset-wrapper($parent:'big') {
	width: auto;
	max-width: none;
}

//Button Mixin
@mixin button($mode:'normal') {
	display: inline-block;
	text-align: center;
	cursor: pointer;
	text-decoration: none;
	vertical-align: middle;
	border: none;
	outline: none;

	@if $mode == 'extend' {
		display: inline-block;
		@include responsify($spacing-sm, 'padding');
		@include responsify($spacing-sm, 'margin');
		border: none;
		cursor: pointer;
		background: $color-btn-primary; //standard;
		color: $color-btn-text;
		font-size: $font-sizes-sm;
	}
	@content;
}

//Button hover incl. hover-additions;
@mixin button-hover($btn-bg: $color-btn-primary) {
	&:hover {
		background: lighten($btn-bg, 15%);
		@content;
	}
}

//GRID mixins
@mixin grid($grid: grid, $auto-rows: auto, $template-col: auto, $temp-rows: auto, $col-gap: $spacing-no, $row-gap: $spacing-no, $auto-flow: row) {
	display: -webkit- $grid;
	display: $grid;
	grid-auto-rows: $auto-rows;
	$repeat: round(100 / strip-unit($template-col));

	grid-template-columns: repeat($repeat, calc(#{$template-col} - #{nth($col-gap, 1)} + (#{nth($col-gap, 1)} / #{$repeat})));

	@each $item, $screensize in $screens {
		$index: index(($screens), ($item $screensize));
		//loop for every screen until list of values is empty with min-width queries
		@media screen and (min-width: $screensize) {
			@if (length($col-gap) >= $index) {
				$colgap: nth($col-gap, $index);
				grid-template-columns: repeat($repeat, calc(#{$template-col} - #{$colgap} + (#{$colgap} / #{$repeat})));
			}

		}
	}

	//grid-template-columns: repeat(auto-fill, minmax($template-col, 1fr));
	@include responsify($row-gap, 'grid-row-gap');
	@include responsify($col-gap, 'grid-column-gap');
	grid-template-rows: $temp-rows;
	grid-auto-flow: $auto-flow;

	@content; //extend grid

	//IE 10/11 Fallback
	@media screen and (-ms-high-contrast: active), screen and (-ms-high-contrast: none) {
		display: flex;
		flex-flow: row wrap;
		justify-content: space-between;

		> * {
			flex-basis: calc(#{$template-col} - #{nth($col-gap, 1)});
			max-width: calc(#{$template-col} - #{nth($col-gap, 1)});
			margin-bottom: (2 * nth($row-gap, 1)) - (nth($row-gap, 1) / 2);
			//max-width: $template-col;
			box-sizing: border-box;
			position: relative;
		}
	}
}

@mixin grid-temp-col($template-col: auto, $col-gap: $spacing-no) {
	$repeat: round(100 / strip-unit($template-col));

	grid-template-columns: repeat($repeat, calc(#{$template-col} - #{nth($col-gap, 1)} + (#{nth($col-gap, 1)} / #{$repeat})));

	@each $item, $screensize in $screens {
		$index: index(($screens), ($item $screensize));
		//loop for every screen until list of values is empty with min-width queries
		@media screen and (min-width: $screensize) {
			@if (length($col-gap) >= $index) {
				$colgap: nth($col-gap, $index);
				grid-template-columns: repeat($repeat, calc(#{$template-col} - #{$colgap} + (#{$colgap} / #{$repeat})));
			}
		}
	}
}

@mixin grid-item($col: 1, $row: 1) {
	font-size: 100%;
	grid-column: span $col;
	grid-row: span $row;
}

@mixin create-grid($modifier, $grid-cols, $breakpoint) {
	//creates classes for class-based grid
	@if $modifier == '' {
		@for $i from 1 through $grid-cols {
			@for $j from 1 through $grid-cols {
				.element-#{$i}-#{$j} {
					grid-column: span $i;
					grid-row: span $j;
				}
			}
		}
	}

	@include media($breakpoint) {
		@for $i from 1 through $grid-cols {
			@for $j from 1 through $grid-cols {
				.element#{$modifier}-#{$i}-#{$j} {
					grid-column: span $i;
					grid-row: span $j;
				}
			}
		}
	}
}

//initialize Grid
@each $modifier, $breakpoint in $grid-map-props {
	@include create-grid($modifier, $grid-max-cols, $breakpoint);
}

//FLEX mixin
// MIXINS - SETUP
@mixin media($width, $type: min) {
	@if map_has_key($screens, $width) {
		$width: map_get($screens, $width);
		@if $type == max {
			$width: $width - 1px;
		}
		@media screen and (#{$type}-width: $width) {
			@content;
		}
	}
}

//creates classes for class based grid-cols (e.g. element-sm-1, element-md-6)
@mixin create-cols($modifier, $grid-cols, $breakpoint) {
	@if $modifier == '' {
		@for $i from 1 through $grid-cols {
			.element-#{$i} {
				flex-basis: (100 / ($grid-cols / $i)) * 1%;
			}
		}
	}
	@include media($breakpoint) {
		.element#{$modifier}-offset-0 {
			margin-left: 0;
		}
		@for $i from 1 through $grid-cols {
			.element#{$modifier}-#{$i} {
				flex-basis: (100 / ($grid-cols / $i)) * 1%;
			}

			.element#{$modifier}-offset-#{$i} {
				margin-left: (100 / ($grid-cols / $i)) * 1%;
			}
		}
	}
}

//use this mixin if you want to give an element the col behavior of a flex-col via scss
@mixin flex-col($breakpoint, $modifier, $cols) {
	@include flex-item(0px, 50px, 0, 0);
	margin-top: $grid-gutter;
	margin-bottom: $grid-gutter;
	@include media($breakpoint) {
		@if $cols == 0 {
			margin-left: 0;
		}
		@if $cols > 0 {
			flex-basis: (100 / ($grid-cols / $cols)) * 1%;
		}

		@if $modifier == 'offset' {
			margin-left: (100 / ($grid-cols / $cols)) * 1%;
		}
	}
}

@mixin flex($padding: 0, $margin: 0, $flex-wrap: wrap, $justify-content: space-around) {
	display: -webkit-box;
	display: -moz-box;
	display: -ms-flexbox;
	display: -webkit-flex;
	display: flex;
	padding: $padding;
	margin: $margin;
	-webkit-flex-wrap: $flex-wrap;
	flex-wrap: $flex-wrap;
	justify-content: $justify-content;
	@content;
}

@mixin flex-inline($flex-wrap: wrap, $justify-content: space-around) {
	display: -webkit-inline-flex;
	display: -moz-inline-box;
	display: -ms-inline-flexbox;
	display: inline-flex;
	-webkit-flex-wrap: $flex-wrap;
	flex-wrap: $flex-wrap;
	justify-content: $justify-content;
	@content;
}

//use this mixin if you want to give a flex item a specific flex behavior via scss
@mixin flex-item($width: 100%, $height: auto, $flex: 0 0 100%) {
	width: $width;
	height: $height;
	flex: $flex;
	@content
}

//Setup Classes for class based flex grid
@each $modifier, $breakpoint in $grid-map-props {
	@include create-cols($modifier, $grid-cols, $breakpoint);
}

//Form FIELDS
//this mixin is used by form fields only to calculate different factors responsively
@mixin calc-responsive($param, $opts1:"", $opts2:"") {
	@if $param == 'box-shadow' {
		//calculate font size related box shadow
		@each $item, $screensize in $screens {
			$index: index(($screens), ($item $screensize));
			//loop for every screen until list of values is empty with min-width queries
			@media screen and (min-width: $screensize) {
				@if (length($field-fontsize-field) >= $index) {
					$field-fontsize: nth($field-fontsize-field, $index);
					$label-fontsize: nth($field-fontsize-label, $index);
					$padding: ceil(((3 * ($field-padding * 16 / 1rem) + $field-fontsize) - ($label-fontsize + $field-fontsize + $field-spacing)) / 2);
					#{$param}: 0 #{$padding} 0 0 $opts1, 0 $padding+2px 0 0 $opts2;
				}
			}
		}
	}

	@if $param == 'transform' {
		//calculate font size related box shadow
		@each $item, $screensize in $screens {
			$index: index(($screens), ($item $screensize));
			//loop for every screen until list of values is empty with min-width queries
			@media screen and (min-width: $screensize) {
				@if (length($field-fontsize-field) >= $index) {
					$field-fontsize: nth($field-fontsize-field, $index);
					$label-fontsize: nth($field-fontsize-label, $index);
					$moveup: ceil(($label-fontsize + $field-fontsize/2));
					#{$param}: translateY(calc(-50% - #{$moveup} + #{$label-fontsize} - #{$field-spacing})); //breakpoint specific
				}
			}
		}
	}

	@if $param == 'height' {
		//calculate font size related box shadow
		@each $item, $screensize in $screens {
			$index: index(($screens), ($item $screensize));
			//loop for every screen until list of values is empty with min-width queries
			@media screen and (min-width: $screensize) {
				@if (length($field-fontsize-field) >= $index) {
					$field-fontsize: nth($field-fontsize-field, $index);
					#{$param}: calc((3 * #{$field-padding}) + #{$field-fontsize} + 0.1em);
				}
			}
		}
	}

	@if $param == 'max-height-label' {
		//calculate font size related box shadow
		@each $item, $screensize in $screens {
			$index: index(($screens), ($item $screensize));
			//loop for every screen until list of values is empty with min-width queries
			@media screen and (min-width: $screensize) {
				@if (length($field-fontsize-field) >= $index) {
					$label-fontsize: nth($field-fontsize-label, $index);
					max-height: $label-fontsize;
				}
			}
		}
	}

	@if $param == 'max-height-field' {
		//calculate font size related box shadow
		@each $item, $screensize in $screens {
			$index: index(($screens), ($item $screensize));
			//loop for every screen until list of values is empty with min-width queries
			@media screen and (min-width: $screensize) {
				@if (length($field-fontsize-field) >= $index) {
					$field-fontsize: nth($field-fontsize-field, $index);
					max-height: calc(#{$field-fontsize} + 0.1em);
				}
			}
		}
	}

	@if $param == 'margin-top' {
		//calculate font size related box shadow
		@each $item, $screensize in $screens {
			$index: index(($screens), ($item $screensize));
			//loop for every screen until list of values is empty with min-width queries
			@media screen and (min-width: $screensize) {
				@if (length($field-fontsize-field) >= $index) {
					$field-fontsize: nth($field-fontsize-field, $index);
					#{$param}: -$field-fontsize;
				}
			}
		}
	}

	@if $param == 'ietransform' {
		//calculate font size related box shadow
		@each $item, $screensize in $screens {
			$index: index(($screens), ($item $screensize));
			//loop for every screen until list of values is empty with min-width queries
			@media screen and (min-width: $screensize) {
				@if (length($field-fontsize-field) >= $index) {
					$field-fontsize: nth($field-fontsize-field, $index);
					$label-fontsize: nth($field-fontsize-label, $index);
					margin-top: calc(-1 * ((((3 * #{$field-padding}) + #{$field-fontsize} + 0.1em) / 2) - #{$label-fontsize}));
				}
			}
		}
	}
}

@mixin field($mode:'after') {
	//use mode "after" when label is after input, use "before" when label is situated before input
	position: relative;
	max-width: $field-maxwidth;
	background: $field-containerbgcolor;
	display: flex;
	transition: background-color 0.25s ease, border 0.25s ease;

	&:hover {
		background: $field-containerbg-hover;
	}

	//extend when mode is before
	@if $mode == 'before' {
		display: flex;
		flex-direction: column;
		flex-wrap: wrap;
		justify-content: center;
		min-width: $field-minwidth; //needs min-width
		@include calc-responsive('height');
		border: 2px solid $color-white;

		&:hover {
			border-color: $field-containerbg-hover;

			input {
				@include calc-responsive('box-shadow', $field-containerbg-hover, $field-containerbg-hover);
			}

			input:valid {
				@include calc-responsive('box-shadow', $field-containerbg-hover, $field-linecolorvalid);
			}

			input:placeholder-shown:invalid {
				@include calc-responsive('box-shadow', $field-containerbg-hover, transparent);
				transition: max-height 0.15s ease;
			}
		}

		//Degrading
		@media screen and (-ms-high-contrast: active), screen and (-ms-high-contrast: none) {
			input:valid {
				@include calc-responsive('box-shadow', $field-containerbgcolor, black);
			}

			&:hover {
				input:valid {
					@include calc-responsive('box-shadow', $field-containerbg-hover, black);
				}
			}
		}
	}
	@content; //custom extends
}

//use mixin to give input input behavior
@mixin input($mode: 'after') {
	//use after when label is after input, use before when label is situated before input
	position: relative;
	width: 100%;
	padding: 2*$field-padding $field-padding $field-padding; //use var
	background: transparent; //use var
	font-weight: 700;
	z-index: 1; //make higher than absolute labels;
	@include responsify($field-fontsize-field);

	& + label {
		transition: transform 0.15s ease;
		@include calc-responsive('transform');
	}

	&:placeholder-shown {
		& + label {
			transform: translateY(calc(-50%));
		}
	}

	&:focus {
		& + label {
			@include calc-responsive('transform');
		}
	}

	&:valid {
		box-shadow: 0 2px 0 0 $field-linecolorvalid;
	}

	&:invalid {
		box-shadow: 0 2px 0 0 $field-linecolorinvalid;
	}

	&:placeholder-shown:invalid {
		box-shadow: none;
	}

	@if $mode != 'before' {
		//Degrading
		@media screen and (-ms-high-contrast: active), screen and (-ms-high-contrast: none) {

			//IE Hack: hover on text-field hovers
			& + label,
			&:focus + label {
				@include calc-responsive('ietransform');
			}
		}
	}

	@if $mode == 'before' {
		position: relative;
		transition: max-height 0.15s ease, box-shadow 0s ease;
		padding: 0 $field-padding;
		margin-top: $field-spacing;
		@include calc-responsive('max-height-field');

		&:placeholder-shown {
			display: block;
			position: static;
			max-height: 0;
			line-height: 0;
			margin-top: 0;
			border: solid 2px transparent;
		}


		&:valid {
			border-bottom-color: transparent;
			@include calc-responsive('box-shadow', $field-containerbgcolor, $field-linecolorvalid);
		}

		&:invalid {
			border-bottom-color: transparent;
			@include calc-responsive('box-shadow', $field-containerbgcolor, $field-linecolorinvalid);
		}

		&:placeholder-shown:invalid {
			@include calc-responsive('box-shadow', $field-containerbgcolor, $field-containerbgcolor);
		}

		&:focus:not(.narrow) {
			z-index: 10;
			margin-top: $field-spacing;
			transition: max-height 0.15s ease, box-shadow 0s ease 0.25s;
			@include calc-responsive('max-height-field'); //eventually add 1px depending on font-x-height;
			@include calc-responsive('box-shadow', $field-containerbgcolor, $color-white);
		}
	}
	//Degrading
	@media screen and (-ms-high-contrast: active), screen and (-ms-high-contrast: none) {
		box-shadow: none !important; //disable css validation here
	}
	@content; // custom extender
}

//add mixin to give element label behavior
@mixin label($mode: 'after') {
	//use after when label is after input, use before when label is situated before input
	position: absolute;
	@include responsify($field-fontsize-label);
	top: 50%;
	transform: translateY(-50%); // @ TODO maybe use center helper?
	left: 0;
	right: 0;
	padding: $field-padding;
	cursor: text;
	//use helper mixin
	-webkit-touch-callout: none;
	-webkit-user-select: none;
	-khtml-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;

	@if $mode == 'before' {
		position: static;
		display: block;
		padding: 0 $field-padding;
		min-width: $field-minwidth;
		transform: translateY(0);
		@include calc-responsive('max-height-label');
		line-height: 1;
		cursor: text;

		&:before {
			content: "";
			display: block;
			position: absolute;
			left: 0;
			right: 0;
			top: 0;
			@include calc-responsive('height');
			@include calc-responsive('margin');
		}
	}
	@content; //custom extensions
}

@mixin select {
	@include calc-responsive('height');
	background: transparent;
	border: 2px solid $color-white;
	color: $color-white;
	font-family: $font-light;
	@content
}

// Container
@mixin radio {
	position: relative;
	cursor: pointer;
	display: none;
}

// Label
@mixin radio-container($mode: 'after', $width: 30px, $height: 30px, $border: solid 2px black, $bg-color: white, $border-radius: 50%) {
	position: relative;
	display: inline-block;
	@include responsify($spacing-sm, 'margin-right');

	@if $mode == 'after' {
		label {
			display: inline-block;

			&:after {
				content: '';
				display: inline-block;
				width: $width;
				height: $height;
				vertical-align: middle;
				border: $border;
				background-color: $color-white;
				border-radius: $border-radius;
				cursor: pointer;
				@include responsify((5px, 5px), 'margin-left');
			}

			&:before {
				content: '';
				position: absolute;
				display: none;
				top: 11px;
				width: ceil($width/4);
				right: ceil($width/2) - ceil($width/14);
				height: ceil($height/2);
				border: solid $color-black;
				border-width: 0 2px 2px 0;
				transform-origin: center;
				@include vendor-prefix(transform, rotate(45deg) translateX(-50%) translateY(ceil(-1* $width/4))); //@TODO: calc different
				@include responsify((5px, 5px), 'margin-left');
			}
		}

		//Custom Check Tick
		input:checked + label:before {
			display: block;
		}
	}


	@if $mode == 'before' {

		label {
			display: flex;
			align-items: center;

			&:before {
				content: '';
				display: inline-block;
				width: $width;
				height: $height;
				vertical-align: middle;
				border: $border;
				background-color: $color-white;
				border-radius: $border-radius;
				cursor: pointer;
				@include responsify($spacing-no, 'margin-left');
				@include responsify((15px, 15px), 'margin-right');
			}

			&:after {
				content: '';
				position: absolute;
				display: none;
				width: ceil($width/4);
				left: 12px;
				top: 14px;
				height: ceil($height/2);
				transform-origin: center;
				@include vendor-prefix(transform, rotate(45deg) translateX(-50%) translateY(ceil(-1* $width/4))); //@TODO: calc different
				@include responsify($spacing-no, 'margin-left');
				@include responsify((15px, 15px), 'margin-right');
				border: solid $color-black;
				border-width: 0 2px 2px 0;
			}
		}

		//Custom Check Tick
		input:checked + label:after {
			display: block;
		}
	}
	@content;
}

//Label Text
@mixin radio-label() {
	cursor: pointer;
	span {
		cursor: pointer;
		vertical-align: middle;
	}
	@content;
}

// Transitions
@mixin transition($args...) {
	-webkit-transition: $args;
	-moz-transition: $args;
	-ms-transition: $args;
	-o-transition: $args;
	transition: $args;
}

// Box Shadow
@mixin box-shadow($top, $left, $blur, $spread, $color, $inset: false) {
	@if $inset {
		-webkit-box-shadow: inset $top $left $blur $spread $color;
		-moz-box-shadow: inset $top $left $blur $spread $color;
		box-shadow: inset $top $left $blur $spread $color;
	} @else {
		-webkit-box-shadow: $top $left $blur $spread $color;
		-moz-box-shadow: $top $left $blur $spread $color;
		box-shadow: $top $left $blur $spread $color;
	}
}


@mixin box-shadow-double($top, $left, $blur, $spread, $color, $top2, $left2, $blur2, $spread2, $color2 ) {
	-webkit-box-shadow: $top $left $blur $spread $color, $top2 $left2 $blur2 $spread2 $color2;
	-moz-box-shadow: $top $left $blur $spread $color, $top2 $left2 $blur2 $spread2 $color2;
	box-shadow: $top $left $blur $spread $color, $top2 $left2 $blur2 $spread2 $color2;
}
