//returns first and last element of a list
@function first($list) {
	@return nth($list, 1);
}
@function last($list) {
	@return nth($list, length($list));
}

//returns unitless value
@function strip-unit($number) {
	@if (type-of($number) == "number" and not unitless($number)) {
		@return $number / ($number * 0 + 1);
	}
	@return $number;	
}

//repeats a list of values to a param (e.g. font-size) and repeats this mapping for every breakpoint; Use for responsive spacing, font-sizes, margins, or whatever you want to adjust list-based
@mixin responsify($values: $font-sizes-sm, $param:'font-size', $prefix:'') {
	#{$param}: first($values); //default
	@each $item, $screensize in $screens {
		$index: index(($screens), ($item $screensize));
		//loop for every screen until list of values is empty with min-width queries
		@media screen and (min-width: $screensize) {
			@if(length($values) >= $index) {
				@if $prefix == "-" {
					#{$param}: -1 * nth($values, $index);
				} @else {
					#{$param}: nth($values, $index);
				}
			}
		}
	}
}

//returns a fluid size based on vw parameters - used for fluid spacing or fluid font-sizes;
@mixin fluidsize($values: $font-sizes-sm, $param:'font-size') {
	//properties
	$minsize: first($values);
	$maxsize: last($values);
	//define min-font-size
	#{$param}: $minsize;
	//define fluid font size behavior on screens above minimum
	@media (min-width: map-get($screens, screen-min)) {
		$diff: ($maxsize - $minsize) / 1px;
		$screendiff: (map-get($screens, screen-xl) - map-get($screens, screen-min)) / 1px;
		#{$param}: calc(#{$minsize} + #{$diff} * ((100vw - #{map-get($screens, screen-min)}) / #{$screendiff}));
	}
	//define max font sizes on biggest screen
	@media (min-width: map-get($screens, screen-xl)) {
		#{$param}: $maxsize;
	}
}

//syntax mixin for dynamic responsive breakpoint use;
@mixin media($width, $type: min) {
	@if map_has_key($screens, $width) {
		$width: map_get($screens, $width);
		@if $type == max {
			$width: $width - 1px;
		}
		@media only screen and (#{$type}-width: $width) {
			@content;
		}
	}
}

//truncates a text
// Requires inline-block or block for proper styling
@mixin text-truncate {
	overflow: hidden;
	text-overflow: ellipsis;
	white-space: nowrap;
}

//vendor-prefixer - can be used with every argument
@mixin vendor-prefix($param, $arguments...) {
	-webkit-#{$param}: $arguments;
	-ms-#{$param}: $arguments; // IEs
	#{$param}: $arguments;
}

// Center - centers an element with different techniques
@mixin center($mode: 'margin') {
	@if $mode == 'margin' {
		margin: 0 auto;
		vertical-align: center;
	}

	@if $mode == 'transform' {
		top: 50%;
		left: 50%;
		@include vendor-prefix(transform, "translateX(-50%) translateY(-50%)");
	}

	@if $mode == 'flex' {
		align-self: center;
	}
}

//linear gradient - simple fromn to version
@mixin linear-gradient ($from, $to) {
	background-color: $from;
	background-image: -webkit-linear-gradient(top, $from, $to);
	/* Chrome 10-25, iOS 5+, Safari 5.1+ */
	background-image: linear-gradient(to bottom, $from, $to);
	/* Chrome 26, Firefox 16+, IE 10+, Opera 12.10+ */
}

//responsive bg bgimages helper
@mixin bg-image($name, $type:'jpg', $imgprefix-small:'small') {
	@include media($breakpoint-largeimage, "max") {
		background-image: url("#{$asset-base-path}#{$name}_#{$imgprefix-small}.#{$type}");
	}
	@include media($breakpoint-largeimage) {
		background-image: url("#{$asset-base-path}#{$name}.#{$type}");
	}
}

//animation-mixins here...
@mixin keyframes($anim-name) {
	@-webkit-keyframes #{$anim-name} {
		@content;
	}
	@-moz-keyframes #{$anim-name} {
		@content;
	}
	@-ms-keyframes #{$anim-name} {
		@content;
	}
	@-o-keyframes #{$anim-name} {
		@content;
	}
	@keyframes #{$anim-name} {
		@content;
	}
}

//helpers which shows pixel values of screen
@function screen($value) {
	@return map_get($screens, $value);
}
